var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "../../atoms/button/button";
import "./jsQuestionnaireFooter.css";
/**
 * The Job Seeker Questionnaire Footer.
 * This is the to most part in the questionnaire which is same for all the questionnaire for the job seeker.
 * The footers are constructed which are then assigned their functions to be called on clicking the bottons.
 */
export default function JSQuestionnaireFooter(props) {
    return (_jsxs("div", { children: [_jsx("hr", { className: "hr-size" }), _jsxs("div", __assign({ className: "flex" }, { children: [_jsx(Button, { children: "Back", className: "back-btn", primary: false, onClick: props.onBack }), _jsx(Button, { children: "Continue", className: "continue-btn", onClick: props.onSubmit })] }))] }));
}
