var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Card from "../../atoms/card/card";
import NavBar from "../../organisms/navBar/navBar";
import PageHeader from "../../organisms/pageHeader/pageHeader";
import "../../templates/general_page_template.css";
import "../../molecules/tabs/tabs.css";
import Button from "../../atoms/button/button";
import { ButtonType } from "../../atoms/button/buttonType";
import GenericTab from "../../molecules/genericTab/genericTab";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import TableCard from "../../molecules/table/table";
import { getCaseloads, getCaseloadsDashboard, exportUsersData, archiveUsersData, } from "../../../redux/actions/manage_users";
import showPopupModal from "../../../redux/actions/popupModal";
import CreateUser from "../../organisms/createUser/CreateUser";
import useScreenSize from "../../atoms/useScreenSize/useScreenSize";
function prepareForTable(caseload, userType, tab) {
    // DOES NOT REMEMBER BETWEEN TABS
    // possible fix: prepare them beforehand
    switch (userType) {
        case "adviser":
            return prepareJobseeker(caseload);
        case "manager":
            if (tab === 0) {
                return prepareAdviser(caseload);
            }
            else {
                return prepareJobseeker(caseload);
            }
        case "organisation":
        default:
            if (tab === 0) {
                return prepareManager(caseload);
            }
            else if (tab === 1) {
                return prepareAdviser(caseload);
            }
            else {
                return prepareJobseeker(caseload);
            }
    }
}
function prepareJobseeker(caseload) {
    var res = [];
    caseload
        ? caseload.jobseekers &&
            caseload.jobseekers.forEach(function (js) {
                res.push([
                    false,
                    js.name,
                    js.dateJoined,
                    "".concat(js.questionnaire_attempts.toString(), " / 2"),
                    js.timegap === 0 ? " - " : "".concat(js.timegap.toString(), " Days"),
                    js.blr_score.toString(),
                    js.questionnaire_attempts === 0
                        ? "No Questionnaires attempted"
                        : {
                            link: "/dashboard",
                            text: "View Dashboard >",
                            state: { state: { viewUser: js.name } },
                            type: "link",
                        },
                ]);
            })
        : undefined;
    return res;
}
function prepareAdviser(caseload) {
    var res = [];
    caseload
        ? caseload.advisers &&
            caseload.advisers.forEach(function (js) {
                res.push([
                    false,
                    js.name,
                    js.dateJoined,
                    js.caseload.toString(),
                    {
                        link: "/dashboard",
                        text: "View Dashboard >",
                        state: { state: { viewUser: js.name } },
                        type: "link",
                    },
                ]);
            })
        : undefined;
    return res;
}
function prepareManager(caseload) {
    var res = [];
    caseload
        ? caseload.managers &&
            caseload.managers.forEach(function (js) {
                res.push([
                    false,
                    js.name,
                    js.dateJoined,
                    js.adviser_number.toString(),
                    js.caseload.toString(),
                    {
                        link: "/dashboard",
                        text: "View Dashboard >",
                        state: { state: { viewUser: js.name } },
                        type: "link",
                    },
                ]);
            })
        : undefined;
    return res;
}
var prepareHeader = function (userType, tabNumber) {
    if (userType === "adviser") {
        return [
            "",
            "Name",
            "Date Joined",
            "Questionnaire Attempts",
            "Time gap",
            "Prediction",
            "",
        ];
    }
    else if (userType === "manager") {
        return tabNumber === 0
            ? ["", "Name", "Date Joined", "Caseload", ""]
            : [
                "",
                "Name",
                "Date Joined",
                "Questionnaire Attempts",
                "Time gap",
                "Prediction",
                "",
            ];
    }
    else {
        return tabNumber === 0
            ? ["", "Name", "Date Joined", "No. Advisers", "Caseload", ""]
            : tabNumber === 1
                ? ["", "Name", "Date Joined", "Caseload", ""]
                : [
                    "",
                    "Name",
                    "Date Joined",
                    "Questionnaire Attempts",
                    "Time gap",
                    "Prediction",
                    "",
                ];
    }
};
export default function ManageUsers() {
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var state = useLocation().state;
    var userToken = useSelector(function (state) { return state.rootReducer.userReducer.loginData; });
    var username = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_username; });
    var safeUser = useSelector(function (state) { return state.rootReducer.userReducer.username; });
    username = username ? username : safeUser;
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var viewing_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_user_type; });
    var safe_viewing_usertype = useSelector(function (state) { return state.rootReducer.userReducer.userType; });
    viewing_user_type = viewing_user_type
        ? viewing_user_type
        : safe_viewing_usertype;
    var caseload = useSelector(function (state) { return state.rootReducer.ManageUserReducer.caseload; });
    if (!userToken) {
        // User has not logged in, no auth token
        // Redirect to Login
        useEffect(function () {
            navigate("/login");
        }, []);
    }
    else {
        // Now we know that the user is logged in. Perform the API calls
        useEffect(function () {
            if (state && state.viewUser) {
                dispatch(getCaseloads(state.viewUser));
                dispatch(getCaseloadsDashboard(state.viewUser));
            }
            else {
                dispatch(getCaseloads(username));
                dispatch(getCaseloadsDashboard(username));
            }
        }, []);
    }
    var _a = useState(0), tabNumber = _a[0], setTabNumber = _a[1];
    var _b = useState((Array)), selectedData = _b[0], setSelectedData = _b[1];
    var handleSelectedData = function (data) {
        setSelectedData(data);
    };
    //export Data Modal
    var exportDataBody = (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "mb-7" }, { children: "Export Data" })), _jsx("p", { children: "Please click below to export the list of selected users. Jobseeker data will be exported as an .xlsx file. A link will be sent to your email address." }), _jsx("div", __assign({ className: "pt-6" }, { children: _jsx(Button, { border: "none", onClick: function () {
                        dispatch(exportUsersData(selectedData));
                        dispatch(showPopupModal(false));
                    }, text: "Export", className: "mt-6" }) }))] }));
    //archive Data Modal
    var archiveDataBody = (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "mb-7" }, { children: "Archive Data" })), _jsx("p", { children: "Please click below to archive the list of selected users. Jobseeker data will be exported as an .xslx file." }), _jsx("div", __assign({ className: "pt-6" }, { children: _jsx(Button, { border: "none", onClick: function () {
                        dispatch(archiveUsersData(selectedData, username));
                        dispatch(showPopupModal(false));
                        setSelectedData([]);
                    }, text: "Archive", className: "mt-6" }) }))] }));
    var screenSize = useScreenSize();
    var buttonStyle = screenSize.width > 1440 ? "w-[10vw] h-[90%]" : "w-[25vw]";
    return (_jsxs("div", __assign({ className: "h-full" }, { children: [_jsx(NavBar, { viewing_user: viewing_user_type, logged_in_user: logged_in_user_type, viewing_username: username }), _jsxs("div", __assign({ className: "page-template-right" }, { children: [_jsx(PageHeader, { title: "Manage Users" }), _jsxs(Card, __assign({ multiLine: true, className: "!pt-4" }, { children: [_jsxs(GenericTab, __assign({ setStateCallback: setTabNumber, titles: viewing_user_type === "adviser"
                                    ? ["Jobseekers"]
                                    : viewing_user_type === "manager"
                                        ? ["Advisers", "Jobseekers"]
                                        : ["Managers", "Advisers", "Jobseekers"] }, { children: [_jsx(Button, __assign({ onClick: function () {
                                            dispatch(showPopupModal(true, exportDataBody));
                                        }, buttonType: ButtonType.OutlinedPrimary, className: buttonStyle }, { children: "Export Jobseeker Data" })), _jsx(Button, __assign({ onClick: function () {
                                            dispatch(showPopupModal(true, archiveDataBody));
                                        }, buttonType: ButtonType.OutlinedSecondary, className: buttonStyle }, { children: "Archive Selected" })), _jsx(Button, __assign({ onClick: function () {
                                            dispatch(showPopupModal(true, _jsx(CreateUser, {})));
                                        }, className: buttonStyle }, { children: "Create User" }))] })), _jsx("div", { className: "tabs-hr" }), _jsx(TableCard, { headers: prepareHeader(viewing_user_type, tabNumber), data: prepareForTable(caseload, viewing_user_type, tabNumber), currentTab: tabNumber, rowsPerPage: 10, showSearchBar: true, multiSelect: true, selectData: selectedData, handleSelectedData: handleSelectedData })] }))] }))] })));
}
