import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Status } from '../../../redux/constants/status';
import StatsTileRow from '../../molecules/statsTileRow/statsTileRow';
export default function DashboardStats() {
    var defaultData = [
        {
            status: Status.Loading
        },
        {
            status: Status.Loading
        },
        {
            status: Status.Loading
        }
    ];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var data = useSelector(function (state) { return state.rootReducer.dashboardReducer.stats; });
    return (_jsx(StatsTileRow, { data: data || defaultData }));
}
