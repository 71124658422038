var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './rightArrow.css';
import rightArrow from '../../../assets/icons/right-arrow.svg';
/**
 * This component is simply the Right Arrow svg with some CSS attached to it.
 *
 * Due to how frequently the Right Arrow icon was used, I've decided to make it into its own independent component.
 * This way, we'll prevent repeating ourselves.
 *
 * This component is usually used in conjunction with a link that redirects the users.
 * For Example: "Based on Questionnaire >"
 *
 * Use this as an inline object or a standalone component.
 */
export default function RightArrow() {
    return _jsx("span", __assign({ className: 'arrow-right' }, { children: _jsx("img", { src: rightArrow }) }));
}
