var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BarChart from '../../molecules/graphs/BarChart';
import { Status } from '../../../redux/constants/status';
import PieChart from '../../molecules/graphs/pieChart';
import StackedBar from '../../molecules/graphs/stackedBar';
var DashboardGraps = function (props) {
    var barData = {
        status: Status.Loading,
        labels: undefined,
        values: undefined
    };
    var rightSideData = {
        status: Status.Loading,
        labels: undefined,
        values: undefined
    };
    if (props.data) {
        barData = {
            status: Status.Successful,
            labels: props.data.map(function (d) { return d.label; }),
            values: props.data.map(function (d) { return [0.1, d.score > 0.1 ? d.score : 0.1]; })
        };
    }
    if (props.rightSide) {
        rightSideData = {
            status: Status.Successful,
            labels: props.rightSide.map(function (d) { return d.label; }),
            values: props.rightSide.map(function (d) { return d.score; })
        };
    }
    return (_jsxs("div", __assign({ className: 'flex flex-row' }, { children: [_jsx("div", __assign({ className: 'graph-area-left flex flex-col' }, { children: _jsx(BarChart, __assign({}, barData)) })), _jsx("div", __assign({ className: 'graph-area-right flex flex-col' }, { children: props.rightSide && props.rightSide.length === 1 ? _jsx(PieChart, __assign({}, rightSideData)) :
                    _jsx(StackedBar, __assign({}, rightSideData)) }))] })));
};
export default DashboardGraps;
