// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
  color: #f65248;
  font-size: 0.875rem;
  margin-top: 0.37rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/errorMessage/errorMessage.css"],"names":[],"mappings":"AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":["@import \"../../styles/global.css\";\n\n.error {\n  color: #f65248;\n  font-size: 0.875rem;\n  margin-top: 0.37rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
