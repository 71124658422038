// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.section-header{
  font-size: 1.625rem;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/sectionHeader/sectionHeader.css"],"names":[],"mappings":";;;AAGA;EACE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":["\n\n\n.section-header{\n  font-size: 1.625rem;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
