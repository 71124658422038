var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import QuestionnaireOrg from "../../../organisms/jsQuestionnaire/jsQuestionnaire";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdviserFeedbackAction, AdviserFeedbackFormAction, } from "../../../../redux/actions/job_seeker_questionnaire";
/**
 * The Adviser Feedback Questionnaire Page
 * This will need to call all relevant APIs to get the values.
 * @param props
 * @returns
 */
function AdviserFeedbackQuestionnaire() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _a = useState(""), error = _a[0], setError = _a[1];
    useEffect(function () {
        dispatch(AdviserFeedbackAction());
    }, [dispatch]);
    //using selector hook to fetch data from the reducer
    var _b = useSelector(function (state) { return state.rootReducer.JobSeekerQuestionnaireReducer; }), adviserFeedbackData = _b.adviserFeedbackData, error_adviserFeedback = _b.error_adviserFeedback, loading = _b.loading, adviserFeedbackFormData = _b.adviserFeedbackFormData, success_adviserFeedback_form = _b.success_adviserFeedback_form;
    var loginData = useSelector(function (state) { return state.rootReducer.userReducer; }).loginData;
    //function to submit the input user data
    var handleFormSubmit = function (formData) {
        var sanitizedData = Object.keys(formData).reduce(function (acc, key) {
            acc[key] = DOMPurify.sanitize(formData[key]);
            return acc;
        }, {});
        dispatch(AdviserFeedbackFormAction(sanitizedData, loginData));
    };
    if (success_adviserFeedback_form) {
        navigate("/questionnaire/adviserImpact");
    }
    return (_jsxs("div", __assign({ className: "" }, { children: [error && _jsx("div", __assign({ className: "error-message" }, { children: error })), _jsx(QuestionnaireOrg, { title: "Adviser Feedback", content: "For advisers and jobseekers to work well together, it's important that they both understand what they need to do and what they expect from each other. So that we can best understand what a positive interaction looks like, please answer as honestly as possible.", data: adviserFeedbackData, responses: adviserFeedbackFormData, handleSubmit: handleFormSubmit, classname: "adviserFeedback-w", width: "84%" })] })));
}
export default AdviserFeedbackQuestionnaire;
