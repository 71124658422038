import { QuestionnaireHistory } from "../constants/questionnaireHistoryConstants";
export var getQuestionnaireHistory = function (username) {
    return {
        type: QuestionnaireHistory.HISTORY,
        username: username,
    };
};
export var getQuestionnaireOneHistory = function (username) {
    return {
        type: QuestionnaireHistory.RESPONSE_HISTORY_1,
        username: username,
    };
};
export var getQuestionnaireTwoHistory = function (username) {
    return {
        type: QuestionnaireHistory.RESPONSE_HISTORY_2,
        username: username,
    };
};
