import { jsx as _jsx } from "react/jsx-runtime";
import "./shimmer.css";
/**
 * The Shimmer
 * This component is essentially the Shimmer to showcase stuff that is currently loading.
 * The current implementation is simply just a div with CSS animations on an infinite loop.
 * The component takes 100% of it's PARENTS size.
 * If you wish to style it, as of now, you can either create a separate div and attach CSS to it or use tailwind directly.
 */
export default function Shimmer(props) {
    return (_jsx("div", { className: "shimmer ".concat(props.className) }));
}
