// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  /* width: 11.375rem; */
  /* height: 4rem; */
  flex-shrink: 0; 
  border-radius: 1.5rem;
  background-color: var(--background-color);
  margin: 0rem 0.75rem;
}

.card-text {
  padding: 1.4rem 2.31rem;
}

.card-large {
  width: 100%;
  height: 100%;
  padding: 5rem 2.19rem 1.75rem 2.19rem;
}

.card p {
  font-weight: 500;
}

.card-scroll {
  height: 69vh;
  overflow-y: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/card/card.css"],"names":[],"mappings":"AAGA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,qBAAqB;EACrB,yCAAyC;EACzC,oBAAoB;AACtB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,qCAAqC;AACvC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["@import \"../../styles/global.css\";\n\n\n.card {\n  /* width: 11.375rem; */\n  /* height: 4rem; */\n  flex-shrink: 0; \n  border-radius: 1.5rem;\n  background-color: var(--background-color);\n  margin: 0rem 0.75rem;\n}\n\n.card-text {\n  padding: 1.4rem 2.31rem;\n}\n\n.card-large {\n  width: 100%;\n  height: 100%;\n  padding: 5rem 2.19rem 1.75rem 2.19rem;\n}\n\n.card p {\n  font-weight: 500;\n}\n\n.card-scroll {\n  height: 69vh;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
