// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  width: 3.9375rem;
  height: 4.125rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  background-color: rgba(217, 217, 217, 0.33);
  padding: 0.9rem 0rem 0.5rem 0.9rem;
}

.logo > img {
  width: 2.5rem;
  height: 2rem;
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/logo/logo.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,sBAAsB;EACtB,2CAA2C;EAC3C,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".logo {\n  width: 3.9375rem;\n  height: 4.125rem;\n  flex-shrink: 0;\n  border-radius: 1.25rem;\n  background-color: rgba(217, 217, 217, 0.33);\n  padding: 0.9rem 0rem 0.5rem 0.9rem;\n}\n\n.logo > img {\n  width: 2.5rem;\n  height: 2rem;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
