var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "../../styles/main.css";
import "../../styles/global.css";
import "../../styles/login.css";
/**
 * Label.
 * Props can take in class name which will style the label.
 * @param props
 * @returns
 */
export default function Label(props) {
    // for adding custom styling if required for the label
    var labelStyle = {};
    return _jsx("label", __assign({ className: props.className }, { children: props.label }));
}
