var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Shimmer from '../../atoms/shimmer/shimmer';
var GraphShimmer = function () {
    return (_jsxs("div", __assign({ style: { height: "80%" } }, { children: [_jsx("div", __assign({ className: 'h-8 pb-2 w-full' }, { children: _jsx(Shimmer, { className: 'w-2/3 rounded' }) })), ";", _jsx(Shimmer, { className: 'rounded-xl' })] })));
};
export default GraphShimmer;
