var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./navItem.css";
/**
 * The NavItem
 * This is the Navigation Buttons on the LHS on most pages.
 * It takes in a Text (Destination)
 * Icon (An SVG with color set of #fff - Check the fill tag)
 * The link to the destination
 * The isSelected boolean states whether it's selected or not.
 */
export default function NavItem(props) {
    //TODO: Add the Link Tag
    var selectedClass = props.isSelected ? "nav-item-selected" : "nav-item-not-selected";
    var iconColor = props.isSelected ? "nav-item-icon-selected" : "nav-item-icon-not-selected";
    return (_jsxs("div", __assign({ className: "".concat(selectedClass, " ").concat(props.disabled ? "nav-item-disabled" : "cursor-pointer", " align-middle nav-item flex flex-row nav-item-transition"), onClick: props.disabled ? undefined : props.onClick }, { children: [_jsx("img", { src: props.icon, alt: "nav-icon", className: "".concat(iconColor, " nav-item-icon") }), _jsx("p", { children: props.text })] })));
}
