var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import "./questionnaireHistory.css";
import NavBar from "../../organisms/navBar/navBar";
import PageHeader from "../../organisms/pageHeader/pageHeader";
import Bar from "../../molecules/bar/bar";
import Card from "../../atoms/card/card";
import DashboardGraps from "../dashboard/dashboardGraps";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionnaireOneHistory, getQuestionnaireTwoHistory, } from "../../../redux/actions/questionnaireHistoryActions";
import TableCard from "../../molecules/table/table";
/**
 * This returns the ordinals for a given date.
 * 1 becomes st
 * 2 becomes nd
 * etc...
 * @param {number} number The date.
 */
function nthNumber(number) {
    return number > 0
        ? ["th", "st", "nd", "rd"][(number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10]
        : "";
}
var tableData = [];
var processResponse = function (section, data) {
    var _loop_1 = function (key) {
        var value = data[key];
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                // Handle array values
                value.forEach(function (item, index) {
                    var question = "".concat(key, " - ").concat(index + 1);
                    tableData.push([
                        section,
                        question,
                        Array.isArray(item) ? item.join(", ") : item.toString(),
                    ]);
                });
            }
            else {
                // Recursively process nested objects
                processResponse("".concat(section, " - ").concat(key), value);
            }
        }
        else {
            // Handle scalar values
            tableData.push([section, key, value.toString()]);
        }
    };
    for (var key in data) {
        _loop_1(key);
    }
};
function QuestionnaireResponses() {
    var dateObj = new Date();
    var day = dateObj.getDate();
    var month = dateObj.toLocaleString("default", { month: "long" });
    var year = dateObj.getFullYear();
    var dispatch = useDispatch();
    var questionnaireNumber = useParams().questionnaireNumber;
    var responses = {};
    var data = {};
    var rightSide = {};
    var viewing_username = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_username; });
    useEffect(function () {
        // Clear the tableData array before processing responses
        tableData.length = 0;
        // Dispatch the action when the component mounts
        if (questionnaireNumber === "1") {
            dispatch(getQuestionnaireOneHistory(viewing_username));
        }
        else {
            dispatch(getQuestionnaireTwoHistory(viewing_username));
        }
    }, [questionnaireNumber, dispatch]);
    responses = useSelector(function (state) { return state.rootReducer.questionnaireHistoryReducer.responses; });
    data = useSelector(function (state) { return state.rootReducer.questionnaireHistoryReducer.graphData; });
    rightSide = useSelector(function (state) { return state.rootReducer.questionnaireHistoryReducer.score; });
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var viewing_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_user_type; });
    // Iterate over each section (Adviser Obligations, Your Obligations)
    for (var section in responses) {
        var sectionData = responses[section];
        processResponse(section, sectionData);
    }
    return (_jsxs("div", __assign({ className: "h-full" }, { children: [_jsx(NavBar, { viewing_user: viewing_user_type, logged_in_user: logged_in_user_type, viewing_username: viewing_username }), _jsxs("div", __assign({ className: "questionnaire-page-template-right" }, { children: [_jsx(PageHeader, { title: "Questionnaire ".concat(questionnaireNumber, " History"), backButton: "Back to History", backTo: "questionnaire" }), _jsx(Bar, __assign({ title: "Employability Area", className: "my-3 py-2" }, { children: _jsx(Card, { children: "".concat(day).concat(nthNumber(day), " ").concat(month, " ").concat(year) }) })), _jsx(DashboardGraps, { data: data, rightSide: rightSide }), _jsx(Bar, { title: "Your Answers", className: "my-5 py-4" }), _jsx(TableCard, { data: tableData, headers: ["Domain", "Questions", ""], rowsPerPage: 10, showSearchBar: true })] }))] })));
}
export default QuestionnaireResponses;
