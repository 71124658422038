// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adviser-card {
  min-height: 100vh;
}

.adviser-card div {
  margin: 0;
}

.adviser-field {
  color: #1e1e1e;
  font-size: 14px;
}

.adviser-field-content {
  color: #1e1e1e;
  font-size: 20px;
  margin-top: 0.13rem;
}

.circular-size {
  width: 6.9375rem;
  height: 6.9375rem;
  font-size: 2.5rem;
  margin: 2.25rem 0 4.25rem 1.25rem;
}

.adviser-content {
  margin-right: 10rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/adviserInfo/adviserInfo.css"],"names":[],"mappings":"AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,iCAAiC;AACnC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["@import \"../../styles/global.css\";\n\n.adviser-card {\n  min-height: 100vh;\n}\n\n.adviser-card div {\n  margin: 0;\n}\n\n.adviser-field {\n  color: #1e1e1e;\n  font-size: 14px;\n}\n\n.adviser-field-content {\n  color: #1e1e1e;\n  font-size: 20px;\n  margin-top: 0.13rem;\n}\n\n.circular-size {\n  width: 6.9375rem;\n  height: 6.9375rem;\n  font-size: 2.5rem;\n  margin: 2.25rem 0 4.25rem 1.25rem;\n}\n\n.adviser-content {\n  margin-right: 10rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
