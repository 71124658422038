var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import "./circularButton.css";
import "../../styles/main.css";
/**
 * The Circular Button
 * The component is simply a circular button.
 *
 * This component was mainly made to make smaller, avatar like, components that are clickable.
 * This is separate from the regular Button component which contains information about primary, error and secondary buttons.
 *
 * By creating as separate component, we avoid overcomplicating the Button Component.
 * If this was to be expanded to have Primary, Secondary and other styles, then it would be advisable to combine this with the Button component.
  */
function CircularButton(props) {
    var _a = useState(false), isPressed = _a[0], setPressed = _a[1];
    return (_jsx("button", __assign({ onClick: props.onClick, onMouseDown: function () { return setPressed(true); }, onMouseUp: function () { return setPressed(false); }, className: "circular cursor-pointer flex justify-center items-center ".concat(props.className, " ").concat(isPressed ? "pressed" : "", " ") }, { children: props.children })));
}
export default CircularButton;
