var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '../../atoms/button/button';
import { ButtonType } from '../../atoms/button/buttonType';
import DropDown from '../../atoms/dropDown/dropDown';
import useScreenSize from '../../atoms/useScreenSize/useScreenSize';
export default function GenericTab(props) {
    var _a = useState(0), current = _a[0], setCurrnet = _a[1];
    var screenSize = useScreenSize();
    console.log(screenSize);
    return (_jsxs("div", __assign({ className: 'flex felx-row justify-between' }, { children: [_jsx("div", __assign({ className: 'flex' }, { children: props.titles.map(function (v, num) {
                    return (_jsx(Button, { onClick: function () {
                            if (props.titles.length != 1) {
                                props.setStateCallback(num);
                                setCurrnet(num);
                            }
                        }, text: v, className: current === num ? "active" : "", buttonType: ButtonType.Tertiary }, num));
                }) })), _jsx("div", __assign({ className: 'flex justify-evenly align-middle w-1/2 ' }, { children: screenSize.width > 1440
                    ? props.children
                    :
                        _jsx(DropDown, __assign({ id: 'manageusers' }, { children: props.children })) }))] })));
}
