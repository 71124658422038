var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Button from '../button/button';
import { ButtonType } from '../button/buttonType';
export default function DropDown(props) {
    var _a = useState(false), isVisible = _a[0], setIsVisible = _a[1];
    useEffect(function () {
        document.addEventListener("click", handleClick);
        return function () {
            document.removeEventListener("click", handleClick);
        };
    });
    function handleClick(e) {
        var clickedElement = e.target;
        if (clickedElement instanceof Element && clickedElement.id !== "dropdown-".concat(props.id) && clickedElement.id !== "dropdown-".concat(props.id, "-child")) {
            setIsVisible(false);
        }
    }
    return (_jsxs("div", __assign({ className: "".concat(props.className) }, { children: [_jsx(Button, __assign({ className: 'h-[90%] w-[10vw]', buttonType: ButtonType.Primary, id: "dropdown-".concat(props.id), childId: "dropdown-".concat(props.id, "-child"), onClick: function () { return setIsVisible(!isVisible); } }, { children: "Actions" })), _jsx("div", __assign({ className: "dropdown-".concat(props.id, " ").concat(!isVisible && "hidden", " absolute z-[1] shadow rounded-2xl bg-white") }, { children: _jsx("div", __assign({ className: 'flex flex-col px-2 py-1 gap-y-2' }, { children: props.children })) }))] })));
}
