export var Dashboard;
(function (Dashboard) {
    Dashboard["Stats_Tiles"] = "Dashboard/Stats_Tiles";
    Dashboard["Stats_Tiles_Successful"] = "Dashboard/Stats_Tiles_Successful";
    Dashboard["Stats_Tiles_Error"] = "Dashboard/Stats_Tiles_Error";
    Dashboard["Stats_Tiles_Empty"] = "Dashboard/Stats_Tiles_Empty";
    Dashboard["Stats_Tiles_Loading"] = "Dashboard/Stats_Tiles_Loading";
    Dashboard["Ques_Attempt_Details"] = "Dashboard/Ques_Attempt_Details";
    Dashboard["Ques_Attempt_Details_Successful"] = "Dashboard/Ques_Attempt_Details_Successful";
    Dashboard["Ques_Attempt_Details_Error"] = "Dashboard/Ques_Attempt_Details_Error";
    Dashboard["Ques_Attempt_Details_Loading"] = "Dashboard/Ques_Attempt_Details_Loading";
    Dashboard["Adviser_Info"] = "Dashboard/Adviser_Info";
    Dashboard["Adviser_Info_Successful"] = "Dashboard/Adviser_Info_Successful";
    Dashboard["Adviser_Info_Error"] = "Dashboard/Adviser_Info_Error";
    Dashboard["Adviser_Info_Loading"] = "Dashboard/Adviser_Info_Loading";
    Dashboard["Update_Ques_Attempt_Details"] = "Dashboard/Update_Ques_Attempt_Details";
    Dashboard["Update_Ques_Attempt_Details_Successful"] = "Dashboard/Update_Ques_Attempt_Details_Successful";
    Dashboard["Update_Ques_Attempt_Details_Error"] = "Dashboard/Update_Ques_Attempt_Details_Error";
    Dashboard["Update_Ques_Attempt_Details_Loading"] = "Dashboard/Update_Ques_Attempt_Details_Loading";
    Dashboard["Update_Needs_Questionnaire"] = "Dashboard/Update_Needs_Questionnaire";
})(Dashboard || (Dashboard = {}));
