// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/vertical.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-landing {
  color: var(--primary);
}

.secondary-landing{
  color: var(--secondary);
}

.landing-vertical {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: repeat-y;
}

.m-left {
  margin-left: 5rem;
}

.faq-ans {
  display: none;
}

.text-stone {
  font-size: 1.25rem;
}

.font-size-2 {
  font-size: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/landing/landing.css"],"names":[],"mappings":"AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yDAAqD;EACrD,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["@import \"../../styles/global.css\";\n\n.primary-landing {\n  color: var(--primary);\n}\n\n.secondary-landing{\n  color: var(--secondary);\n}\n\n.landing-vertical {\n  background-image: url(\"../../../assets/vertical.png\");\n  background-repeat: repeat-y;\n}\n\n.m-left {\n  margin-left: 5rem;\n}\n\n.faq-ans {\n  display: none;\n}\n\n.text-stone {\n  font-size: 1.25rem;\n}\n\n.font-size-2 {\n  font-size: 2rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
