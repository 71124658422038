export var ManageUser;
(function (ManageUser) {
    ManageUser["ManageUser"] = "ManageUser/ManageUser";
    ManageUser["ManageUser_Sucessful"] = "ManageUser/Sucessful";
    ManageUser["ManageUserDashboard"] = "ManageUser/Dashboard";
    ManageUser["ManageUserDashboard_Sucessful"] = "ManageUser/Dashboard_sucessful";
    ManageUser["ManageUserDashboard_Empty"] = "ManageUser/Dashboard_Empty";
    ManageUser["ManageExportUsers"] = "ManageUser/exportUsers";
    ManageUser["ManageExportUsers_Sucessful"] = "ManageUser/exportUsers_sucessful";
    ManageUser["ManageArchiveUsers"] = "ManageUser/archiveUsers";
    ManageUser["ManageArchiveUsers_Sucessful"] = "ManageUser/archiveUsers_sucessful";
    ManageUser["ManageCreateUser"] = "ManageUser/MangeCreateUser";
    ManageUser["ManageCreateUser_Successful"] = "ManageUser/MangeCreateUserSuccessful";
    ManageUser["ManageCreateUser_Error"] = "ManageUser/MangeCreateUserError";
})(ManageUser || (ManageUser = {}));
