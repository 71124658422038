// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
  color: black;
  -webkit-text-fill-color: black;
}

.grad {
  background-color: #a1b6ff; /* For browsers that do not support gradients */
  background-image: linear-gradient(165.52deg, #a1b6ff -4.31%, #5148fa 90.88%);
}

.right-width {
  width: 50%;
}

.form-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 335px;
  height: 687px;
}

.icon {
  background: #d9d9d954;
  width: 35px;
  height: 30px;
  border-radius: 20px;
}

.icon img {
  width: 32px;
  height: 20px;
}

.checkbox-size {
  width: 13px !important;
  height: 13px !important;
  background: lightgray 50% / cover no-repeat;
}

.disp-flex {
  display: flex;
}

.login-btn p {
  font-size: 1.2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/login.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,yBAAyB,EAAE,+CAA+C;EAC1E,4EAA4E;AAC9E;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".login {\n  color: black;\n  -webkit-text-fill-color: black;\n}\n\n.grad {\n  background-color: #a1b6ff; /* For browsers that do not support gradients */\n  background-image: linear-gradient(165.52deg, #a1b6ff -4.31%, #5148fa 90.88%);\n}\n\n.right-width {\n  width: 50%;\n}\n\n.form-content {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.form {\n  width: 335px;\n  height: 687px;\n}\n\n.icon {\n  background: #d9d9d954;\n  width: 35px;\n  height: 30px;\n  border-radius: 20px;\n}\n\n.icon img {\n  width: 32px;\n  height: 20px;\n}\n\n.checkbox-size {\n  width: 13px !important;\n  height: 13px !important;\n  background: lightgray 50% / cover no-repeat;\n}\n\n.disp-flex {\n  display: flex;\n}\n\n.login-btn p {\n  font-size: 1.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
