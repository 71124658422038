var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import QuestionnaireOrg from "../../../organisms/jsQuestionnaire/jsQuestionnaire";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdviserObligationsAction, AdviserObligationsFormAction, } from "../../../../redux/actions/job_seeker_questionnaire";
import { updateNeedsQuestionnaire } from "../../../../redux/actions/dashboard";
/**
 * The Adviser Obligations Questionnaire Page
 * This will need to call all relevant APIs to get the values.
 * @param props
 * @returns
 */
function AdviserObligationsQuestionnaire() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _a = useState(""), error = _a[0], setError = _a[1];
    useEffect(function () {
        dispatch(AdviserObligationsAction());
    }, [dispatch]);
    //using selector hook to fetch data from the reducer
    var _b = useSelector(function (state) { return state.rootReducer.JobSeekerQuestionnaireReducer; }), adviserObligationsData = _b.adviserObligationsData, error_adviserObligations = _b.error_adviserObligations, loading = _b.loading, adviserObligationsFormData = _b.adviserObligationsFormData, success_adviserObligations_form = _b.success_adviserObligations_form;
    var loginData = useSelector(function (state) { return state.rootReducer.userReducer; }).loginData;
    //function to submit the input user data
    var handleFormSubmit = function (formData) {
        var sanitizedData = Object.keys(formData).reduce(function (acc, key) {
            acc[key] = DOMPurify.sanitize(formData[key]);
            return acc;
        }, {});
        dispatch(AdviserObligationsFormAction(sanitizedData, loginData));
    };
    var handleBackBtn = function () {
        navigate("/questionnaire/clientObligations");
    };
    var quesAttemptDetails = useSelector(function (state) { return state.rootReducer.dashboardReducer.quesAttemptDetails; });
    if (success_adviserObligations_form) {
        if (quesAttemptDetails && quesAttemptDetails.second_attempt_ready) {
            navigate("/questionnaire/adviserFeedback");
        }
        else {
            dispatch(updateNeedsQuestionnaire(false));
            navigate("/dashboard");
        }
    }
    var contentString = "For advisers and jobseekers to work well together, it's important that they both understand what they need to do and what they expect from each other. There is no right or wrong answer to these questions. If there is no need for the support listed just select \"No Extent\"";
    return (_jsxs("div", __assign({ className: "" }, { children: [error && _jsx("div", __assign({ className: "error-message" }, { children: error })), _jsx(QuestionnaireOrg, { title: "Adviser Obligations", content: contentString, data: adviserObligationsData, responses: adviserObligationsFormData, handleSubmit: handleFormSubmit, handleBackBtn: handleBackBtn, classname: "adviserObligations-w", width: "77%" })] })));
}
export default AdviserObligationsQuestionnaire;
