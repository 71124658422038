var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { QuestionnaireHistory } from "../constants/questionnaireHistoryConstants";
import { Status } from "../constants/status";
var initialState = {
    historyState: QuestionnaireHistory.HISTORY,
    numberOfAttempts: 0,
};
function parseAttempts(data) {
    var _a;
    return (_a = data === null || data === void 0 ? void 0 : data.length) !== null && _a !== void 0 ? _a : 0;
}
function shortDate(date) {
    var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    var day = date.getDate().toString().padStart(2, "0");
    var month = months[date.getMonth()];
    var year = date.getFullYear();
    return "".concat(day, " ").concat(month, " ").concat(year);
}
function parseDates(data) {
    var parsedData = [];
    data.forEach(function (d) {
        var date = new Date(d.completed_at);
        parsedData.push(shortDate(date));
    });
    return parsedData;
}
var BarLabels = {
    "General Skills Score": "General Skill",
    "Job search confidence Score": ["Job Search", "Confidence"],
    "Personal attributes Score": ["Personal", "Attributes"],
    "Immediacy of employment Score": ["Immediacy", "of employment"],
    "Assets Score": "Assets",
    "Job-seeking behaviours Score": ["Job Seeking", "Behaviours"],
};
function parseResponses(data) {
    var parsedData = {};
    parsedData["Demographics"] = data["Demographics"];
    parsedData["Searching for Work"] = data["Searching for Work"];
    parsedData["General Skills"] = data["General Skills"];
    parsedData["Job Search Confidence"] = data["Job Search Confidence"];
    parsedData["Personal Attributes"] = data["Personal Attributes"];
    parsedData["Qualifications and Work History"] =
        data["Qualifications and Work History"];
    parsedData["Health and Social Support"] = data["Health and Social Support"];
    parsedData["Proximity to Employment"] =
        data["Proximity to Employment: Self-Assessment"];
    parsedData["Overall Expectations"] = data["Overall Expectations"];
    parsedData["Your Obligations"] = data["Client obligations data"];
    parsedData["Adviser Obligations"] = data["Adviser obligations data"];
    return parsedData;
}
function parseGraphData(data) {
    var parsedData = [];
    Object.keys(data.strengths_scores).forEach(function (value) {
        if (typeof data.strengths_scores[value] === "number") {
            var label = BarLabels[value];
            parsedData.push({
                label: label,
                score: data.strengths_scores[value],
            });
        }
    });
    return parsedData;
}
var parseEmpScore = function (data) {
    var parsedData = [];
    parsedData.push({
        label: "Your Score",
        score: data.employability_score,
    });
    return parsedData;
};
var questionnaireHistoryReducer = function (state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case QuestionnaireHistory.HISTORY:
            return __assign(__assign({}, state), { children: undefined, status: Status.Loading });
        case QuestionnaireHistory.HISTORY_SUCESSFUL:
            return __assign(__assign({}, state), { numberOfAttempts: parseAttempts(action.payload), complete: (_b = (_a = action.payload[0]) === null || _a === void 0 ? void 0 : _a.complete) !== null && _b !== void 0 ? _b : 0, completedDates: parseDates(action.payload), status: Status.Successful });
        case QuestionnaireHistory.RESPONSE_HISTORY_1_SUCESSFUL:
            return __assign(__assign({}, state), { responses: parseResponses(action.payload), graphData: parseGraphData(action.payload), score: parseEmpScore(action.payload), status: Status.Successful });
        case QuestionnaireHistory.RESPONSE_HISTORY_2_SUCESSFUL:
            return __assign(__assign({}, state), { responses: parseResponses(action.payload), graphData: parseGraphData(action.payload), score: parseEmpScore(action.payload), status: Status.Successful });
        default:
            return state;
    }
};
export default questionnaireHistoryReducer;
