// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-template-right {
  width: calc(100% - 22.9375rem);
  height: 100%;
  margin-left: 22.9375rem;
  background-color: var(--background-color-darker);
  padding: 1.5rem 2.62rem;
  min-height: 100vh;
}


.page-hr{
  background: var(--grey);
  width: 100%;
  height: 0.0625rem;
  margin: 2.12rem 0rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/templates/general_page_template.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,YAAY;EACZ,uBAAuB;EACvB,gDAAgD;EAChD,uBAAuB;EACvB,iBAAiB;AACnB;;;AAGA;EACE,uBAAuB;EACvB,WAAW;EACX,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".page-template-right {\n  width: calc(100% - 22.9375rem);\n  height: 100%;\n  margin-left: 22.9375rem;\n  background-color: var(--background-color-darker);\n  padding: 1.5rem 2.62rem;\n  min-height: 100vh;\n}\n\n\n.page-hr{\n  background: var(--grey);\n  width: 100%;\n  height: 0.0625rem;\n  margin: 2.12rem 0rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
