// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionnaire {
  padding: 3% 4%;
}

.questionnaire-content {
  margin-top: 10px;
}

.question-answer {
  margin-top: 50px;
}

.question {
  color: #1e1e1e;
  font-size: 20px;
}

.choices {
  color: #1e1e1e;
}

.choices-size {
  margin-top: 30px;
}

.select-input {
  width: 23px !important;
  height: 23px !important;
}

.select-label {
  margin: 0 40px 0 12px;
}

.input-size {
  width: 271px !important;
  height: 47px !important;
}

.dropdown {
  width: 271px;
  height: 47px;
  padding: 12px 19px;
}

.matrix-heading {
  margin: 50px 0 100px 0;
}

.matrix-ques {
  width: 40%;
}

.matrix-ques-set-color {
  color: #1e1e1e;
}

.matrix-choices-size {
  width: 60%;
}

.matrix-choice {
  width: 25%;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/jsQuestionnaire/jsQuestionnaire.css"],"names":[],"mappings":"AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":["@import \"../../styles/global.css\";\n\n.questionnaire {\n  padding: 3% 4%;\n}\n\n.questionnaire-content {\n  margin-top: 10px;\n}\n\n.question-answer {\n  margin-top: 50px;\n}\n\n.question {\n  color: #1e1e1e;\n  font-size: 20px;\n}\n\n.choices {\n  color: #1e1e1e;\n}\n\n.choices-size {\n  margin-top: 30px;\n}\n\n.select-input {\n  width: 23px !important;\n  height: 23px !important;\n}\n\n.select-label {\n  margin: 0 40px 0 12px;\n}\n\n.input-size {\n  width: 271px !important;\n  height: 47px !important;\n}\n\n.dropdown {\n  width: 271px;\n  height: 47px;\n  padding: 12px 19px;\n}\n\n.matrix-heading {\n  margin: 50px 0 100px 0;\n}\n\n.matrix-ques {\n  width: 40%;\n}\n\n.matrix-ques-set-color {\n  color: #1e1e1e;\n}\n\n.matrix-choices-size {\n  width: 60%;\n}\n\n.matrix-choice {\n  width: 25%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
