// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circular {
  width: 4.125rem;
  height: 4.125rem;
  flex-shrink: 0;
  background-color: #6C63FF;
  color: #FFF;
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/button/circularButton.css"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,yBAAyB;EACzB,WAAW;EACX,kBAAkB;AACpB","sourcesContent":["@import '../../styles/global.css';\n\n.circular {\n  width: 4.125rem;\n  height: 4.125rem;\n  flex-shrink: 0;\n  background-color: #6C63FF;\n  color: #FFF;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
