var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import NavBar from "../../organisms/navBar/navBar";
import "./obligations.css";
import Tabs from "../../molecules/tabs/tabs";
import PageHeader from "../../organisms/pageHeader/pageHeader";
/**
 * The Obligations Page
 * This will need to call all relevant APIs to get the values.
 */
function Obligations() {
    var _a = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (state) { return state.rootReducer.dashboardReducer; }), obligations = _a.obligations, adviserObligations = _a.adviserObligations;
    var data = [
        {
            title: "Your Obligations",
            data: {
                label: "To what extent have you made the following commitment or obligation to your adviser?",
                data: obligations,
                choices: [
                    "No extent",
                    "Little extent",
                    "Some extent",
                    "Great extent",
                    "Very great extent",
                ],
            },
        },
        {
            title: "Adviser Obligations",
            data: {
                label: "To what extent has your adviser made the following commitment or obligation to you?",
                data: adviserObligations,
                choices: [
                    "No extent",
                    "Little extent",
                    "Some extent",
                    "Great extent",
                    "Very great extent",
                ],
            },
        },
    ];
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var viewing_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_user_type; });
    var viewing_username = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_username; });
    return (_jsxs("div", __assign({ className: "obligations" }, { children: [_jsx(NavBar, { viewing_user: viewing_user_type, logged_in_user: logged_in_user_type, viewing_username: viewing_username }), _jsxs("div", __assign({ className: "obligations-page-template-right" }, { children: [_jsx(PageHeader, { title: "Obligations" }), _jsx("div", __assign({ className: "flex flex-shrink-0 obligations-content" }, { children: _jsx(Tabs, { tabs: ["Your Obligations", "Adviser Obligations"], data: data, type: "obligations", classname: "obligations-ques-ans" }) }))] }))] })));
}
export default Obligations;
