/**
 * Constant declaration for the redux store.
 */
export var Questionnaire;
(function (Questionnaire) {
    Questionnaire["ANTECEDENTS"] = "ANTECEDENTS";
    Questionnaire["ANTECEDENTS_SUCCESS"] = "ANTECEDENTS_SUCCESS";
    Questionnaire["ANTECEDENTS_FAILURE"] = "ANTECEDENTS_FAILURE";
    Questionnaire["ANTECEDENTS_FORM"] = "ANTECEDENTS_FORM";
    Questionnaire["ANTECEDENTS_FORM_SUCCESS"] = "ANTECEDENTS_FORM_SUCCESS";
    Questionnaire["ANTECEDENTS_FORM_FAILURE"] = "ANTECEDENTS_FORM_FAILURE";
    Questionnaire["KNOWLEDGE"] = "KNOWLEDGE";
    Questionnaire["KNOWLEDGE_SUCCESS"] = "KNOWLEDGE_SUCCESS";
    Questionnaire["KNOWLEDGE_FAILURE"] = "KNOWLEDGE_FAILURE";
    Questionnaire["KNOWLEDGE_FORM"] = "KNOWLEDGE_FORM";
    Questionnaire["KNOWLEDGE_FORM_SUCCESS"] = "KNOWLEDGE_FORM_SUCCESS";
    Questionnaire["KNOWLEDGE_FORM_FAILURE"] = "KNOWLEDGE_FORM_FAILURE";
    Questionnaire["GENERAL_SKILLS"] = "GENERAL_SKILLS";
    Questionnaire["GENERAL_SKILLS_SUCCESS"] = "GENERAL_SKILLS_SUCCESS";
    Questionnaire["GENERAL_SKILLS_FAILURE"] = "GENERAL_SKILLS_FAILURE";
    Questionnaire["GENERAL_SKILLS_FORM"] = "GENERAL_SKILLS_FORM";
    Questionnaire["GENERAL_SKILLS_FORM_SUCCESS"] = "GENERAL_SKILLS_FORM_SUCCESS";
    Questionnaire["GENERAL_SKILLS_FORM_FAILURE"] = "GENERAL_SKILLS_FORM_FAILURE";
    Questionnaire["CONFIDENCE"] = "CONFIDENCE";
    Questionnaire["CONFIDENCE_SUCCESS"] = "CONFIDENCE_SUCCESS";
    Questionnaire["CONFIDENCE_FAILURE"] = "CONFIDENCE_FAILURE";
    Questionnaire["CONFIDENCE_FORM"] = "CONFIDENCE_FORM";
    Questionnaire["CONFIDENCE_FORM_SUCCESS"] = "CONFIDENCE_FORM_SUCCESS";
    Questionnaire["CONFIDENCE_FORM_FAILURE"] = "CONFIDENCE_FORM_FAILURE";
    Questionnaire["ATTRIBUTES"] = "ATTRIBUTES";
    Questionnaire["ATTRIBUTES_SUCCESS"] = "ATTRIBUTES_SUCCESS";
    Questionnaire["ATTRIBUTES_FAILURE"] = "ATTRIBUTES_FAILURE";
    Questionnaire["ATTRIBUTES_FORM"] = "ATTRIBUTES_FORM";
    Questionnaire["ATTRIBUTES_FORM_SUCCESS"] = "ATTRIBUTES_FORM_SUCCESS";
    Questionnaire["ATTRIBUTES_FORM_FAILURE"] = "ATTRIBUTES_FORM_FAILURE";
    Questionnaire["QUALIFICATIONS"] = "QUALIFICATIONS";
    Questionnaire["QUALIFICATIONS_SUCCESS"] = "QUALIFICATIONS_SUCCESS";
    Questionnaire["QUALIFICATIONS_FAILURE"] = "QUALIFICATIONS_FAILURE";
    Questionnaire["QUALIFICATIONS_FORM"] = "QUALIFICATIONS_FORM";
    Questionnaire["QUALIFICATIONS_FORM_SUCCESS"] = "QUALIFICATIONS_FORM_SUCCESS";
    Questionnaire["QUALIFICATIONS_FORM_FAILURE"] = "QUALIFICATIONS_FORM_FAILURE";
    Questionnaire["SOCIAL"] = "SOCIAL";
    Questionnaire["SOCIAL_SUCCESS"] = "SOCIAL_SUCCESS";
    Questionnaire["SOCIAL_FAILURE"] = "SOCIAL_FAILURE";
    Questionnaire["SOCIAL_FORM"] = "SOCIAL_FORM";
    Questionnaire["SOCIAL_FORM_SUCCESS"] = "SOCIAL_FORM_SUCCESS";
    Questionnaire["SOCIAL_FORM_FAILURE"] = "SOCIAL_FORM_FAILURE";
    Questionnaire["PROGRESSION"] = "PROGRESSION";
    Questionnaire["PROGRESSION_SUCCESS"] = "PROGRESSION_SUCCESS";
    Questionnaire["PROGRESSION_FAILURE"] = "PROGRESSION_FAILURE";
    Questionnaire["PROGRESSION_FORM"] = "PROGRESSION_FORM";
    Questionnaire["PROGRESSION_FORM_SUCCESS"] = "PROGRESSION_FORM_SUCCESS";
    Questionnaire["PROGRESSION_FORM_FAILURE"] = "PROGRESSION_FORM_FAILURE";
    Questionnaire["GLOBAL"] = "GLOBAL";
    Questionnaire["GLOBAL_SUCCESS"] = "GLOBAL_SUCCESS";
    Questionnaire["GLOBAL_FAILURE"] = "GLOBAL_FAILURE";
    Questionnaire["GLOBAL_FORM"] = "GLOBAL_FORM";
    Questionnaire["GLOBAL_FORM_SUCCESS"] = "GLOBAL_FORM_SUCCESS";
    Questionnaire["GLOBAL_FORM_FAILURE"] = "GLOBAL_FORM_FAILURE";
    Questionnaire["CLIENT_OBLIGATIONS"] = "CLIENT_OBLIGATIONS";
    Questionnaire["CLIENT_OBLIGATIONS_SUCCESS"] = "CLIENT_OBLIGATIONS_SUCCESS";
    Questionnaire["CLIENT_OBLIGATIONS_FAILURE"] = "CLIENT_OBLIGATIONS_FAILURE";
    Questionnaire["CLIENT_OBLIGATIONS_FORM"] = "CLIENT_OBLIGATIONS_FORM";
    Questionnaire["CLIENT_OBLIGATIONS_FORM_SUCCESS"] = "CLIENT_OBLIGATIONS_FORM_SUCCESS";
    Questionnaire["CLIENT_OBLIGATIONS_FORM_FAILURE"] = "CLIENT_OBLIGATIONS_FORM_FAILURE";
    Questionnaire["ADVISER_OBLIGATIONS"] = "ADVISER_OBLIGATIONS";
    Questionnaire["ADVISER_OBLIGATIONS_SUCCESS"] = "ADVISER_OBLIGATIONS_SUCCESS";
    Questionnaire["ADVISER_OBLIGATIONS_FAILURE"] = "ADVISER_OBLIGATIONS_FAILURE";
    Questionnaire["ADVISER_OBLIGATIONS_FORM"] = "ADVISER_OBLIGATIONS_FORM";
    Questionnaire["ADVISER_OBLIGATIONS_FORM_SUCCESS"] = "ADVISER_OBLIGATIONS_FORM_SUCCESS";
    Questionnaire["ADVISER_OBLIGATIONS_FORM_FAILURE"] = "ADVISER_OBLIGATIONS_FORM_FAILURE";
    Questionnaire["ADVISER_DEMOGRAPHCS"] = "ADVISER_DEMOGRAPHCS";
    Questionnaire["ADVISER_DEMOGRAPHCS_SUCCESS"] = "ADVISER_DEMOGRAPHCS_SUCCESS";
    Questionnaire["ADVISER_DEMOGRAPHCS_FAILURE"] = "ADVISER_DEMOGRAPHCS_FAILURE";
    Questionnaire["ADVISER_PROGRESSION"] = "ADVISER_PROGRESSION";
    Questionnaire["ADVISER_PROGRESSION_SUCCESS"] = "ADVISER_PROGRESSION_SUCCESS";
    Questionnaire["ADVISER_PROGRESSION_FAILURE"] = "ADVISER_PROGRESSION_FAILURE";
    Questionnaire["ADVISER_CLIENT_OBLIGATIONS"] = "ADVISER_CLIENT_OBLIGATIONS";
    Questionnaire["ADVISER_CLIENT_OBLIGATIONS_SUCCESS"] = "ADVISER_CLIENT_OBLIGATIONS_SUCCESS";
    Questionnaire["ADVISER_CLIENT_OBLIGATIONS_FAILURE"] = "ADVISER_CLIENT_OBLIGATIONS_FAILURE";
    Questionnaire["ADVISER_ADVISER_OBLIGATIONS"] = "ADVISER_ADVISER_OBLIGATIONS";
    Questionnaire["ADVISER_ADVISER_OBLIGATIONS_SUCCESS"] = "ADVISER_ADVISER_OBLIGATIONS_SUCCESS";
    Questionnaire["ADVISER_ADVISER_OBLIGATIONS_FAILURE"] = "ADVISER_ADVISER_OBLIGATIONS_FAILURE";
    Questionnaire["ADVISER_GLOBAL"] = "ADVISER_GLOBAL";
    Questionnaire["ADVISER_GLOBAL_SUCCESS"] = "ADVISER_GLOBAL_SUCCESS";
    Questionnaire["ADVISER_GLOBAL_FAILURE"] = "ADVISER_GLOBAL_FAILURE";
    Questionnaire["ADVISER_FEEDBACK"] = "ADVISER_FEEDBACK";
    Questionnaire["ADVISER_FEEDBACK_SUCCESS"] = "ADVISER_FEEDBACK_SUCCESS";
    Questionnaire["ADVISER_FEEDBACK_FAILURE"] = "ADVISER_FEEDBACK_FAILURE";
    Questionnaire["ADVISER_FEEDBACK_FORM"] = "ADVISER_FEEDBACK_FORM";
    Questionnaire["ADVISER_FEEDBACK_FORM_SUCCESS"] = "ADVISER_FEEDBACK_FORM_SUCCESS";
    Questionnaire["ADVISER_FEEDBACK_FORM_FAILURE"] = "ADVISER_FEEDBACK_FORM_FAILURE";
    Questionnaire["ADVISER_IMPACT"] = "ADVISER_IMPACT";
    Questionnaire["ADVISER_IMPACT_SUCCESS"] = "ADVISER_IMPACT_SUCCESS";
    Questionnaire["ADVISER_IMPACT_FAILURE"] = "ADVISER_IMPACT_FAILURE";
    Questionnaire["ADVISER_IMPACT_FORM"] = "ADVISER_IMPACT_FORM";
    Questionnaire["ADVISER_IMPACT_FORM_SUCCESS"] = "ADVISER_IMPACT_FORM_SUCCESS";
    Questionnaire["ADVISER_IMPACT_FORM_FAILURE"] = "ADVISER_IMPACT_FORM_FAILURE";
    Questionnaire["OVERALL_EXPERIENCE"] = "OVERALL_EXPERIENCE";
    Questionnaire["OVERALL_EXPERIENCE_SUCCESS"] = "OVERALL_EXPERIENCE_SUCCESS";
    Questionnaire["OVERALL_EXPERIENCE_FAILURE"] = "OVERALL_EXPERIENCE_FAILURE";
    Questionnaire["OVERALL_EXPERIENCE_FORM"] = "OVERALL_EXPERIENCE_FORM";
    Questionnaire["OVERALL_EXPERIENCE_FORM_SUCCESS"] = "OVERALL_EXPERIENCE_FORM_SUCCESS";
    Questionnaire["OVERALL_EXPERIENCE_FORM_FAILURE"] = "OVERALL_EXPERIENCE_FORM_FAILURE";
})(Questionnaire || (Questionnaire = {}));
