var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RegisterUser } from "../../redux/actions/authentication";
import Button from "../atoms/button/button";
import LeftCoverImage from "../atoms/leftCoverImage/leftCoverImage";
import InputField from "../molecules/inputField/inputField";
import BrandHeader from "../molecules/brandHeader/brandHeader";
import "../styles/main.css";
import "../styles/login.css";
import LoginTemplate from "../templates/loginTemplate";
import ErrorMessage from "../atoms/errorMessage/errorMessage";
import { validateFormData } from "./questionnaire/questionnaires/validationUtils";
/**
 * The Login Page
 * This will need to call all relevant APIs to get the values.
 * @param props
 * @returns
 */
function Register() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var searchParams = useSearchParams()[0];
    var token = searchParams.get("token");
    var success = useSelector(function (state) { return state.rootReducer.userReducer.registerSuccess; });
    if (success) {
        navigate("/");
    }
    if (token == undefined || token === "") {
        navigate("/");
    }
    //creating react state hook to store username and password locally
    var _a = useState({
        username: "",
        password: "",
        confirmpassword: "",
    }), loginData = _a[0], setLoginData = _a[1];
    var _b = useState({
        username: "",
        password: "",
    }), errorMessage = _b[0], setErrorMessage = _b[1];
    //function to submit the input user data
    var handleSubmit = function (e) {
        var _a;
        e.preventDefault();
        var validation = validateFormData(loginData);
        if (!(validation === null || validation === void 0 ? void 0 : validation.isValid)) {
            var errorId = validation === null || validation === void 0 ? void 0 : validation.errorField;
            setErrorMessage(__assign(__assign({}, errorMessage), (_a = {}, _a[errorId] = validation === null || validation === void 0 ? void 0 : validation.errorMessage, _a)));
        }
        else {
            dispatch(RegisterUser(loginData.username, loginData.password, token));
        }
    };
    //function to change the values in the input fields entered by the user
    var handleChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setLoginData(__assign(__assign({}, loginData), (_a = {}, _a[name] = value, _a)));
    };
    //navigation to dashboard page on successful login
    return (_jsx(LoginTemplate, { leftImage: _jsx(LeftCoverImage, {}), form: _jsxs("form", __assign({ onSubmit: handleSubmit, className: "form m-3" }, { children: [_jsx(BrandHeader, {}), _jsx("h1", __assign({ className: "heading mt-5" }, { children: "Login" })), _jsx("div", __assign({ className: "h3 mb-3 mt-1 font-medium text grey" }, { children: "Welcome aboard! Let&aposs kickstart your journey by setting up your account" })), _jsx(InputField, { label: "Username", type: "text", id: "id_username", name: "username", placeholder: "", value: loginData.username, onChange: handleChange, className: "login mt-2" }), !loginData.username ? (_jsx(ErrorMessage, { message: "This field is required*" }, "username")) : (""), _jsx(InputField, { label: "Password", type: "password", id: "id_password", name: "password", placeholder: "", value: loginData.password, onChange: handleChange, className: "login mt-2" }), !loginData.password ? (_jsx(ErrorMessage, { message: "This field is required*" }, "password")) : (""), " ", _jsx(InputField, { label: "Confirm Password", type: "password", id: "id_password", name: "confirmpassword", placeholder: "", value: loginData.confirmpassword, onChange: handleChange, className: "login mt-2" }), loginData.password !== loginData.confirmpassword ? (_jsx(ErrorMessage, { message: "Password do not match!" }, "password")) : (""), " ", _jsx("div", { className: "float-right mt-5 mb-10" }), _jsx("div", __assign({ className: "pt-6" }, { children: _jsx(Button, { border: "none", onClick: function () { return handleSubmit; }, text: "Setup Account", className: "mt-6" }) }))] })) }));
}
export default Register;
