var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SectionHeader from '../../atoms/sectionHeader/sectionHeader';
import "./bar.css";
/**
 * The Bar Component.
 * This component is usually seen when dealing with a title on the LHS and other actions on the RHS.
 * This will take the available space given.
 * @param props
 */
export default function Bar(props) {
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "flex flex-row topbar justify-between align-middle ".concat(props.className) }, { children: [_jsx(SectionHeader, { title: props.title }), _jsx("div", __assign({ className: 'flex flex-row align-middle' }, { children: props.children }))] })) }));
}
