var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Logo from '../../atoms/logo/logo';
import "../../styles/main.css";
import "../../styles/global.css";
import "./brandHeader.css";
/**
 * The Brand Header.
 * This is the MyEmploy Logo (Atoms) along with the Brand Name and Slogan.
 * This will take up a fixed size but additional properties can be changed by passing in classNames (tailwind)
 * This can be seen at the Top Left of the DashBoard
 */
export default function BrandHeader(props) {
    var logoStyle = {
        marginRight: "1.06rem"
    };
    return (_jsxs("div", __assign({ className: "flex flex-row ".concat(props.className) }, { children: [_jsx(Logo, { style: logoStyle }), _jsxs("div", { children: [_jsx("h1", __assign({ className: 'top-text' }, { children: "My Employ" })), _jsx("p", __assign({ className: 'alt-text' }, { children: "Unleashing Potential" }))] })] })));
}
