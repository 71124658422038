export var Questionnaire;
(function (Questionnaire) {
    Questionnaire["ADVISER_DEMOGRAPHICS"] = "ADVISER_DEMOGRAPHICS";
    Questionnaire["ADVISER_DEMOGRAPHICS_SUCCESS"] = "ADVISER_DEMOGRAPHICS_SUCCESS";
    Questionnaire["ADVISER_DEMOGRAPHICS_FAILURE"] = "ADVISER_DEMOGRAPHICS_FAILURE";
    Questionnaire["ADVISER_DEMOGRAPHICS_FORM"] = "ADVISER_DEMOGRAPHICS_FORM";
    Questionnaire["ADVISER_DEMOGRAPHICS_FORM_SUCCESS"] = "ADVISER_DEMOGRAPHICS_FORM_SUCCESS";
    Questionnaire["ADVISER_DEMOGRAPHICS_FORM_FAILURE"] = "ADVISER_DEMOGRAPHICS_FORM_FAILURE";
    Questionnaire["ADVISER_PROGRESSION"] = "ADVISER_PROGRESSION";
    Questionnaire["ADVISER_PROGRESSION_SUCCESS"] = "ADVISER_PROGRESSION_SUCCESS";
    Questionnaire["ADVISER_PROGRESSION_FAILURE"] = "ADVISER_PROGRESSION_FAILURE";
    Questionnaire["ADVISER_PROGRESSION_FORM"] = "ADVISER_PROGRESSION_FORM";
    Questionnaire["ADVISER_PROGRESSION_FORM_SUCCESS"] = "ADVISER_PROGRESSION_FORM_SUCCESS";
    Questionnaire["ADVISER_PROGRESSION_FORM_FAILURE"] = "ADVISER_PROGRESSION_FORM_FAILURE";
    Questionnaire["ADVISER_CLIENT_OBLIGATIONS"] = "ADVISER_CLIENT_OBLIGATIONS";
    Questionnaire["ADVISER_CLIENT_OBLIGATIONS_SUCCESS"] = "ADVISER_CLIENT_OBLIGATIONS_SUCCESS";
    Questionnaire["ADVISER_CLIENT_OBLIGATIONS_FAILURE"] = "ADVISER_CLIENT_OBLIGATIONS_FAILURE";
    Questionnaire["ADVISER_CLIENT_OBLIGATIONS_FORM"] = "ADVISER_CLIENT_OBLIGATIONS_FORM";
    Questionnaire["ADVISER_CLIENT_OBLIGATIONS_FORM_SUCCESS"] = "ADVISER_CLIENT_OBLIGATIONS_FORM_SUCCESS";
    Questionnaire["ADVISER_CLIENT_OBLIGATIONS_FORM_FAILURE"] = "ADVISER_CLIENT_OBLIGATIONS_FORM_FAILURE";
    Questionnaire["ADVISER_ADVISER_OBLIGATIONS"] = "ADVISER_ADVISER_OBLIGATIONS";
    Questionnaire["ADVISER_ADVISER_OBLIGATIONS_SUCCESS"] = "ADVISER_ADVISER_OBLIGATIONS_SUCCESS";
    Questionnaire["ADVISER_ADVISER_OBLIGATIONS_FAILURE"] = "ADVISER_ADVISER_OBLIGATIONS_FAILURE";
    Questionnaire["ADVISER_ADVISER_OBLIGATIONS_FORM"] = "ADVISER_ADVISER_OBLIGATIONS_FORM";
    Questionnaire["ADVISER_ADVISER_OBLIGATIONS_FORM_SUCCESS"] = "ADVISER_ADVISER_OBLIGATIONS_FORM_SUCCESS";
    Questionnaire["ADVISER_ADVISER_OBLIGATIONS_FORM_FAILURE"] = "ADVISER_ADVISER_OBLIGATIONS_FORM_FAILURE";
    Questionnaire["ADVISER_GLOBAL"] = "ADVISER_GLOBAL";
    Questionnaire["ADVISER_GLOBAL_SUCCESS"] = "ADVISER_GLOBAL_SUCCESS";
    Questionnaire["ADVISER_GLOBAL_FAILURE"] = "ADVISER_GLOBAL_FAILURE";
    Questionnaire["ADVISER_GLOBAL_FORM"] = "ADVISER_GLOBAL_FORM";
    Questionnaire["ADVISER_GLOBAL_FORM_SUCCESS"] = "ADVISER_GLOBAL_FORM_SUCCESS";
    Questionnaire["ADVISER_GLOBAL_FORM_FAILURE"] = "ADVISER_GLOBAL_FORM_FAILURE";
    Questionnaire["ADVISER_JOBSEEKER_OUTCOME"] = "ADVISER_JOBSEEKER_OUTCOME";
    Questionnaire["ADVISER_JOBSEEKER_OUTCOME_SUCCESS"] = "ADVISER_JOBSEEKER_OUTCOME_SUCCESS";
    Questionnaire["ADVISER_JOBSEEKER_OUTCOME_FAILURE"] = "ADVISER_JOBSEEKER_OUTCOME_FAILURE";
    Questionnaire["ADVISER_JOBSEEKER_OUTCOME_FORM"] = "ADVISER_JOBSEEKER_OUTCOME_FORM";
    Questionnaire["ADVISER_JOBSEEKER_OUTCOME_FORM_SUCCESS"] = "ADVISER_JOBSEEKER_OUTCOME_FORM_SUCCESS";
    Questionnaire["ADVISER_JOBSEEKER_OUTCOME_FORM_FAILURE"] = "ADVISER_JOBSEEKER_OUTCOME_FORM_FAILURE";
})(Questionnaire || (Questionnaire = {}));
