var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import "../styles/main.css";
import "../styles/login.css";
import LeftCoverImage from "../atoms/leftCoverImage/leftCoverImage";
import BrandHeader from "../molecules/brandHeader/brandHeader";
import Button from "../atoms/button/button";
import LoginTemplate from "../templates/loginTemplate";
/**
 * The Password Reset Confirmation Page
 * This will need to call all relevant APIs to get the values.
 * @param props
 * @returns
 */
function PasswordResetEmail() {
    var navigate = useNavigate();
    //function to submit the input user data
    var handleSubmit = function (e) {
        e.preventDefault();
        sessionStorage.setItem("logout", "true");
        navigate("/login");
    };
    return (_jsx(LoginTemplate, { leftImage: _jsx(LeftCoverImage, { second: true }), form: _jsxs("form", __assign({ onSubmit: handleSubmit, className: "form m-3" }, { children: [_jsx(BrandHeader, {}), _jsx("h1", __assign({ className: "heading mt-5" }, { children: "Password Recovery" })), _jsx("div", __assign({ className: "h3 mb-3 mt-1 font-medium text grey" }, { children: "We have send you a password reset link to your email address. Please check your spam folder if you can't find it." })), _jsx("div", { className: "float-right" }), _jsx("div", __assign({ className: "pt-6" }, { children: _jsx(Button, { border: "none", onClick: function () { return handleSubmit; }, text: "Continue", className: "mt-6" }) }))] })) }));
}
export default PasswordResetEmail;
