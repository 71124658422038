var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Bar } from "react-chartjs-2";
import { Chart, registerables, } from "chart.js";
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(annotationPlugin);
Chart.register.apply(Chart, __spreadArray(__spreadArray([], registerables, false), [ChartjsPluginStacked100, ChartDataLabels], false));
import "./stackedBar.css";
import { Status } from "../../../redux/constants/status";
import GraphShimmer from "./GraphShimmer";
// const average = (values: number[]) => {
//     let sum = 0;
//     for (let i = 0; i < values.length; i++) {
//         sum += values[i];
//     }
//     return sum / values.length
// }
var StackedBar = function (props) {
    if (props.status === Status.Successful && props.values) {
        var dataFormat = {
            labels: props.labels,
            datasets: [
                {
                    label: "Score",
                    data: props.values.map(function (data) { return [27, data]; }),
                    backgroundColor: function (context) {
                        var ctx = context.chart.ctx;
                        var x1 = 300 * Math.sin(5.88176); // angle in radians
                        var y1 = 300 * Math.cos(5.88176); // angle in radians
                        var gradient = ctx.createLinearGradient(0, 0, x1, y1);
                        gradient.addColorStop(0, "#6c63ff");
                        gradient.addColorStop(1, "#9994FF");
                        return gradient;
                    },
                    borderRadius: 200,
                    borderSkipped: false,
                    barPercentage: 0.4,
                },
                {
                    label: "Remaining Score",
                    data: props.values.map(function (data) { return [
                        Math.min(data + 7, 146),
                        146,
                    ]; }),
                    backgroundColor: function (context) {
                        var ctx = context.chart.ctx;
                        var x1 = 300 * Math.sin(5.88176); // angle in radians
                        var y1 = 300 * Math.cos(5.88176); // angle in radians
                        var gradient = ctx.createLinearGradient(0, 0, x1, y1);
                        gradient.addColorStop(0, "#FFA96F");
                        gradient.addColorStop(1, "#FFDDC7");
                        return gradient;
                    },
                    borderRadius: 200,
                    borderSkipped: false,
                    barPercentage: 0.4,
                },
            ],
        };
        return (_jsx("div", __assign({ className: "canvas-container" }, { children: _jsx(Bar, { data: dataFormat, options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        datalabels: {
                            display: true,
                            color: "white",
                            formatter: function (value, context) {
                                if (context.dataset.label === "Remaining Score") {
                                    return value[1] - (value[0] - 7);
                                }
                                else
                                    return value[1];
                            },
                        },
                        // annotation: {
                        //     annotations: {
                        //         line1: {
                        //             type: 'line',
                        //             yMin: average(props.values),
                        //             yMax: average(props.values),
                        //             borderColor: '#CBCBCB',
                        //             borderWidth: 1,
                        //             label: {
                        //                 display: true,
                        //                 content: 'Average Score: ' + Math.ceil((average(props.values) / 160) * 100) + '%',
                        //                 backgroundColor: "#FFF",
                        //                 borderColor: "#ADADAD",
                        //                 borderRadius: 50,
                        //                 borderWidth: 0.5,
                        //                 color: '#1E1E1E',
                        //                 font: {
                        //                     size: 10,
                        //                     family: 'Manrope',
                        //                     weight: 'normal'
                        //                 },
                        //                 padding: 10,
                        //                 position: "30%",
                        //                 drawTime: 'afterDatasetsDraw'
                        //             },
                        //             drawTime: 'beforeDatasetsDraw'
                        //         }
                        //     }
                        // },
                        legend: {
                            display: true,
                        },
                        title: {
                            display: true,
                            text: "Changes In Employability Score",
                            align: "start",
                            font: {
                                family: "Manrope",
                                size: 18,
                                weight: "normal",
                            },
                            color: "#1E1E1E",
                            padding: {
                                bottom: 30,
                            },
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    var _a;
                                    var label = context.dataset.label || "";
                                    if (label) {
                                        label += ": ";
                                    }
                                    if (context.parsed.y !== null) {
                                        var value = 0;
                                        if (context.dataset.label === "Remaining Score" &&
                                            ((_a = context.parsed._custom) === null || _a === void 0 ? void 0 : _a.barStart)) {
                                            value =
                                                context.parsed._custom.barEnd -
                                                    (context.parsed._custom.barStart - 7);
                                        }
                                        else {
                                            value = context.parsed.y;
                                        }
                                        label += value;
                                    }
                                    return label;
                                },
                            },
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            stacked: true,
                            ticks: {
                                font: {
                                    family: "Manrope",
                                    size: 15,
                                },
                                color: "#1E1E1E",
                            },
                        },
                        y: {
                            grid: {
                                display: false,
                            },
                            min: 20,
                            max: 146,
                            ticks: {
                                font: {
                                    family: "Manrope",
                                    size: 14,
                                },
                                color: "#777777",
                            },
                        },
                    },
                } }) })));
    }
    return _jsx(GraphShimmer, {});
};
export default StackedBar;
