var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineReducers } from "redux";
import userReducer from "./authentication";
import dashboardReducer from "./dashboard";
import JobSeekerQuestionnaireReducer from "./job_seeker_questionnaire";
import questionnaireHistoryReducer from "./questionnaireHistory";
import profileReducer from "./profile";
import AdviserQuestionnaireReducer from "./adviser_questionnaire";
import ManageUserReducer from "./manage_users";
export var appReducer = combineReducers({
    userReducer: userReducer,
    dashboardReducer: dashboardReducer,
    JobSeekerQuestionnaireReducer: JobSeekerQuestionnaireReducer,
    questionnaireHistoryReducer: questionnaireHistoryReducer,
    profileReducer: profileReducer,
    AdviserQuestionnaireReducer: AdviserQuestionnaireReducer,
    ManageUserReducer: ManageUserReducer,
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function rootReducer(state, action) {
    if (action.type == "LOGOUT_USER") {
        sessionStorage.clear();
        return appReducer(undefined, action);
    }
    if (action.type == 'Dashboard/Stats_Tiles_Successful') {
        state = __assign(__assign({}, state), { JobSeekerQuestionnaireReducer: JobSeekerQuestionnaireReducer(undefined, action), AdviserQuestionnaireReducer: AdviserQuestionnaireReducer(undefined, action) });
    }
    return appReducer(state, action);
}
