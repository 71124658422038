var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BrandHeader from "../brandHeader/brandHeader";
import "./jsQuestionnaireHeader.css";
import { useSelector } from "react-redux";
/**
 * The Job Seeker Questionnaire Header.
 * This is the top most part in the questionnaire which is same for all the questionnaire for the job seeker.
 * The headings are constructed which are then assigned their Title, content, progressive bar width.
 */
export default function JSQuestionnaireHeader(props) {
    var quesAttemptDetails = useSelector(function (state) { return state.rootReducer.dashboardReducer.quesAttemptDetails; });
    var progressBar = {
        width: "".concat(props.width),
    };
    return (_jsxs("div", __assign({ className: "" }, { children: [_jsx("div", __assign({ className: "flex justify-between" }, { children: _jsx(BrandHeader, {}) })), _jsxs("div", __assign({ className: "m-top" }, { children: [_jsx("p", __assign({ className: "heading capitalize font-medium not-italic set-line-height" }, { children: "Questionnaire" })), _jsx("div", __assign({ className: "slider" }, { children: props.width ? (_jsx("div", { className: "inner-slider inner-slider-w ".concat(props.classname), style: progressBar })) : (_jsx("div", { className: "inner-slider inner-slider-w ".concat(props.classname) })) }))] })), _jsxs("div", __assign({ className: "questionnaire-heading-m" }, { children: [_jsx("p", __assign({ className: "questionnaire-heading capitalize font-medium not-italic set-line-height" }, { children: props.title })), _jsx("p", __assign({ className: "questionnaire-heading-content font-medium not-italic set-line-height" }, { children: props.content }))] }))] })));
}
