var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { takeEvery, call, put, getContext } from "redux-saga/effects";
import Cookies from "js-cookie";
import { Questionnaire } from "../constants/adviser_questionnaire";
import store from "../store";
import { APIType } from "../services/authenticationService";
/**
 * The Adviser Demographics Generator function
 * This function is responsible for handling Adviser Demographics service calls.
 * @param props
 */
function AdviserDemographicsSaga() {
    var callAPI, label_1, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                label_1 = "In regard to your current performance, please select the more accurate option below:";
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/adviser_demographics/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, label_1);
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_DEMOGRAPHICS_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_DEMOGRAPHICS_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_1 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_DEMOGRAPHICS_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AdviserDemographicsFormSaga(action) {
    var callAPI, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit_adviser_demographics/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_DEMOGRAPHICS_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_DEMOGRAPHICS_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_2 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_DEMOGRAPHICS_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Adviser Progression Generator function
 * This function is responsible for handling Adviser Progression service calls.
 * @param props
 */
function AdviserProgressionSaga() {
    var callAPI, label_2, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                label_2 = "";
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/adviser_progression/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, label_2);
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_PROGRESSION_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_PROGRESSION_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_3 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_PROGRESSION_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AdviserProgressionFormSaga(action) {
    var viewing_username, callAPI, response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                viewing_username = store.getState().rootReducer.dashboardReducer.viewing_username;
                return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit_adviser_questionnaire/adviser_progression/?jobseeker=".concat(viewing_username), { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_PROGRESSION_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_PROGRESSION_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_4 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_PROGRESSION_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Adviser Client Obligations Generator function
 * This function is responsible for handling Adviser Client Obligations service calls.
 * @param props
 */
function AdviserClientObligationsSaga() {
    var callAPI, label_3, response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                label_3 = "To what extent has your jobseeker made the following commitment or obligation to you?";
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/adviser_client_obligations/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, label_3);
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_CLIENT_OBLIGATIONS_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_5 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AdviserClientObligationsFormSaga(action) {
    var csrftoken, viewing_username, callAPI, response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                csrftoken = Cookies.get("csrftoken");
                viewing_username = store.getState().rootReducer.dashboardReducer.viewing_username;
                return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit_adviser_questionnaire/adviser_client_obligations/?jobseeker=".concat(viewing_username), { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_6 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Adviser Adviser Obligations Generator function
 * This function is responsible for handling Adviser Adviser Obligations service calls.
 * @param props
 */
function AdviserAdviserObligationsSaga() {
    var callAPI, response, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/adviser_adviser_obligations/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, "To what extent have you made the following commitment or obligations to your jobseeker?");
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_ADVISER_OBLIGATIONS_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_7 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AdviserAdviserObligationsFormSaga(action) {
    var viewing_username, callAPI, response, error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                viewing_username = store.getState().rootReducer.dashboardReducer.viewing_username;
                return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit_adviser_questionnaire/adviser_adviser_obligations/?jobseeker=".concat(viewing_username), { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_8 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Adviser Global Generator function
 * This function is responsible for handling Adviser Global service calls.
 * @param props
 */
function AdviserGlobalSaga() {
    var callAPI, label_4, response, error_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                label_4 = "For advisers and jobseekers to work well together, it's important that they both understand what they need to do and what they expect from each other.";
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/adviser_global/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, label_4);
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_GLOBAL_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_GLOBAL_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_9 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_GLOBAL_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AdviserGlobalFormSaga(action) {
    var viewing_username, callAPI, response, error_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                viewing_username = store.getState().rootReducer.dashboardReducer.viewing_username;
                return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit_adviser_questionnaire/adviser_global/?jobseeker=".concat(viewing_username), { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_GLOBAL_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_GLOBAL_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_10 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_GLOBAL_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Adviser Jobseeker Outcome Generator function
 * This function is responsible for handling Adviser Jobseeker Outcome service calls.
 * @param props
 */
function AdviserJobseekerOutcomeSaga() {
    var callAPI, label, response, error_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                label = "We would now like to know the outcome of your cooperation with this jobseeker.";
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/jobseeker_outcome/")
                            .then(function (resp) {
                            return resp.data;
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_JOBSEEKER_OUTCOME_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_11 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AdviserJobseekerOutcomeFormSaga(action) {
    var viewing_username, callAPI, response, error_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                viewing_username = store.getState().rootReducer.dashboardReducer.viewing_username;
                return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit_adviser_questionnaire/jobseeker_outcome/?jobseeker=".concat(viewing_username), { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_12 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function getMatrixRadioSelectArray(resp, label) {
    // Filter out objects having "field_type" equal to "MatrixRadioSelect"
    var choices = [];
    var matrixRadioSelectObjects = resp.data.filter(function (obj) {
        var fieldType = obj[Object.keys(obj)[0]].field_type;
        choices = obj[Object.keys(obj)[0]].choices;
        return fieldType === "MatrixRadioSelect";
    });
    // Create a new array with the filtered objects
    var newArray = __spreadArray([], matrixRadioSelectObjects, true);
    // Append the new array to the original one
    var modifiedData = __spreadArray(__spreadArray([], resp.data.filter(function (obj) { return obj[Object.keys(obj)[0]].field_type !== "MatrixRadioSelect"; }), true), [
        {
            matrix_Radio_Select_Array: {
                label: label,
                field_type: "MatrixRadioSelect",
                choices: choices,
                data: newArray,
            },
        },
    ], false);
    return modifiedData;
}
function AdviserQuestionnaireSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_DEMOGRAPHICS, AdviserDemographicsSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_DEMOGRAPHICS_FORM, AdviserDemographicsFormSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_PROGRESSION, AdviserProgressionSaga)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_PROGRESSION_FORM, AdviserProgressionFormSaga)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_CLIENT_OBLIGATIONS, AdviserClientObligationsSaga)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FORM, AdviserClientObligationsFormSaga)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_ADVISER_OBLIGATIONS, AdviserAdviserObligationsSaga)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FORM, AdviserAdviserObligationsFormSaga)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_GLOBAL, AdviserGlobalSaga)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_GLOBAL_FORM, AdviserGlobalFormSaga)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_JOBSEEKER_OUTCOME, AdviserJobseekerOutcomeSaga)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FORM, AdviserJobseekerOutcomeFormSaga)];
            case 12:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default AdviserQuestionnaireSaga;
