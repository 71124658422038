var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);
Chart.register.apply(Chart, registerables);
import "./stackedBar.css";
import { Status } from "../../../redux/constants/status";
import GraphShimmer from "./GraphShimmer";
var PieChart = function (props) {
    if (props.status === Status.Successful && props.values) {
        var dataFormat = {
            labels: ["Your Score", "Remaining Score"],
            datasets: [{
                    data: [props.values[0], 146 - props.values[0]],
                    backgroundColor: ["#6c63ff", "#FFA96F"],
                }]
        };
        return (_jsx("div", __assign({ className: "canvas-container" }, { children: _jsx(Doughnut, { data: dataFormat, options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        datalabels: {
                            display: false
                        },
                        legend: {
                            display: true,
                            reverse: true
                        },
                        title: {
                            display: true,
                            text: 'Average Caseload Employability Score',
                            align: 'start',
                            font: {
                                family: "Manrope",
                                size: 18,
                                weight: 'normal'
                            },
                            color: '#1E1E1E',
                            padding: {
                                bottom: 30
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    var label = context.dataset.label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    if (context.parsed !== null) {
                                        label += context.parsed;
                                    }
                                    return label;
                                }
                            }
                        }
                    },
                } }) })));
    }
    return _jsx(GraphShimmer, {});
};
export default PieChart;
