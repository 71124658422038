var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import QuestionnaireOrg from "../../../organisms/jsQuestionnaire/jsQuestionnaire";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClientObligationsAction, ClientObligationsFormAction, } from "../../../../redux/actions/job_seeker_questionnaire";
/**
 * The Client Obligations Questionnaire Page
 * This will need to call all relevant APIs to get the values.
 * @param props
 * @returns
 */
function ClientObligationsQuestionnaire() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _a = useState(""), error = _a[0], setError = _a[1];
    useEffect(function () {
        dispatch(ClientObligationsAction());
    }, [dispatch]);
    //using selector hook to fetch data from the reducer
    var _b = useSelector(function (state) { return state.rootReducer.JobSeekerQuestionnaireReducer; }), clientObligationsData = _b.clientObligationsData, error_clientObligations = _b.error_clientObligations, loading = _b.loading, clientObligationsFormData = _b.clientObligationsFormData, success_clientObligations_form = _b.success_clientObligations_form;
    var loginData = useSelector(function (state) { return state.rootReducer.userReducer; }).loginData;
    //function to submit the input user data
    var handleFormSubmit = function (formData) {
        var sanitizedData = Object.keys(formData).reduce(function (acc, key) {
            acc[key] = DOMPurify.sanitize(formData[key]);
            return acc;
        }, {});
        dispatch(ClientObligationsFormAction(sanitizedData, loginData));
    };
    var handleBackBtn = function () {
        navigate("/questionnaire/global");
    };
    if (success_clientObligations_form) {
        navigate("/questionnaire/adviserObligations");
    }
    return (_jsxs("div", __assign({ className: "" }, { children: [error && _jsx("div", __assign({ className: "error-message" }, { children: error })), _jsx(QuestionnaireOrg, { title: "Your Obligations", content: "As a participant on this programme, you will be interacting with your adviser. In order to ensure that you get the best from the relationship with your adviser, we want to understand what you feel you have committed to do, but also what they have committed to do, to support to towards employment. Please respond truthfully and trust your initial reactions to the following statements.", data: clientObligationsData, responses: clientObligationsFormData, handleSubmit: handleFormSubmit, handleBackBtn: handleBackBtn, classname: "clientObligations-w", width: "70%" })] })));
}
export default ClientObligationsQuestionnaire;
