// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-title {
  color: #646464;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem; /* 166.667% */
}

.text-subtitle {
  color: #A1A1A1;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem;
}

.text-text {
  color: rgba(30, 30, 30, 0.90);
  /* font-size: 0.9375rem; */
  font-style: normal;
  font-weight: 400;
  line-height: 1.6875rem; /* 180% */
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/text.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB,EAAE,aAAa;AACtC;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB,EAAE,SAAS;AACnC","sourcesContent":[".text-title {\n  color: #646464;\n  font-size: 1.125rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 1.875rem; /* 166.667% */\n}\n\n.text-subtitle {\n  color: #A1A1A1;\n  font-size: 0.875rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 1.875rem;\n}\n\n.text-text {\n  color: rgba(30, 30, 30, 0.90);\n  /* font-size: 0.9375rem; */\n  font-style: normal;\n  font-weight: 400;\n  line-height: 1.6875rem; /* 180% */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
