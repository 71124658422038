var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Input from "../../atoms/input/input";
import JSQuestionnaireFooter from "../../molecules/jsQuestionnaireFooter/jsQuestionnaireFooter";
import "./jsQuestionnaire.css";
import MatrixRadioSelect from "../../molecules/matrixRadioSelect/matrixRadioSelect";
import { validateFormData } from "../../pages/questionnaire/questionnaires/validationUtils";
import ErrorMessage from "../../atoms/errorMessage/errorMessage";
import JSQuestionnaireHeader from "../../molecules/jsQuestionnaireHeader/jsquestionnaireHeader";
/**
 * The Questionnaire Page
 * This will need to call all relevant APIs to get the values.
 * This Page fetches the list of questions for each Questionnaire type.
 * The Questions in the questionnaire could be of 5 types:
 * => RadioSelect, CheckboxSelectMultiple, MatrixRadioSelect, TextInput, and Select.
 * The conditions, classes and response handling have been added for each type and their final reponses will be returned.
 * @param props
 * @returns
 */
function QuestionnaireOrg(props) {
    var data = props.data;
    var handleSubmit = props.handleSubmit, handleBackBtn = props.handleBackBtn;
    var responseData = props.responses;
    //create an object to store user responses for the given questions
    var emptyformData = {};
    data.forEach(function (obj, val) {
        var key = Object.keys(obj)[0];
        if (key == "matrix_Radio_Select_Array") {
            obj[key].data.forEach(function (el) {
                var key1 = Object.keys(el)[0];
                emptyformData[key1] = responseData[key1] ? responseData[key1] : "";
            });
        }
        else {
            if (obj[Object.keys(obj)[0]].field_type === "CheckboxSelectMultiple") {
                emptyformData[key] = responseData[key]
                    ? responseData[key].split(",")
                    : []; // initialize the values of array type
            }
            else {
                emptyformData[key] = responseData[key] ? responseData[key] : ""; // initialize the values of string type
            }
        }
    });
    var _a = useState(emptyformData), formData = _a[0], setFormData = _a[1];
    var _b = useState(emptyformData), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = useState(""), submitError = _c[0], setSubmitError = _c[1];
    useEffect(function () {
        setFormData(emptyformData);
    }, [data]);
    //function to change the values in the input fields entered by the user
    var handleChange = function (e) {
        var _a, _b, _c;
        var _d = e.target, name = _d.name, value = _d.value, type = _d.type;
        switch (type) {
            case "radio":
                setFormData(__assign(__assign({}, formData), (_a = {}, _a[name] = value, _a)));
                break;
            case "checkbox":
                var currVal = formData[name];
                var isValuePresent = currVal.includes(value);
                var updatedVal = void 0;
                if (isValuePresent) {
                    // If the value is present, remove it
                    updatedVal = currVal.filter(function (entity) { return entity !== value; });
                }
                else {
                    // If the value is not present, add it
                    updatedVal = __spreadArray(__spreadArray([], currVal, true), [value], false);
                }
                setFormData(__assign(__assign({}, formData), (_b = {}, _b[name] = updatedVal, _b)));
                break;
            case "text":
                setFormData(__assign(__assign({}, formData), (_c = {}, _c[name] = value, _c)));
                break;
        }
    };
    //function to select the values from the options selected by the user
    var handleSelect = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormData(__assign(__assign({}, formData), (_a = {}, _a[name] = value === "Select One" ? "" : value, _a)));
    };
    //function to appens a specific classes for radio buttons and checkboxes
    var handleInputType = function (type) {
        if (type == "RadioSelect" || type == "MatrixRadioSelect") {
            return "radio";
        }
        else if (type == "CheckboxSelectMultiple") {
            return "checkbox";
        }
    };
    //function to handle the checked properties for radio buttons and checkboxes
    var handleResponse = function (name, value, type) {
        var _a;
        var ans = false;
        if (type == "RadioSelect" || type == "MatrixRadioSelect") {
            formData[name] === value ? (ans = true) : (ans = false);
        }
        else if (type == "CheckboxSelectMultiple") {
            ((_a = formData[name]) === null || _a === void 0 ? void 0 : _a.includes(value)) ? (ans = true) : (ans = false);
        }
        return ans;
    };
    // Call the onSubmit function passed via props
    var handleFormSubmit = function () {
        var _a;
        var validation = validateFormData(formData, data);
        if (!(validation === null || validation === void 0 ? void 0 : validation.isValid)) {
            var errorId = validation === null || validation === void 0 ? void 0 : validation.errorField;
            setErrorMessage(__assign(__assign({}, errorMessage), (_a = {}, _a[errorId] = validation === null || validation === void 0 ? void 0 : validation.errorMessage, _a)));
            if (validation === null || validation === void 0 ? void 0 : validation.errorMessage) {
                setSubmitError("Please answer all questions to proceed*");
            }
        }
        else {
            handleSubmit === null || handleSubmit === void 0 ? void 0 : handleSubmit(formData);
        }
    };
    // Call the onBack function to navigate to previous page
    var handleFormBackBtn = function () {
        handleBackBtn === null || handleBackBtn === void 0 ? void 0 : handleBackBtn();
    };
    return (_jsxs("div", __assign({ className: "questionnaire" }, { children: [_jsx(JSQuestionnaireHeader, { title: props.title, content: props.content, classname: props.classname ? props.classname : "", width: props.width ? props.width : "" }), _jsx("div", __assign({ className: "questionnaire-content" }, { children: data.length === 0 ? (_jsx("div", { children: "No data available." })) : (_jsx("div", { children: data.map(function (item, index) { return (_jsxs("div", { children: [item[Object.keys(item)[0]].field_type ==
                                "MatrixRadioSelect" ? (_jsx(MatrixRadioSelect, { data: item[Object.keys(item)[0]], handleChange: handleChange, handleResponse: handleResponse, formData: formData, errorMessage: errorMessage })) : (_jsxs("div", __assign({ className: "question-answer" }, { children: [_jsx("p", __assign({ className: "font-medium not-italic set-line-height question" }, { children: item[Object.keys(item)[0]].label })), item[Object.keys(item)[0]].field_type == "RadioSelect" ||
                                        item[Object.keys(item)[0]].field_type ==
                                            "CheckboxSelectMultiple" ? (_jsx("div", __assign({ className: "\n                          ".concat(item[Object.keys(item)[0]].field_type ==
                                            "RadioSelect"
                                            ? "flex"
                                            : "") }, { children: item[Object.keys(item)[0]].choices.map(function (choice, index) { return (_jsxs("p", __assign({ className: "choices choices-size flex font-medium not-italic set-line-height" }, { children: [_jsx(Input, { type: handleInputType(item[Object.keys(item)[0]].field_type), className: "select-input", name: Object.keys(item)[0], value: choice, onChange: handleChange, onInputChange: function () { }, isChecked: handleResponse(Object.keys(item)[0], choice, item[Object.keys(item)[0]].field_type) }), _jsx("label", __assign({ className: "select-label" }, { children: choice }))] }), index)); }) }))) : (""), item[Object.keys(item)[0]].field_type == "TextInput" ? (_jsx(Input, { type: "text", id: "", name: Object.keys(item)[0], placeholder: "", value: formData[Object.keys(item)[0]], onChange: handleChange, className: "choices choices-size input-size", onInputChange: function () { } })) : (""), item[Object.keys(item)[0]].field_type == "Select" ? (_jsxs("select", __assign({ className: "dropdown choices-size", name: Object.keys(item)[0], value: formData[Object.keys(item)[0]], onChange: handleSelect }, { children: [_jsx("option", __assign({ value: undefined }, { children: "Select One" })), item[Object.keys(item)[0]].choices.map(function (choice, index) { return (_jsx("option", __assign({ value: choice }, { children: choice }), index)); })] }))) : ("")] }))), !formData[Object.keys(item)[0]] &&
                                errorMessage[Object.keys(item)[0]] ? (_jsx(ErrorMessage, { message: errorMessage[Object.keys(item)[0]] }, Object.keys(item)[0])) : ("")] }, index)); }) })) })), _jsx(JSQuestionnaireFooter, { onSubmit: handleFormSubmit, onBack: handleFormBackBtn }), _jsx("div", __assign({ className: "my-4" }, { children: _jsx(ErrorMessage, { message: submitError }, "") }))] })));
}
export default QuestionnaireOrg;
