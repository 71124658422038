// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-template-left {
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: auto;
  height: 100%;
  width: 22.9375rem;
  background-color: var(--background-color);
  padding: 2.56rem 2.81rem;
}

.page-brand-header {
  margin-bottom: 5.81rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/navBar/navBar.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,eAAe;EACf,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,yCAAyC;EACzC,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["@import \"../../styles/global.css\";\n\n.page-template-left {\n  margin: 0;\n  padding: 0;\n  position: fixed;\n  overflow: auto;\n  height: 100%;\n  width: 22.9375rem;\n  background-color: var(--background-color);\n  padding: 2.56rem 2.81rem;\n}\n\n.page-brand-header {\n  margin-bottom: 5.81rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
