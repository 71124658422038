var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Bar from "../../molecules/bar/bar";
import threeDots from "../../../assets/icons/three-dots.png";
import { useNavigate } from "react-router";
import "./pageHeader.css";
import Button from "../../atoms/button/button";
import { ButtonType } from "../../atoms/button/buttonType";
import Card from "../../atoms/card/card";
import { ArrowType } from "../../atoms/card/arrowType";
import { useDispatch } from "react-redux";
import { LogoutAction } from "../../../redux/actions/authentication";
import showPopupModal from "../../../redux/actions/popupModal";
import Confirm from "../../atoms/confirm/confirm";
import user from "../../../assets/user.png";
var PageHeader = function (props) {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _a = useState(false), showLogout = _a[0], setShowLogout = _a[1];
    var logoutBody = (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "mb-7" }, { children: "Log out" })), _jsx("p", { children: "Are you sure you want to Log Out? Confirming will end your current session. Remember to save any unsaved work before proceeding." }), _jsx(Confirm, { reverse: true, className: "mt-14", onConfirm: function () {
                    dispatch(LogoutAction());
                    dispatch(showPopupModal(false));
                    navigate("/login");
                }, onCancel: function () {
                    dispatch(showPopupModal(false));
                } })] }));
    useEffect(function () {
        document.addEventListener("click", handleClick);
        return function () {
            document.removeEventListener("click", handleClick);
        };
    });
    function handleClick(e) {
        var clickedElement = e.target;
        if (clickedElement instanceof Element &&
            clickedElement.id !== "threedots-dropdown-button") {
            setShowLogout(false);
        }
    }
    return (_jsxs("div", { children: [_jsxs(Bar, __assign({ title: props.title }, { children: [props.backButton && (_jsx(Button, __assign({ className: "px-4 py-4 w-min mx-6", buttonType: ButtonType.Filled, onClick: props.onClick
                            ? props.onClick
                            : function () {
                                navigate("/" + props.backTo);
                            } }, { children: _jsx(Card, __assign({ arrowType: ArrowType.Left, transarent: true, className: "p-0 m-0" }, { children: _jsx("p", __assign({ className: "text-lg" }, { children: props.backButton })) })) }))), _jsx(Button, __assign({ className: "mx-3 profile-img", onClick: function () {
                            navigate("/profile");
                        }, buttonType: ButtonType.Tertiary }, { children: _jsx("img", { src: user }) })), _jsx("button", __assign({ id: "threedots-dropdown-button", className: "dropdown-btn", onClick: function () {
                            setShowLogout(!showLogout);
                        } }, { children: _jsx("img", { src: threeDots }) })), _jsx("div", __assign({ className: "dropdown-content ".concat(!showLogout && "hidden") }, { children: _jsx(Button, __assign({ onClick: function () {
                                dispatch(showPopupModal(true, logoutBody));
                            }, className: "profile-img", buttonType: ButtonType.Tertiary }, { children: "Logout" })) }))] })), _jsx("div", { className: "hr" })] }));
};
export default PageHeader;
