import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import rootReducer from "./reducers/rootReducer";
import RootSaga from './saga/index';
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import popupReducer from "./reducers/popupModal";
import AuthenticationService from "./services/authenticationService";
var persistConfig = {
    key: "root",
    version: 1,
    storage: storage,
    blacklist: ['popupReducer'],
};
var reducer = combineReducers({
    rootReducer: rootReducer,
    popupReducer: popupReducer
});
var persistedReducer = persistReducer(persistConfig, reducer);
var authService = new AuthenticationService();
// Avoid 'this' binding + avoid "this is undefined" error
var sagaMiddleware = createSagaMiddleware({
    context: {
        authService: {
            callAPI: function (apiType, endpoint, data, noRedirect) { return authService.callAPI(apiType, endpoint, data, noRedirect); },
            setCredentials: function (token, refresh) { return authService.setCredentials(token, refresh); }
        }
    }
});
var store = configureStore({
    reducer: persistedReducer,
    middleware: function () { return [sagaMiddleware]; }
});
sagaMiddleware.run(RootSaga);
export default store;
