// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shimmer {
  height: 100%;
  /* width: 100%; */
  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
  background-size: 200% 100%;
  animation: shimmerAnimation 1.5s infinite linear;
}

@keyframes shimmerAnimation {
  to {
    background-position: -200% 0;
  }
}


`, "",{"version":3,"sources":["webpack://./src/components/atoms/shimmer/shimmer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,oFAAoF;EACpF,0BAA0B;EAC1B,gDAAgD;AAClD;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".shimmer {\n  height: 100%;\n  /* width: 100%; */\n  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);\n  background-size: 200% 100%;\n  animation: shimmerAnimation 1.5s infinite linear;\n}\n\n@keyframes shimmerAnimation {\n  to {\n    background-position: -200% 0;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
