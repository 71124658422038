var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PopupModal } from "../constants/popupModal";
var initialState = {
    popupState: PopupModal.Hide,
};
var popupReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case PopupModal.Show:
            return __assign(__assign({}, state), { children: action.payload });
        case PopupModal.Hide:
            return __assign(__assign({}, state), { children: undefined });
        default:
            return state;
    }
};
export default popupReducer;
