var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import CircularButton from "../../atoms/button/circularButton";
import NavBar from "../../organisms/navBar/navBar";
import "../../templates/general_page_template.css";
import Card from "../../atoms/card/card";
import { useDispatch, useSelector } from "react-redux";
import { AdviserInfoAction } from "../../../redux/actions/dashboard";
import "../../styles/text.css";
import "./adviserInfo.css";
import PageHeader from "../../organisms/pageHeader/pageHeader";
export default function AdviserInfo() {
    var dispatch = useDispatch();
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var viewing_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_user_type; });
    var viewing_username = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_username; });
    useEffect(function () {
        dispatch(AdviserInfoAction());
    }, [dispatch]);
    var adviserInfo = useSelector(function (state) { return state.rootReducer.dashboardReducer; }).adviserInfo;
    var page_title = "Adviser Info";
    if (logged_in_user_type == "adviser") {
        page_title = "Manager Info";
    }
    else if (logged_in_user_type == "manager") {
        page_title = "Organisation Info";
    }
    return (_jsxs("div", __assign({ className: "h-full" }, { children: [_jsx(NavBar, { viewing_user: viewing_user_type, logged_in_user: logged_in_user_type, viewing_username: viewing_username }), _jsxs("div", __assign({ className: "page-template-right" }, { children: [_jsx(PageHeader, { title: page_title }), _jsx(Card, __assign({ multiLine: true, className: "adviser-card" }, { children: _jsxs("div", { children: [_jsxs("p", __assign({ className: "font-medium not-italic set-line-height question" }, { children: ["Your ", adviserInfo === null || adviserInfo === void 0 ? void 0 : adviserInfo.user_type, " Details"] })), _jsx(CircularButton, __assign({ className: "mx-3 circular-size", onClick: function () { } }, { children: "J" })), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "adviser-content" }, { children: [_jsx("p", __assign({ className: "font-medium not-italic set-line-height adviser-field" }, { children: "Username" })), _jsx("p", __assign({ className: "font-medium not-italic set-line-height adviser-field-content" }, { children: adviserInfo === null || adviserInfo === void 0 ? void 0 : adviserInfo.username }))] })), _jsxs("div", __assign({ className: "adviser-content" }, { children: [_jsx("p", __assign({ className: "font-medium not-italic set-line-height adviser-field" }, { children: "Org Contact" })), _jsx("p", __assign({ className: "font-medium not-italic set-line-height adviser-field-content" }, { children: adviserInfo === null || adviserInfo === void 0 ? void 0 : adviserInfo.email }))] })), _jsxs("div", __assign({ className: "adviser-content" }, { children: [_jsx("p", __assign({ className: "font-medium not-italic set-line-height adviser-field" }, { children: "Org Address" })), _jsx("p", __assign({ className: "font-medium not-italic set-line-height adviser-field-content" }, { children: adviserInfo === null || adviserInfo === void 0 ? void 0 : adviserInfo.address }))] }))] }))] }) }))] }))] })));
}
