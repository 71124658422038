var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Profile } from "../constants/profile";
import { Status } from "../constants/status";
var initialState = {
    status: Status.Loading
};
var profileReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case Profile.Profile:
            return __assign(__assign({}, state), { status: Status.Loading });
        // Wrap with scope to fix eslint errors
        case Profile.Profile_Successful: {
            var userInformation = action.payload[0];
            return __assign(__assign({}, state), { username: userInformation["username"], age: userInformation["age"], address: userInformation["address"], ethnicity: userInformation["ethnicity"], gender: userInformation["gender"], id: userInformation["user"], email: userInformation["email"], status: Status.Successful });
        }
        case Profile.Profile_Empty:
            return __assign(__assign({}, state), { username: "", age: "", address: "", ethnicity: "", gender: "", id: "", email: "", status: Status.Successful });
        case Profile.Profile_Error:
            return __assign(__assign({}, state), { status: Status.Error });
        case Profile.Update_Profile:
            return __assign(__assign({}, state), { status: Status.Loading });
        // Wrap with scope to fix eslint errors
        case Profile.Update_Profile_Successful: {
            return __assign(__assign({}, state), { age: action.payload.age, address: action.payload.address, gender: action.payload.gender, status: Status.Successful });
        }
        case Profile.Update_Profile_Error:
            return __assign(__assign({}, state), { status: Status.Error });
        default:
            return state;
    }
};
export default profileReducer;
