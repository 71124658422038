var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Button from "../../atoms/button/button";
import { ButtonType } from "../../atoms/button/buttonType";
import MatrixRadioSelect from "../matrixRadioSelect/matrixRadioSelect";
import "./tabs.css";
/**
 * The Tabs Component
 * This component embed the tab switching functionality.
 * Multiple tabs could be added and on selecting a particular tab data under that tab will be visible.
 */
function Tabs(props) {
    var _a = useState(props.tabs[0]), activeTab = _a[0], setActiveTab = _a[1];
    var handleTabChange = function (tab) {
        setActiveTab(tab);
    };
    return (_jsxs("div", __assign({ className: "tabs-overview" }, { children: [_jsx("div", __assign({ className: "flex" }, { children: props.tabs.map(function (tab, index) { return (_jsx(Button, { onClick: function () {
                        handleTabChange(tab);
                    }, text: tab, buttonType: ButtonType.Tertiary, className: activeTab === tab ? "active" : "" }, index)); }) })), _jsx("div", { className: "tabs-hr" }), props.data.map(function (obj, index) { return (_jsx("div", __assign({ id: obj[Object.keys(obj)[0]], style: {
                    display: activeTab === "".concat(obj[Object.keys(obj)[0]]) ? "block" : "none",
                } }, { children: _jsx(MatrixRadioSelect, { data: obj[Object.keys(obj)[1]], type: props.type, classname: props.classname }) }), index)); })] })));
}
export default Tabs;
