// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr-size {
  height: 1px;
  background: #777;
  margin: 68px 0 55px 0;
}

.continue-btn {
  width: 291px;
  height: 77px;
  box-shadow: 3px 12px 12px 0px rgba(0, 0, 0, 0.1);
}

.continue-btn p {
  font-size: 1.2rem;
}

.back-btn {
  width: 174px;
  height: 77px;
  border: 1px solid #c5c5c5;
  box-shadow: 3px 12px 12px 0px rgba(0, 0, 0, 0.1);
  margin-right: 35px;
}

.back-btn p {
  font-size: 1.2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/jsQuestionnaireFooter/jsQuestionnaireFooter.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gDAAgD;AAClD;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,gDAAgD;EAChD,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["@import \"../../styles/global.css\";\n\n.hr-size {\n  height: 1px;\n  background: #777;\n  margin: 68px 0 55px 0;\n}\n\n.continue-btn {\n  width: 291px;\n  height: 77px;\n  box-shadow: 3px 12px 12px 0px rgba(0, 0, 0, 0.1);\n}\n\n.continue-btn p {\n  font-size: 1.2rem;\n}\n\n.back-btn {\n  width: 174px;\n  height: 77px;\n  border: 1px solid #c5c5c5;\n  box-shadow: 3px 12px 12px 0px rgba(0, 0, 0, 0.1);\n  margin-right: 35px;\n}\n\n.back-btn p {\n  font-size: 1.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
