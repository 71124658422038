var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../atoms/button/button";
import LeftCoverImage from "../atoms/leftCoverImage/leftCoverImage";
import InputField from "../molecules/inputField/inputField";
import LoginTemplate from "../templates/loginTemplate";
import BrandHeader from "../molecules/brandHeader/brandHeader";
import { ResetAction } from "../../redux/actions/authentication";
import "../styles/main.css";
import "../styles/login.css";
/**
 * The Password Reset Email Page
 * This will need to call all relevant APIs to get the values.
 * This page take the email id to send the reset token for password reset.
 * @param props
 * @returns
 */
function PasswordResetEmail() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    //using selector hook to fetch data from the reducer
    var userData = useSelector(function (state) { return state.rootReducer.userReducer; });
    //creating react state hook to store email locally
    var _a = useState({
        email: "",
    }), resetData = _a[0], setResetData = _a[1];
    //function to submit the input user data
    var handleSubmit = function (e) {
        e.preventDefault();
        dispatch(ResetAction(resetData));
    };
    //function to change the values in the input fields entered by the user
    var handleChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setResetData(__assign(__assign({}, resetData), (_a = {}, _a[name] = value, _a)));
    };
    //navigation to password reset confirmation page on successful email input
    if (userData.resetData) {
        navigate("/passwordReset");
    }
    return (_jsx(LoginTemplate, { leftImage: _jsx(LeftCoverImage, { second: true }), form: _jsxs("form", __assign({ onSubmit: handleSubmit, className: "form m-3" }, { children: [_jsx(BrandHeader, {}), _jsx("h1", __assign({ className: "heading mt-5" }, { children: "Password Recovery" })), _jsx("div", __assign({ className: "h3 mb-3 mt-1 font-medium text grey" }, { children: "Please enter your email address associated with your account and we\u2019ll send you a link to reset your password." })), _jsx(InputField, { label: "Email", type: "text", id: "id_email", name: "email", placeholder: "email", value: resetData.email, onChange: handleChange }), _jsx("div", { className: "float-right" }), _jsx("div", __assign({ className: "pt-6" }, { children: _jsx(Button, { border: "none", onClick: function () { return handleSubmit; }, text: "Continue", className: "mt-6" }) }))] })) }));
}
export default PasswordResetEmail;
