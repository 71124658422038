// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.obligations-page-template-right {
  width: calc(100% - 22.9375rem);
  height: 100%;
  margin-left: 22.9375rem;
  background-color: var(--background-color-darker);
  padding: 1.5rem 2.62rem;
  min-height: 100vh;
}

.obligations-hr {
  background: var(--grey);
  width: 100%;
  height: 0.0625rem;
  margin: 2.12rem 0rem;
}

.obligations {
  height: 100%;
}

.obligations-content {
  border-radius: 1.875rem;
  background: #fff;
}

.obligations-ques-ans {
  margin-top: 2.19rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/obligations/obligations.css"],"names":[],"mappings":"AAEA;EACE,8BAA8B;EAC9B,YAAY;EACZ,uBAAuB;EACvB,gDAAgD;EAChD,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["@import \"../../styles/global.css\";\n\n.obligations-page-template-right {\n  width: calc(100% - 22.9375rem);\n  height: 100%;\n  margin-left: 22.9375rem;\n  background-color: var(--background-color-darker);\n  padding: 1.5rem 2.62rem;\n  min-height: 100vh;\n}\n\n.obligations-hr {\n  background: var(--grey);\n  width: 100%;\n  height: 0.0625rem;\n  margin: 2.12rem 0rem;\n}\n\n.obligations {\n  height: 100%;\n}\n\n.obligations-content {\n  border-radius: 1.875rem;\n  background: #fff;\n}\n\n.obligations-ques-ans {\n  margin-top: 2.19rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
