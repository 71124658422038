var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import "./questionnaireResponses";
import NavBar from "../../organisms/navBar/navBar";
import PageHeader from "../../organisms/pageHeader/pageHeader";
import TableCard from "../../molecules/table/table";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionnaireHistory } from "../../../redux/actions/questionnaireHistoryActions";
import { makeQues2Ready } from "../../../redux/actions/dashboard";
var headers = ["Attempts", "Date", ""]; // Add an empty string for the third column
/**
 * The Obligations Page
 * This will need to call all relevant APIs to get the values.
 */
function Questionnaire() {
    var dispatch = useDispatch();
    var viewing_username = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_username; });
    useEffect(function () {
        if (viewing_username) {
            // Dispatch the action when the component mounts
            dispatch(getQuestionnaireHistory(viewing_username));
        }
    }, [dispatch, viewing_username]);
    var numberOfAttempts = useSelector(function (state) {
        return state.rootReducer.questionnaireHistoryReducer.numberOfAttempts;
    });
    var dates = [];
    dates = useSelector(function (state) { return state.rootReducer.questionnaireHistoryReducer.completedDates; });
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var viewing_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_user_type; });
    var quesAttemptDetails = useSelector(function (state) { return state.rootReducer.dashboardReducer.quesAttemptDetails; });
    // const [second_attempt, setSecondAttempt] = React.useState(
    //   quesAttemptDetails.second_attempt_ready
    // );
    useEffect(function () {
        // setSecondAttempt(quesAttemptDetails.second_attempt_ready);
    }, [quesAttemptDetails]);
    if (!numberOfAttempts || !dates) {
        // If numberOfAttempts or dates are falsy, return null or a loading indicator
        return null; // or return <LoadingIndicator /> or any other suitable component
    }
    if (logged_in_user_type === "adviser" &&
        numberOfAttempts === 1 &&
        dates.length === 1) {
        dates.push(null);
    }
    var ques_attempts = {
        "Questionnaire 1": quesAttemptDetails.completed_once,
        "Questionnaire 2": quesAttemptDetails.completed_twice,
    };
    var adviser_ques_attempts = {
        "Questionnaire 1": quesAttemptDetails.adv_questionnaire_one_complete,
        "Questionnaire 2": quesAttemptDetails.adv_questionnaire_two_complete &&
            quesAttemptDetails.second_attempt_ready,
    };
    var ques_attempts_enable = {
        "Questionnaire 1": quesAttemptDetails.adv_questionnaire_one_complete,
        "Questionnaire 2": quesAttemptDetails.second_attempt_ready,
    };
    var adviser_ques_attempts_enable = {
        "Questionnaire 1": !quesAttemptDetails.adv_questionnaire_one_complete,
        "Questionnaire 2": quesAttemptDetails.adv_questionnaire_one_complete &&
            quesAttemptDetails.second_attempt_ready,
    };
    var handleToggleBtn = function (toggle) {
        dispatch(makeQues2Ready(quesAttemptDetails, toggle));
    };
    var data = [];
    for (var i = 0; i < dates.length; i++) {
        var currentQuestionnaire = "Questionnaire ".concat(i + 1);
        var innerData = [currentQuestionnaire];
        if (logged_in_user_type === "adviser") {
            if (!ques_attempts[currentQuestionnaire]) {
                if (i == 1) {
                    innerData.push({
                        text: ques_attempts_enable[currentQuestionnaire]
                            ? "Disable Questionnaire 2"
                            : "Enable Questionnaire 2",
                        type: "toggle",
                        classname: "set-btn=color",
                    });
                }
            }
            else {
                innerData.push(dates[i]);
            }
            if (adviser_ques_attempts[currentQuestionnaire]) {
                innerData.push("Adviser Questionnaire ".concat(i + 1, " Taken"));
                innerData.push({
                    link: "/questionnaireResponses/".concat(i + 1),
                    text: "View Results >",
                });
            }
            else {
                if (adviser_ques_attempts_enable[currentQuestionnaire]) {
                    innerData.push({
                        link: "/questionnaire/adviserProgression",
                        text: "Take Adviser Questionnaire ".concat(i + 1),
                        type: "button",
                    });
                }
            }
        }
        else {
            innerData.push(dates[i]);
            innerData.push({
                link: "/questionnaireResponses/".concat(i + 1),
                text: "View Results >",
            });
        }
        data.push(innerData);
    }
    // const data = dates.map((element: string, index: number) => [
    //   `Questionnaire ${index + 1}`,
    //   logged_in_user_type === "adviser" &&
    //     ques_attempts[`Questionnaire ${index + 1}`]
    //     ? element
    //     : logged_in_user_type === "adviser" ? {
    //       // handleClick: handleToggleBtn(!quesAttemptDetails.second_attempt_ready),
    //       text: ques_attempts_enable[`Questionnaire ${index + 1}`]
    //         ? "Disable Questionnaire 2"
    //         : "Enable Questionnaire 2",
    //       type: "toggle",
    //       classname: "set-btn-color",
    //     } : "",
    //   logged_in_user_type === "adviser" &&
    //     adviser_ques_attempts[`Questionnaire ${index + 1}`]
    //     ? `Adviser Questionnaire ${index + 1} Taken`
    //     : adviser_ques_attempts_enable[`Questionnaire ${index + 1}`]
    //       ? {
    //         link: "/questionnaire/adviserProgression",
    //         text: `Take Adviser Questionnaire ${index + 1}`,
    //         type: "button",
    //       }
    //       : "",
    //   element
    //     ? { link: `/questionnaireResponses/${index + 1}`, text: "View Results >" }
    //     : "",
    // ]);
    return (_jsxs("div", __assign({ className: "h-full" }, { children: [_jsx(NavBar, { viewing_user: viewing_user_type, logged_in_user: logged_in_user_type, viewing_username: viewing_username }), _jsxs("div", __assign({ className: "questionnaire-page-template-right" }, { children: [_jsx(PageHeader, { title: "Questionnaire History" }), _jsx(TableCard, { headers: headers, data: data, rowsPerPage: 10, handleClick: function () {
                            return handleToggleBtn(!quesAttemptDetails.second_attempt_ready);
                        } })] }))] })));
}
export default Questionnaire;
