// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.matrix-heading-reduced {
    margin: 50px 0 70px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/matrixRadioSelect/matrixRadioSelect.css"],"names":[],"mappings":"AAEA;IACI,qBAAqB;AACzB","sourcesContent":["@import \"../../styles/global.css\";\n\n.matrix-heading-reduced {\n    margin: 50px 0 70px 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
