var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import QuestionnaireOrg from "../../../organisms/jsQuestionnaire/jsQuestionnaire";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SocialAction, SocialFormAction, } from "../../../../redux/actions/job_seeker_questionnaire";
/**
 * The Social Questionnaire Page
 * This will need to call all relevant APIs to get the values.
 * @param props
 * @returns
 */
function SocialQuestionnaire() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _a = useState(''), error = _a[0], setError = _a[1];
    useEffect(function () {
        dispatch(SocialAction());
    }, [dispatch]);
    //using selector hook to fetch data from the reducer
    var _b = useSelector(function (state) { return state.rootReducer.JobSeekerQuestionnaireReducer; }), socialData = _b.socialData, error_social = _b.error_social, loading = _b.loading, socialFormData = _b.socialFormData, success_social_form = _b.success_social_form;
    var loginData = useSelector(function (state) { return state.rootReducer.userReducer; }).loginData;
    //function to submit the input user data
    var handleFormSubmit = function (formData) {
        var sanitizedData = Object.keys(formData).reduce(function (acc, key) {
            acc[key] = DOMPurify.sanitize(formData[key]);
            return acc;
        }, {});
        dispatch(SocialFormAction(sanitizedData, loginData));
    };
    var handleBackBtn = function () {
        navigate("/questionnaire/qualifications");
    };
    if (success_social_form) {
        navigate("/questionnaire/progression");
    }
    return (_jsxs("div", __assign({ className: "" }, { children: [error && _jsx("div", __assign({ className: "error-message" }, { children: error })), _jsx(QuestionnaireOrg, { title: "Personal Circumstances", content: "In this section, please tell us about your personal circumstances and the support you have in regard to looking for and keeping work.", data: socialData, responses: socialFormData, handleSubmit: handleFormSubmit, handleBackBtn: handleBackBtn, classname: "social-w", width: "49%" })] })));
}
export default SocialQuestionnaire;
