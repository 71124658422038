export var gender_options = [
    "Male",
    "Female",
    "Other",
    "Prefer not to say",
];
export var age_options = [
    "18-24",
    "25-50",
    "51+",
    "Prefer not to say",
];
