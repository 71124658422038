import { jsx as _jsx } from "react/jsx-runtime";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import { Status } from "../../../redux/constants/status";
import GraphShimmer from "./GraphShimmer";
Chart.register.apply(Chart, registerables);
function BarChart(props) {
    if (props.status === Status.Successful) {
        var data = {
            labels: props.labels,
            datasets: [{
                    data: props.values,
                    backgroundColor: function (context) {
                        var ctx = context.chart.ctx;
                        var x1 = 90 * Math.sin(5.88176); // angle in radians
                        var y1 = 90 * Math.cos(5.88176); // angle in radians
                        var gradient = ctx.createLinearGradient(x1, y1, 0, 0);
                        gradient.addColorStop(0, "#6c63ff");
                        gradient.addColorStop(1, "#9994FF");
                        return gradient;
                    },
                    borderRadius: 200,
                    borderSkipped: false,
                    barPercentage: 0.4,
                }]
        };
        return _jsx(Bar, { data: data, options: {
                indexAxis: 'y',
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    datalabels: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Average Rating',
                        align: 'start',
                        font: {
                            family: "Manrope",
                            size: 18,
                            weight: 'normal'
                        },
                        color: '#1E1E1E',
                        padding: {
                            bottom: 30
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                var label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.x !== null) {
                                    label += context.parsed.x;
                                }
                                return label;
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            font: {
                                family: "Manrope",
                                size: 15
                            },
                            color: '#1E1E1E'
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                            color: function (context) {
                                if (context.index === 5) {
                                    return '';
                                }
                            }
                        },
                        ticks: {
                            font: {
                                family: "Manrope",
                                size: 14
                            },
                            color: '#777777',
                            crossAlign: "far"
                        }
                    }
                }
            } });
    }
    return _jsx(GraphShimmer, {});
}
export default BarChart;
