var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import Button from "../../atoms/button/button";
import { ButtonType } from "../../atoms/button/buttonType";
import { useDispatch, useSelector } from "react-redux";
import showPopupModal from "../../../redux/actions/popupModal";
import CreateUserForm from "./CreateUserForm";
var CreateUser = function () {
    var dispatch = useDispatch();
    var _a = useState(0), userType = _a[0], setUserType = _a[1];
    var _b = useState(""), creatingUserType = _b[0], setCreatingUserType = _b[1];
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var safe_logged_in_usertype = useSelector(function (state) { return state.rootReducer.userReducer.userType; });
    logged_in_user_type = logged_in_user_type ? logged_in_user_type : safe_logged_in_usertype;
    return (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "mb-7" }, { children: "Create user" })), _jsx("p", { children: "Please choose a user type" }), _jsxs("div", __assign({ className: "pt-6" }, { children: [_jsx(Button, { border: "none", onClick: function () {
                            userType !== 1 ? setUserType(1) : setUserType(0);
                            setCreatingUserType("Jobseeker");
                        }, buttonType: userType === 1 ? ButtonType.FilledBlack : ButtonType.Outlined, text: "Jobseeker", className: "mt-6" }), logged_in_user_type !== "adviser" && (_jsx(Button, { border: "none", onClick: function () {
                            userType !== 2 ? setUserType(2) : setUserType(0);
                            setCreatingUserType("Adviser");
                        }, buttonType: userType === 2 ? ButtonType.FilledBlack : ButtonType.Outlined, text: "Adviser", className: "mt-6" })), logged_in_user_type === "organisation" && (_jsx(Button, { border: "none", onClick: function () {
                            userType !== 3 ? setUserType(3) : setUserType(0);
                            setCreatingUserType("Manager");
                        }, buttonType: userType === 3 ? ButtonType.FilledBlack : ButtonType.Outlined, text: "Manager", className: "mt-6" }))] })), _jsx("div", __assign({ className: "pt-6" }, { children: _jsx(Button, { border: "none", onClick: function () {
                        if (userType !== 0) {
                            setUserType(0);
                            dispatch(showPopupModal(false));
                            dispatch(showPopupModal(true, _jsx(CreateUserForm, { userType: userType, creatingUserType: creatingUserType })));
                        }
                    }, text: "Continue", className: "mt-6" }) }))] }));
};
export default CreateUser;
