// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-item {
  width: 17.3125rem;
  height: 4rem;
  flex-shrink: 0;
  border-radius: 0.9375rem;
  padding: 1.25rem 2.06rem;
  margin: 0rem 0rem 1.19rem 0rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.nav-item:hover {
  background-color: var(--background-color-dark);
}


.nav-item-selected {
  background: rgba(81, 72, 250, 0.05);
  font-weight: 600;
}

.nav-item-transition {
  transition: ease-in-out 100ms;
}


.nav-item-not-selected {
  font-weight: 500;
}

.nav-item-selected > p {
  color: var(--primary);
}

.nav-item-icon {
  /* margin: 1.25rem 0.75rem 1.25rem 2.06rem; */
  margin-right: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
}

.nav-item-disabled {
  color: #D3D3D3;
  opacity: 0.5;
}

/* Should probaby not use this */
.nav-item-disabled:hover {
  background: transparent;

}

/*https://codepen.io/sosuke/pen/Pjoqqp*/
/* THIS HAS TO BE RECOMPUTED IF COLOR CHANGE*/
/* color: #C8C8C8; */
.nav-item-icon-not-selected {
  filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(204deg) brightness(93%) contrast(84%);
}
/* #514BFF */
.nav-item-icon-selected {
  filter: invert(27%) sepia(39%) saturate(6116%) hue-rotate(237deg) brightness(100%) contrast(104%);
}

`, "",{"version":3,"sources":["webpack://./src/components/atoms/navItem/navItem.css"],"names":[],"mappings":"AAGA;EACE,iBAAiB;EACjB,YAAY;EACZ,cAAc;EACd,wBAAwB;EACxB,wBAAwB;EACxB,8BAA8B;EAC9B,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,8CAA8C;AAChD;;;AAGA;EACE,mCAAmC;EACnC,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B;;;AAGA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,6CAA6C;EAC7C,qBAAqB;EACrB,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA,gCAAgC;AAChC;EACE,uBAAuB;;AAEzB;;AAEA,uCAAuC;AACvC,6CAA6C;AAC7C,oBAAoB;AACpB;EACE,2FAA2F;AAC7F;AACA,YAAY;AACZ;EACE,iGAAiG;AACnG","sourcesContent":["@import \"../../styles/global.css\";\n\n\n.nav-item {\n  width: 17.3125rem;\n  height: 4rem;\n  flex-shrink: 0;\n  border-radius: 0.9375rem;\n  padding: 1.25rem 2.06rem;\n  margin: 0rem 0rem 1.19rem 0rem;\n  user-select: none;\n}\n\n.nav-item:hover {\n  background-color: var(--background-color-dark);\n}\n\n\n.nav-item-selected {\n  background: rgba(81, 72, 250, 0.05);\n  font-weight: 600;\n}\n\n.nav-item-transition {\n  transition: ease-in-out 100ms;\n}\n\n\n.nav-item-not-selected {\n  font-weight: 500;\n}\n\n.nav-item-selected > p {\n  color: var(--primary);\n}\n\n.nav-item-icon {\n  /* margin: 1.25rem 0.75rem 1.25rem 2.06rem; */\n  margin-right: 0.75rem;\n  width: 1.5rem;\n  height: 1.5rem;\n  justify-content: center;\n  align-items: center;\n}\n\n.nav-item-disabled {\n  color: #D3D3D3;\n  opacity: 0.5;\n}\n\n/* Should probaby not use this */\n.nav-item-disabled:hover {\n  background: transparent;\n\n}\n\n/*https://codepen.io/sosuke/pen/Pjoqqp*/\n/* THIS HAS TO BE RECOMPUTED IF COLOR CHANGE*/\n/* color: #C8C8C8; */\n.nav-item-icon-not-selected {\n  filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(204deg) brightness(93%) contrast(84%);\n}\n/* #514BFF */\n.nav-item-icon-selected {\n  filter: invert(27%) sepia(39%) saturate(6116%) hue-rotate(237deg) brightness(100%) contrast(104%);\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
