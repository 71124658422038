var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import StatsTile from "../../atoms/statsTile/statsTile";
/**
 * This component sill simply take in an Array of StatsTileProps and then return a Row of Stats Tiles accordingly.
 *
 * Use this component as the underlying component when creating Page Specific StatsTileRows.
 *
 * The individual StatsTile are growable and will take up the entirety of the available space evenly.
 */
export default function StatsTileRow(props) {
    var array = props.data.map(function (data, index) {
        return (_jsx(StatsTile, { icon: data.icon, link: data.link, score: data.score, title: data.title, points: data.points, linkTitle: data.linkTitle, status: data.status, userType: data.userType }, index));
        // return StatsTile(data);
    });
    return (_jsxs("div", __assign({ className: "flex flex-row  justify-evenly flex-grow 1 mb-1 flex-wrap gap-y-2" }, { children: __spreadArray([], array, true) })));
}
