var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './logo.css';
import icon from '../../../assets/logo.svg';
/**
 * The Logo
 * This is simply the MyEmploy Logo. Additional styling can be added via the className (Tailwind), or Style prop
 */
export default function Logo(props) {
    return (_jsx("div", __assign({ className: "logo ".concat(props.className, " inline-block align-middle"), style: props.style }, { children: _jsx("img", { src: icon }) })));
}
