var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import QuestionnaireOrg from "../../../organisms/jsQuestionnaire/jsQuestionnaire";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfidenceAction, ConfidenceFormAction, } from "../../../../redux/actions/job_seeker_questionnaire";
/**
 * The Confidence Questionnaire Page
 * This will need to call all relevant APIs to get the values.
 * @param props
 * @returns
 */
function ConfidenceQuestionnaire() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _a = useState(''), error = _a[0], setError = _a[1];
    useEffect(function () {
        dispatch(ConfidenceAction());
    }, [dispatch]);
    //using selector hook to fetch data from the reducer
    var _b = useSelector(function (state) { return state.rootReducer.JobSeekerQuestionnaireReducer; }), confidenceData = _b.confidenceData, error_confidence = _b.error_confidence, loading = _b.loading, confidenceFormData = _b.confidenceFormData, success_confidence_form = _b.success_confidence_form;
    var loginData = useSelector(function (state) { return state.rootReducer.userReducer; }).loginData;
    //function to submit the input user data
    var handleFormSubmit = function (formData) {
        var sanitizedData = Object.keys(formData).reduce(function (acc, key) {
            acc[key] = DOMPurify.sanitize(formData[key]);
            return acc;
        }, {});
        dispatch(ConfidenceFormAction(sanitizedData, loginData));
    };
    var handleBackBtn = function () {
        navigate("/questionnaire/generalSkills");
    };
    if (success_confidence_form) {
        navigate("/questionnaire/attributes");
    }
    return (_jsxs("div", __assign({ className: "" }, { children: [error && _jsx("div", __assign({ className: "error-message" }, { children: error })), _jsx(QuestionnaireOrg, { title: "Job Search Confidence", content: "We are now interested in your general skills and confidence in preparing for and searching for work.", data: confidenceData, responses: confidenceFormData, handleSubmit: handleFormSubmit, handleBackBtn: handleBackBtn, classname: "confidence-w", width: "28%" })] })));
}
export default ConfidenceQuestionnaire;
