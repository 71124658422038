var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios, { AxiosError } from "axios";
export var APIType;
(function (APIType) {
    APIType[APIType["GET"] = 0] = "GET";
    APIType[APIType["POST"] = 1] = "POST";
    APIType[APIType["PUT"] = 2] = "PUT";
    // PATCH,
    // DELETE
})(APIType || (APIType = {}));
/**
 * The Authentication Service
 *
 * Manages and regenerates access and refresh tokens for you.
 * It currently does it by storing the time when the token was generated.
 * This is passed to all sagas via middleware context.
 * You may retrieve any relevant methods of this class by the follwing code:
 *
 * import store, { AuthServiceContext } from "../store";
 *
 * ```ts
 * function* someSaga() {
 *  const { callAPI, setCredentials }: AuthServiceContext = yield getContext("authService");
 *  .... some code ....
 *    const response: AxiosResponse = yield call(() => {
 *      return callAPI(APITye.<your method>, <your endpoint>, <any additional data>);
 *    });
 * }
 * ```
 *
 * The above code is just an example. You do not have to follow the
 * same exact pattern.
 *
 * If the token has been generated XYZ minutes ago and it has been longer than
 * the token expiration time, then when using callAPI, it will automatically
 * regenerate the tokens AND perform the original API request that you want.
 *
 * However, if the refresh token as also expired, then this program will not
 * be able to regnerate. Instead will redirect the users to the logout screen.
 * This will call the logout action from dispatch and will clear all state.
 *
 */
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService() {
        this.refreshApiURL = "/api/token/refresh/";
    }
    /**
     * Navigate to the Login Page with the logout param to be true.
     */
    AuthenticationService.prototype.logoutUser = function () {
        sessionStorage.setItem("logout", "true");
        window.location.replace("/login");
    };
    /**
     * Obtain the new tokens. Shallow error handling.
     * Errors should be handled by the end caller of callAPI
    */
    AuthenticationService.prototype.getToken = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var access, refresh, response, access, error_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        // Page Refresh or New Page
                        if (!this.refreshToken || !this.accessToken) {
                            access = (_a = sessionStorage.getItem("accessToken")) !== null && _a !== void 0 ? _a : "";
                            refresh = (_b = sessionStorage.getItem("refreshToken")) !== null && _b !== void 0 ? _b : "";
                            if (access === "" || refresh === "") {
                                // Tokens were not in sessionStorage
                                return [2 /*return*/, false];
                            }
                            this.setCredentials(access, refresh);
                            return [2 /*return*/, true];
                            // Not sure why it bugs out. Should investivate further why its undefined on refresh but not the accessToken
                        }
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.post(this.refreshApiURL, {
                                refresh: this.refreshToken,
                            })];
                    case 2:
                        response = _e.sent();
                        if (!response) {
                            throw new Error("EXPIRE_REFRESHING");
                        }
                        access = response.data.access;
                        this.setCredentials(access, this.refreshToken);
                        return [2 /*return*/, true];
                    case 3:
                        error_1 = _e.sent();
                        if (error_1 instanceof AxiosError) {
                            if (((_d = (_c = error_1.response) === null || _c === void 0 ? void 0 : _c.status) !== null && _d !== void 0 ? _d : 401) === 401) {
                            }
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, false];
                }
            });
        });
    };
    /**
     * The callAPI
     *
     * Specifying the Method (APIType), endpoint and any additional data,
     * return a Promise<AxiosResponse>
     * This is the main function of this class.
     * This handles all of your token regeneration and abstracts away
     * the axios call to a higher level.
     *
     * You should not pass in the Authorization in the headers as
     * it is handled by this function and it will be overwritten.
    */
    AuthenticationService.prototype.callAPI = function (apiType, endpoint, data, noRedirect) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _c, error_2, success;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if ((data === null || data === void 0 ? void 0 : data.body) && apiType === APIType.GET) {
                            throw new Error("Invalid API Call. GET Request does not take a body");
                        }
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 10, , 15]);
                        _c = apiType;
                        switch (_c) {
                            case APIType.GET: return [3 /*break*/, 2];
                            case APIType.POST: return [3 /*break*/, 4];
                            case APIType.PUT: return [3 /*break*/, 6];
                        }
                        return [3 /*break*/, 8];
                    case 2: return [4 /*yield*/, axios.get(endpoint, {
                            headers: __assign(__assign({}, data === null || data === void 0 ? void 0 : data.headers), { Authorization: "Bearer ".concat(this.accessToken) }),
                            params: __assign({}, data === null || data === void 0 ? void 0 : data.params)
                        })];
                    case 3: return [2 /*return*/, _d.sent()];
                    case 4: return [4 /*yield*/, axios.post(endpoint, data === null || data === void 0 ? void 0 : data.body, {
                            headers: __assign(__assign({}, data === null || data === void 0 ? void 0 : data.headers), { Authorization: "Bearer ".concat(this.accessToken) }),
                            params: __assign({}, data === null || data === void 0 ? void 0 : data.params)
                        })];
                    case 5: return [2 /*return*/, _d.sent()];
                    case 6: return [4 /*yield*/, axios.put(endpoint, data === null || data === void 0 ? void 0 : data.body, {
                            headers: __assign(__assign({}, data === null || data === void 0 ? void 0 : data.headers), { Authorization: "Bearer ".concat(this.accessToken) }),
                            params: __assign({}, data === null || data === void 0 ? void 0 : data.params)
                        })];
                    case 7: return [2 /*return*/, _d.sent()];
                    case 8: throw new Error("APIType error. Not sure how this happened. Passed in an invalid apiType? How did you bypass the typechecker?");
                    case 9: return [3 /*break*/, 15];
                    case 10:
                        error_2 = _d.sent();
                        if (!(error_2 instanceof AxiosError)) return [3 /*break*/, 13];
                        if (!(((_b = (_a = error_2.response) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : NaN) == 401)) return [3 /*break*/, 12];
                        return [4 /*yield*/, this.getToken()];
                    case 11:
                        success = _d.sent();
                        if (!success && !noRedirect) {
                            this.logoutUser();
                            return [2 /*return*/, undefined];
                        }
                        return [2 /*return*/, this.callAPI(apiType, endpoint, data)];
                    case 12: 
                    // Some other Axios Error.
                    // Throw it for the caller to handle it.
                    throw error_2;
                    case 13:
                        if (error_2 instanceof Error) {
                            if (error_2.message == "EXPIRE_REFRESHING" && !noRedirect) {
                                this.logoutUser();
                                return [2 /*return*/, undefined];
                            }
                        }
                        _d.label = 14;
                    case 14: throw error_2;
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    // Setters
    AuthenticationService.prototype.setCredentials = function (token, refresh) {
        this.accessToken = token;
        this.refreshToken = refresh;
        // Session Storage is cleared on tab close but not on page refresh.
        sessionStorage.setItem("accessToken", token);
        sessionStorage.setItem("refreshToken", refresh);
    };
    return AuthenticationService;
}());
export default AuthenticationService;
