export var validateFormData = function (formData, data) {
    if (data) {
        for (var item in data) {
            var value = data[item];
            var key = Object.keys(value)[0];
            if (key == "matrix_Radio_Select_Array") {
                var subData = value[key].data;
                for (var subItem in subData) {
                    var subValue = subData[subItem];
                    var subKey = Object.keys(subValue)[0];
                    var ans = checkValidity(subKey, subValue, formData);
                    if (!ans.isValid) {
                        return ans;
                    }
                }
            }
            else {
                var ans = checkValidity(key, value, formData);
                if (!ans.isValid) {
                    return ans;
                }
            }
        }
    }
    else {
        for (var key in formData) {
            var value = formData[key];
            if ((value === null || value === void 0 ? void 0 : value.length) <= 0) {
                return {
                    isValid: false,
                    errorField: key,
                    errorMessage: "This field is required*",
                };
            }
        }
    }
    return { isValid: true };
};
export var checkValidity = function (key, value, formData) {
    var isRequired = value[key].required;
    if (isRequired) {
        var formValue = formData[key];
        if ((formValue === null || formValue === void 0 ? void 0 : formValue.length) <= 0) {
            return {
                isValid: false,
                errorField: key,
                errorMessage: "This field is required*",
            };
        }
        var maxLength = value[key].max_length;
        if (maxLength && (formValue === null || formValue === void 0 ? void 0 : formValue.length) > maxLength) {
            return {
                isValid: false,
                errorField: key,
                errorMessage: "This text limit exceeds*",
            };
        }
    }
    return { isValid: true };
};
