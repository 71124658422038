/* eslint-disable @typescript-eslint/no-explicit-any */
import { Questionnaire } from "../constants/adviser_questionnaire";
/**
 * The Adviser Demographics Action
 * This action is called when user successfully extracts the Adviser Demographics questionnaires.
 * @param props
 */
export var AdviserDemographicsAction = function () {
    return {
        type: Questionnaire.ADVISER_DEMOGRAPHICS,
    };
};
export var AdviserDemographicsFormAction = function (adviserDemographicsFormData, token) {
    return {
        type: Questionnaire.ADVISER_DEMOGRAPHICS_FORM,
        payload: adviserDemographicsFormData,
        token: token,
    };
};
/**
 * The Adviser Progression Action
 * This action is called when user successfully extracts the Adviser Progression questionnaires.
 * @param props
 */
export var AdviserProgressionAction = function () {
    return {
        type: Questionnaire.ADVISER_PROGRESSION,
    };
};
export var AdviserProgressionFormAction = function (adviserProgressionFormData, token) {
    return {
        type: Questionnaire.ADVISER_PROGRESSION_FORM,
        payload: adviserProgressionFormData,
        token: token,
    };
};
/**
 * The Adviser Client Obligations Action
 * This action is called when user successfully extracts the Adviser Client Obligations questionnaires.
 * @param props
 */
export var AdviserClientObligationsAction = function () {
    return {
        type: Questionnaire.ADVISER_CLIENT_OBLIGATIONS,
    };
};
export var AdviserClientObligationsFormAction = function (adviserClientObligationsFormData, token) {
    return {
        type: Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FORM,
        payload: adviserClientObligationsFormData,
        token: token,
    };
};
/**
 * The Adviser Adviser Obligations Action
 * This action is called when user successfully extracts the Adviser Adviser Obligations questionnaires.
 * @param props
 */
export var AdviserAdviserObligationsAction = function () {
    return {
        type: Questionnaire.ADVISER_ADVISER_OBLIGATIONS,
    };
};
export var AdviserAdviserObligationsFormAction = function (adviserAdviserObligationsFormData, token) {
    return {
        type: Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FORM,
        payload: adviserAdviserObligationsFormData,
        token: token,
    };
};
/**
 * The Adviser Global Action
 * This action is called when user successfully extracts the Adviser Global questionnaires.
 * @param props
 */
export var AdviserGlobalAction = function () {
    return {
        type: Questionnaire.ADVISER_GLOBAL,
    };
};
export var AdviserGlobalFormAction = function (adviserGlobalFormData, token) {
    return {
        type: Questionnaire.ADVISER_GLOBAL_FORM,
        payload: adviserGlobalFormData,
        token: token,
    };
};
/**
 * The Adviser Jobseeker Outcome Action
 * This action is called when user successfully extracts the Adviser Jobseeker Outcome questionnaires.
 * @param props
 */
export var AdviserJobseekerOutcomeAction = function () {
    return {
        type: Questionnaire.ADVISER_JOBSEEKER_OUTCOME,
    };
};
export var AdviserJobseekerOutcomeFormAction = function (adviserJobseekerOutcomeFormData, token) {
    return {
        type: Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FORM,
        payload: adviserJobseekerOutcomeFormData,
        token: token,
    };
};
