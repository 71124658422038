var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../atoms/button/button";
import LeftCoverImage from "../atoms/leftCoverImage/leftCoverImage";
import BrandHeader from "../molecules/brandHeader/brandHeader";
import "../styles/main.css";
import "../styles/login.css";
import InputField from "../molecules/inputField/inputField";
import LoginTemplate from "../templates/loginTemplate";
import { LogoutAction, ResetActionConfirm } from "../../redux/actions/authentication";
import ErrorMessage from "../atoms/errorMessage/errorMessage";
/**
 * The Login Reset Page
 * This will need to call all relevant APIs to get the values.
 * This page will be redirected via token link sent to the user on their personal email id to reset their login password.
 * @param props
 * @returns
 */
function LoginReset() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    // Log any current session out.
    // This also prevents autofilling fields in
    dispatch(LogoutAction());
    //using useSearchParams to extract query parameters
    var searchParams = useSearchParams()[0];
    var uid = searchParams.get("uid");
    var token = searchParams.get("token");
    var _a = useState(""), error = _a[0], setError = _a[1];
    var validatePassword = function (password) {
        var regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/;
        return regex.test(password);
    };
    //creating react state hook to store password and confirmed password locally
    var _b = useState({
        password: "",
        confirmPassword: "",
    }), loginData = _b[0], setLoginData = _b[1];
    //function to submit the input user data
    function handleSubmit(e) {
        e.preventDefault();
        // Validate  password
        if (!validatePassword(loginData.password)) {
            setError("Password must be at least 12 characters long and include at least one uppercase letter, one number, and one special character.");
            return;
        }
        // Check if passwords match
        if (loginData.password !== loginData.confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        // Send password reset request to backend API
        if (uid && token) {
            var reset_data = {
                uid: uid,
                token: token,
                password: loginData.password,
            };
            dispatch(ResetActionConfirm(reset_data));
            navigate("/login");
        }
        else {
            setError("Invalid or missing token and user ID.");
        }
    }
    //function to change the values in the input fields entered by the user
    var handleChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setLoginData(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (_jsx(LoginTemplate, { leftImage: _jsx(LeftCoverImage, { second: true }), form: _jsxs("form", __assign({ onSubmit: handleSubmit, className: "form m-3" }, { children: [_jsx(BrandHeader, {}), _jsx("h1", __assign({ className: "heading mt-5" }, { children: "Reset Password" })), _jsx("div", __assign({ className: "h3 mb-3 mt-1 font-medium text grey" }, { children: "Welcome back! Secure your account by creating a new password. Enter it below and confirm to complete the reset." })), _jsx(InputField, { label: "Password", type: "password", id: "id_password", name: "password", placeholder: "password", value: loginData.password, onChange: handleChange }), _jsx(InputField, { label: "Confirm Password", type: "password", id: "id_confirm_password", name: "confirmPassword", placeholder: "confirm password", value: loginData.confirmPassword, onChange: handleChange }), _jsx("div", { className: "float-right" }), _jsx("div", __assign({ className: "pt-6" }, { children: _jsx(Button, { border: "none", onClick: function () { return handleSubmit; }, text: "Continue", className: "mt-6" }) })), error != "" && _jsx(ErrorMessage, { message: error, classname: "text-center" }, "loginReset")] })) }));
}
export default LoginReset;
