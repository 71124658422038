var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Questionnaire } from "../constants/adviser_questionnaire";
import { Status } from "../constants/status";
var initialState = {
    adviserDemographicsData: [],
    error_adviserDemographics: null,
    adviserDemographicsFormData: [],
    success_adviserDemographics_form: null,
    error_adviserDemographics_form: null,
    adviserProgressionData: [],
    error_adviserProgression: null,
    adviserProgressionFormData: [],
    success_adviserProgression_form: null,
    error_adviserProgression_form: null,
    adviserClientObligationsData: [],
    error_adviserClientObligations: null,
    adviserClientObligationsFormData: [],
    success_adviserClientObligations_form: null,
    error_adviserClientObligations_form: null,
    adviserAdviserObligationsData: [],
    error_adviserAdviserObligations: null,
    adviserAdviserObligationsFormData: [],
    success_adviserAdviserObligations_form: null,
    error_adviserAdviserObligations_form: null,
    adviserGlobalData: [],
    error_adviserGlobal: null,
    adviserGlobalFormData: [],
    success_adviserGlobal_form: null,
    error_adviserGlobal_form: null,
    adviserJobseekerOutcomeData: [],
    error_adviserJobseekerOutcome: null,
    adviserJobseekerOutcomeFormData: [],
    success_adviserJobseekerOutcome_form: null,
    error_adviserJobseekerOutcome_form: null,
    status: Status.Loading,
};
/**
 * The Adviser Questionnaire Reducer
 * This reducer is responsible for handling the state and action management of the adviser questionnaire requests.
 * @param props
 */
var AdviserQuestionnaireReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case Questionnaire.ADVISER_DEMOGRAPHICS:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_DEMOGRAPHICS_SUCCESS:
            return __assign(__assign({}, state), { adviserDemographicsData: action.payload, status: Status.Successful });
        case Questionnaire.ADVISER_DEMOGRAPHICS_FAILURE:
            return __assign(__assign({}, state), { error_adviserDemographics: action.payload, status: Status.Error });
        case Questionnaire.ADVISER_DEMOGRAPHICS_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_DEMOGRAPHICS_FORM_SUCCESS:
            return __assign(__assign({}, state), { adviserDemographicsFormData: action.payload, success_adviserDemographics_form: action.status, status: Status.Successful });
        case Questionnaire.ADVISER_DEMOGRAPHICS_FORM_FAILURE:
            return __assign(__assign({}, state), { error_adviserDemographics_form: action.status, status: Status.Error });
        case Questionnaire.ADVISER_PROGRESSION:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_PROGRESSION_SUCCESS:
            return __assign(__assign({}, state), { adviserProgressionData: action.payload, status: Status.Successful });
        case Questionnaire.ADVISER_PROGRESSION_FAILURE:
            return __assign(__assign({}, state), { error_adviserProgression: action.payload, status: Status.Error });
        case Questionnaire.ADVISER_PROGRESSION_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_PROGRESSION_FORM_SUCCESS:
            return __assign(__assign({}, state), { adviserProgressionFormData: action.payload, success_adviserProgression_form: action.status, status: Status.Successful });
        case Questionnaire.ADVISER_PROGRESSION_FORM_FAILURE:
            return __assign(__assign({}, state), { error_adviserProgression_form: action.status, status: Status.Error });
        case Questionnaire.ADVISER_CLIENT_OBLIGATIONS:
            return __assign(__assign({}, state), { success_adviserProgression_form: null, status: Status.Loading });
        case Questionnaire.ADVISER_CLIENT_OBLIGATIONS_SUCCESS:
            return __assign(__assign({}, state), { adviserClientObligationsData: action.payload, status: Status.Successful });
        case Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FAILURE:
            return __assign(__assign({}, state), { error_adviserClientObligations: action.payload, status: Status.Error });
        case Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FORM_SUCCESS:
            return __assign(__assign({}, state), { adviserClientObligationsFormData: action.payload, success_adviserClientObligations_form: action.status, status: Status.Successful });
        case Questionnaire.ADVISER_CLIENT_OBLIGATIONS_FORM_FAILURE:
            return __assign(__assign({}, state), { error_adviserClientObligations_form: action.status, status: Status.Error });
        case Questionnaire.ADVISER_ADVISER_OBLIGATIONS:
            return __assign(__assign({}, state), { success_adviserClientObligations_form: null, status: Status.Loading });
        case Questionnaire.ADVISER_ADVISER_OBLIGATIONS_SUCCESS:
            return __assign(__assign({}, state), { adviserAdviserObligationsData: action.payload, status: Status.Successful });
        case Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FAILURE:
            return __assign(__assign({}, state), { error_adviserAdviserObligations: action.payload, status: Status.Error });
        case Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FORM_SUCCESS:
            return __assign(__assign({}, state), { adviserAdviserObligationsFormData: action.payload, success_adviserAdviserObligations_form: action.status, status: Status.Successful });
        case Questionnaire.ADVISER_ADVISER_OBLIGATIONS_FORM_FAILURE:
            return __assign(__assign({}, state), { error_adviserAdviserObligations_form: action.status, status: Status.Error });
        case Questionnaire.ADVISER_GLOBAL:
            return __assign(__assign({}, state), { success_adviserAdviserObligations_form: null, status: Status.Loading });
        case Questionnaire.ADVISER_GLOBAL_SUCCESS:
            return __assign(__assign({}, state), { adviserGlobalData: action.payload, status: Status.Successful });
        case Questionnaire.ADVISER_GLOBAL_FAILURE:
            return __assign(__assign({}, state), { error_adviserGlobal: action.payload, status: Status.Error });
        case Questionnaire.ADVISER_GLOBAL_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_GLOBAL_FORM_SUCCESS:
            return __assign(__assign({}, state), { adviserGlobalFormData: action.payload, success_adviserGlobal_form: action.status, status: Status.Successful });
        case Questionnaire.ADVISER_GLOBAL_FORM_FAILURE:
            return __assign(__assign({}, state), { error_adviserGlobal_form: action.status, status: Status.Error });
        case Questionnaire.ADVISER_JOBSEEKER_OUTCOME:
            return __assign(__assign({}, state), { success_adviserAdviserObligations_form: null, status: Status.Loading });
        case Questionnaire.ADVISER_JOBSEEKER_OUTCOME_SUCCESS:
            return __assign(__assign({}, state), { adviserJobseekerOutcomeData: action.payload, status: Status.Successful });
        case Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FAILURE:
            return __assign(__assign({}, state), { error_adviserJobseekerOutcome: action.payload, status: Status.Error });
        case Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FORM_SUCCESS:
            return __assign(__assign({}, state), { adviserJobseekerOutcomeFormData: action.payload, success_adviserJobseekerOutcome_form: action.status, status: Status.Successful });
        case Questionnaire.ADVISER_JOBSEEKER_OUTCOME_FORM_FAILURE:
            return __assign(__assign({}, state), { error_adviserJobseekerOutcome_form: action.status, status: Status.Error });
        default:
            return state;
    }
};
export default AdviserQuestionnaireReducer;
