var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import Button from "../../atoms/button/button";
import Card from "../../atoms/card/card";
import Bar from "../../molecules/bar/bar";
import NavBar from "../../organisms/navBar/navBar";
import "./dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { getQuesAttemptDetails, getStatsTileData, } from "../../../redux/actions/dashboard";
import { useLocation, useNavigate } from "react-router";
import DashboardStats from "./dashboardStats";
import DashboardGraps from "./dashboardGraps";
import PageHeader from "../../organisms/pageHeader/pageHeader";
import { getCaseloadsDashboard } from "../../../redux/actions/manage_users";
import { getQuestionnaireHistory } from "../../../redux/actions/questionnaireHistoryActions";
/**
 * This returns the ordinals for a given date.
 * 1 becomes st
 * 2 becomes nd
 * etc...
 * @param {number} number The date.
 */
function nthNumber(number) {
    return number > 0
        ? ["th", "st", "nd", "rd"][(number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10]
        : "";
}
/**
 * The Dashboard Page
 * This will need to call all relevant APIs to get the values.
 * @param props
 * @returns
 */
function Dashboard() {
    var dateObj = new Date();
    var day = dateObj.getDate();
    var month = dateObj.toLocaleString("default", { month: "long" });
    var year = dateObj.getFullYear();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var userToken = useSelector(function (state) { return state.rootReducer.userReducer.loginData; });
    var username = useSelector(function (state) { return state.rootReducer.userReducer.username; });
    // const username = useSelector(
    //   (state: any) => state.rootReducer.userReducer.logged_in_username
    // );
    var user_type = useSelector(function (state) { return state.rootReducer.userReducer.userType; });
    var needs_questionnaire = useSelector(function (state) { return state.rootReducer.dashboardReducer.needs_questionnaire; });
    var numberOfAttempts = useSelector(function (state) {
        return state.rootReducer.questionnaireHistoryReducer.numberOfAttempts;
    });
    var complete = useSelector(function (state) { return state.rootReducer.questionnaireHistoryReducer.complete; });
    var state = useLocation().state;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    useEffect(function () {
        dispatch(getQuestionnaireHistory(username));
    }, []);
    if (!userToken) {
        // User has not logged in, no auth token
        // Redirect to Login
        useEffect(function () {
            navigate("/login");
        }, []);
        // } else if (numberOfAttempts === 0 && user_type === "jobseeker") {
        //   useEffect(() => {
        //     navigate("/");
        //   }, []);
    }
    else {
        // Now we know that the user is logged in. Perform the API calls
        useEffect(function () {
            if (state && state.viewUser) {
                dispatch(getCaseloadsDashboard(state.viewUser));
                dispatch(getStatsTileData(state.viewUser));
            }
            else {
                dispatch(getCaseloadsDashboard(username));
                dispatch(getStatsTileData(username));
            }
        }, [state]);
    }
    var statTileFetchStatus = useSelector(function (state) { return state.rootReducer.dashboardReducer.status; });
    var data = useSelector(function (state) { return state.rootReducer.dashboardReducer.graphData; });
    var rightSide = useSelector(function (state) { return state.rootReducer.dashboardReducer.score; });
    // Now we know that the username. Perform the API calls
    useEffect(function () {
        if (state && state.viewUser) {
            dispatch(getQuesAttemptDetails(state.viewUser));
        }
        else {
            dispatch(getQuesAttemptDetails(username));
        }
    }, []);
    var quesAttemptDetails = useSelector(function (state) { return state.rootReducer.dashboardReducer.quesAttemptDetails; });
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var viewing_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_user_type; });
    var role = useSelector(function (state) { return state.rootReducer.userReducer.role; });
    if (needs_questionnaire) {
        if (user_type === "jobseeker") {
            navigate("/questionnaire/antecedents");
        }
    }
    var handleQuestionnaire = function () {
        if (quesAttemptDetails &&
            quesAttemptDetails.second_attempt_ready &&
            !quesAttemptDetails.completed_twice) {
            navigate("/questionnaire/antecedents");
        }
    };
    var viewing_username = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_username; });
    if (role === "jobseeker" && numberOfAttempts < 1 && complete) {
        useEffect(function () {
            navigate("/questionnaire/antecedents");
        }, []);
    }
    return (_jsxs("div", __assign({ className: "dashboard" }, { children: [_jsx(NavBar, { viewing_user: viewing_user_type, logged_in_user: logged_in_user_type, viewing_username: viewing_username }), _jsxs("div", __assign({ className: "dashboard-page-template-right" }, { children: [logged_in_user_type === viewing_user_type ? (_jsx(PageHeader, { title: "".concat(username, " Dashboard") })) : (_jsx(PageHeader, { title: "".concat(state && state.viewUser, " Dashboard"), backButton: "Manage Users", onClick: function () {
                            navigate("/manage", {
                                state: { viewUser: username },
                            });
                        } })), _jsx(DashboardStats, {}), _jsxs(Bar, __assign({ title: "Employability Area", className: "my-3 py-2" }, { children: [_jsx(Card, { children: "".concat(day).concat(nthNumber(day), " ").concat(month, " ").concat(year) }), logged_in_user_type == "jobseeker" && quesAttemptDetails &&
                                quesAttemptDetails.second_attempt_ready &&
                                !quesAttemptDetails.completed_twice && (_jsx(Button, __assign({ onClick: handleQuestionnaire, className: "h-16 w-44 ml-3" }, { children: "Questionnaire 2" })))] })), _jsx(DashboardGraps, { data: data, rightSide: rightSide })] }))] })));
}
export default Dashboard;
