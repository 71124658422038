// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-overview {
  width: 100%;
  margin: 0 2.9rem 2.9rem;
}

.tabs-hr {
  background: var(--grey);
  width: 100%;
  height: 0.0625rem;
}

.active {
  border-radius: 0.15625rem;
  border-bottom: 0.1875rem solid #5148fa;
}

.active p {
  color: #5148fa;
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/tabs/tabs.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,sCAAsC;AACxC;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@import \"../../styles/global.css\";\n\n.tabs-overview {\n  width: 100%;\n  margin: 0 2.9rem 2.9rem;\n}\n\n.tabs-hr {\n  background: var(--grey);\n  width: 100%;\n  height: 0.0625rem;\n}\n\n.active {\n  border-radius: 0.15625rem;\n  border-bottom: 0.1875rem solid #5148fa;\n}\n\n.active p {\n  color: #5148fa;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
