var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ManageUser } from "../constants/manage_users";
import { Status } from "../constants/status";
var initialState = {
    manageUserState: ManageUser.ManageUser,
    manageExportUsers: "",
    manageArchiveUsers: "",
};
function getShortDate(date) {
    var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    var day = date.getDate().toString().padStart(2, "0");
    var month = months[date.getMonth()];
    var year = date.getFullYear();
    return "".concat(day, " ").concat(month, " ").concat(year);
}
function parseJobseekers(data) {
    var result = [];
    Object.keys(data["jobseekers"]).forEach(function (name) {
        var current = data["jobseekers"][name];
        var date = new Date(current["date_joined"]);
        var shortdate = getShortDate(date);
        var blr = current["blr_score"] < 1 ? "-" : current["blr_score"].toString() + "%";
        var diff = 0;
        var count = 0;
        if (current["questionnaire_1_date"] !== "") {
            count = 1;
        }
        if (current["questionnaire_2_date"] !== "") {
            count = 2;
            var q1Date = new Date(current["questionnaire_1_date"]);
            var q2Date = new Date(current["questionnaire_2_date"]);
            var timeDiff = Math.abs(q2Date.getTime() - q1Date.getTime());
            diff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        }
        result.push({
            name: name,
            dateJoined: shortdate,
            questionnaire_attempts: count,
            blr_score: blr,
            timegap: diff,
        });
    });
    return result;
}
function parseAdvisers(data) {
    var result = [];
    Object.keys(data["advisers"]).forEach(function (name) {
        var current = data["advisers"][name];
        var date = new Date(current["date_joined"]);
        var shortdate = getShortDate(date);
        result.push({
            name: name,
            dateJoined: shortdate,
            caseload: current["caseload_count"].toString(),
        });
    });
    return result;
}
function parseManagers(data) {
    var result = [];
    Object.keys(data["managers"]).forEach(function (name) {
        var current = data["managers"][name];
        var date = new Date(current["date_joined"]);
        var shortdate = getShortDate(date);
        result.push({
            name: name,
            dateJoined: shortdate,
            adviser_number: current["adviser_count"],
            caseload: current["caseload_count"],
        });
    });
    return result;
}
function parseCaseload(data) {
    var parsedData = {};
    switch (data.user_type) {
        case "organisation":
            parsedData.managers = parseManagers(data);
            parsedData.advisers = parseAdvisers(data);
            parsedData.jobseekers = parseJobseekers(data);
            return parsedData;
        case "manager":
            parsedData.advisers = parseAdvisers(data);
            parsedData.jobseekers = parseJobseekers(data);
            return parsedData;
        case "adviser":
            parsedData.jobseekers = parseJobseekers(data);
            return parsedData;
        default:
            return parsedData;
    }
}
var ManageUserReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ManageUser.ManageUser:
            return __assign(__assign({}, state), { children: undefined, status: Status.Loading });
        case ManageUser.ManageUser_Sucessful:
            return __assign(__assign({}, state), { status: Status.Successful, caseload: parseCaseload(action.payload), manageArchiveUsers: "" });
        case ManageUser.ManageExportUsers:
            return __assign(__assign({}, state), { status: Status.Loading });
        case ManageUser.ManageExportUsers_Sucessful:
            return __assign(__assign({}, state), { manageExportUsers: action.payload, status: Status.Successful });
        case ManageUser.ManageArchiveUsers:
            return __assign(__assign({}, state), { status: Status.Loading });
        case ManageUser.ManageArchiveUsers_Sucessful:
            return __assign(__assign({}, state), { manageArchiveUsers: action.payload, status: Status.Successful });
        case ManageUser.ManageCreateUser_Successful:
            return __assign(__assign({}, state), { createUserStatus: { status: Status.Successful } });
        case ManageUser.ManageCreateUser_Error:
            return __assign(__assign({}, state), { createUserStatus: { status: Status.Error, message: action.payload } });
        case ManageUser.ManageCreateUser:
            return __assign(__assign({}, state), { createUserStatus: { status: Status.Loading } });
        default:
            return state;
    }
};
export default ManageUserReducer;
