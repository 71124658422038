// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar-title {
  font-size: 1.625rem;
  font-weight: 500;
}

.bar {
  margin: 1.56rem 0rem;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/bar/bar.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["@import \"../../styles/global.css\";\n\n\n.bar-title {\n  font-size: 1.625rem;\n  font-weight: 500;\n}\n\n.bar {\n  margin: 1.56rem 0rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
