var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Input from "../../atoms/input/input";
import Label from "../../atoms/label/label";
import "../../styles/main.css";
import "../../styles/global.css";
import "../../styles/login.css";
import "./inputField.css";
/**
 * Input Field with label.
 * Props is specific to input field with labels and can accept different values.
 * @param props
 * @returns
 */
export default function InputField(props) {
    //inherited from Label and Input atom
    // Quick fix
    // For some reason Typescript doesnt allow this line, need to use if else
    // const inputChange = props.onInputChange ?? () => {};
    var inputChange;
    if (props.onInputChange) {
        inputChange = props.onInputChange;
    }
    else {
        inputChange = function () { };
    }
    return (_jsxs("p", __assign({ className: "mt-6" }, { children: [_jsx(Label, { label: props.label }), _jsx(Input, { type: props.type, name: props.name, id: props.id, value: props.value, onChange: props.onChange, placeholder: props.placeholder, onInputChange: inputChange, className: props.className })] })));
}
