// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-key {
  color: var(--text-color);
  font-size: 1.125rem;
  font-weight: 500;
}

.profile-value {
  color: var(--text-color-grey);
  font-size: 1.125rem;
  font-weight: 500;
}

.profile-edit-btn {
  width: 7.875rem;
  height: 3.75rem;
  border-radius: 1rem;
}

.set-margin {
  margin-top: 3%;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/profile/profile.css"],"names":[],"mappings":"AAGA;EACE,wBAAwB;EACxB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@import \"../../styles/global.css\";\n\n\n.profile-key {\n  color: var(--text-color);\n  font-size: 1.125rem;\n  font-weight: 500;\n}\n\n.profile-value {\n  color: var(--text-color-grey);\n  font-size: 1.125rem;\n  font-weight: 500;\n}\n\n.profile-edit-btn {\n  width: 7.875rem;\n  height: 3.75rem;\n  border-radius: 1rem;\n}\n\n.set-margin {\n  margin-top: 3%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
