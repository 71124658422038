var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './leftCoverImage.css';
import coverimage from "../../../assets/coverimage.png";
import coverimage2 from "../../../assets/coverimage2.png";
/**
 * Left Cover Image.
 * Props can take in class name which will style the image used in the left side of the authentication screens.
 * @param props
 * @returns
 */
export default function LeftCoverImage(props) {
    return (_jsxs("div", __assign({ className: "w-1/2 image-container ".concat(props.className) }, { children: [_jsx("img", { src: props.second ? coverimage2 : coverimage, alt: "Cover Image" }), _jsx("div", { className: "gradient-overlay" })] })));
}
