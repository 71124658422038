var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate } from "react-router-dom";
import NavItem from "../../atoms/navItem/navItem";
import dashboard from "../../../assets/icons/dashboard.svg";
import obligations from "../../../assets/icons/obligations.svg";
import helpAndSupport from "../../../assets/icons/help-and-support.svg";
import questionnaire from "../../../assets/icons/questionnaire.svg";
import adviser from "../../../assets/icons/adviser.svg";
import termsAndConditions from "../../../assets/icons/terms-and-conditions.svg";
import settings from "../../../assets/icons/settings.svg";
import manage from "../../../assets/icons/manage.svg";
import BrandHeader from "../../molecules/brandHeader/brandHeader";
import "./navBar.css";
import Button from "../../atoms/button/button";
import Confirm from "../../atoms/confirm/confirm";
import { useDispatch, useSelector } from "react-redux";
import { LogoutAction } from "../../../redux/actions/authentication";
import { ButtonType } from "../../atoms/button/buttonType";
import showPopupModal from "../../../redux/actions/popupModal";
import { getCaseloads, getCaseloadsDashboard, } from "../../../redux/actions/manage_users";
var navItemData = {
    Dashboard: {
        name: "Dashboard",
        icon: dashboard,
        url: "/dashboard",
    },
    Questionnaire: {
        name: "Questionnaire History",
        icon: questionnaire,
        url: "/questionnaire",
    },
    Obligations: {
        name: "Obligations",
        icon: obligations,
        url: "/obligations",
    },
    Adviser: {
        name: "Adviser",
        icon: adviser,
        url: "/upper",
    },
    Manager: {
        name: "Manager",
        icon: adviser,
        url: "/upper",
    },
    Organisation: {
        name: "Organisation",
        icon: adviser,
        url: "/upper",
    },
    Help: {
        name: "Help & Support",
        icon: helpAndSupport,
        url: "/help_and_support",
    },
    Guide: {
        name: "User Guide",
        icon: manage,
        url: "/user_guide",
    },
    Terms: {
        name: "Terms & Conditions",
        icon: termsAndConditions,
        url: "/terms_and_conditions",
    },
    Settings: {
        name: "Settings",
        icon: settings,
        url: "/settings",
        disabled: true,
    },
    Manage: {
        name: "Manage Users",
        icon: manage,
        url: "/manage",
    },
    Demographics: {
        name: "Adviser Demographics",
        icon: adviser,
        url: "/questionnaire/adviserDemographics",
    },
};
export default function NavBar(props) {
    var location = useLocation();
    var navigate = useNavigate();
    var dispatch = useDispatch();
    function constructNavItem(name, navigate, pathname, user) {
        var re = new RegExp("^".concat(navItemData[name].url));
        return (_jsx(NavItem, { text: navItemData[name].name, icon: navItemData[name].icon, isSelected: re.test(pathname), link: navItemData[name].url, disabled: navItemData[name].disabled, onClick: function () {
                if (navItemData[name].url === "/manage" && user) {
                    dispatch(getCaseloads(user));
                    dispatch(getCaseloadsDashboard(user));
                }
                navigate(navItemData[name].url, { state: { viewUser: user } });
            } }, name));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var userType = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (state) { return state.rootReducer.userReducer.userType; });
    var navItems;
    switch (props.viewing_user || userType) {
        case "adviser":
            navItems = [
                "Dashboard",
                "Manage",
                "Obligations",
                "Manager",
                "Help",
                "Guide",
                "Terms",
                "Settings",
                "Demographics",
            ];
            break;
        case "manager":
            navItems = ["Dashboard", "Manage", "Obligations", "Organisation", "Help", "Guide", "Terms", "Settings"];
            break;
        case "organisation":
            navItems = ["Dashboard", "Manage", "Help", "Guide", "Obligations", "Terms", "Settings"];
            break;
        case "jobseeker":
        default:
            navItems = [
                "Dashboard",
                "Questionnaire",
                "Obligations",
                "Adviser",
                "Help",
                "Guide",
                "Terms",
                "Settings",
            ];
    }
    var logoutBody = (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "mb-7" }, { children: "Log out" })), _jsx("p", { children: "Are you sure you want to Log Out? Confirming will end your current session. Remember to save any unsaved work before proceeding." }), _jsx(Confirm, { reverse: true, className: "mt-14", onConfirm: function () {
                    navigate("/login");
                    dispatch(LogoutAction());
                    dispatch(showPopupModal(false));
                }, onCancel: function () {
                    dispatch(showPopupModal(false));
                } })] }));
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "page-template-left flex flex-col justify-between h-full" }, { children: [_jsx(BrandHeader, { className: "page-brand-header" }), _jsx("div", __assign({ className: "flex flex-col w-full" }, { children: navItems.map(function (name) {
                        return constructNavItem(name, navigate, location.pathname, props.viewing_username);
                    }) })), _jsx(Button, __assign({ onClick: function () {
                        dispatch(showPopupModal(true, logoutBody));
                    }, className: "w-full", buttonType: ButtonType.Secondary }, { children: "Logout" }))] })) }));
}
