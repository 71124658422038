import { useState, useEffect } from 'react';
export default function useScreenSize() {
    var _a = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    }), screenSize = _a[0], setScreenSize = _a[1];
    useEffect(function () {
        var handleResize = function () {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        // Clean up the event listener when the component unmounts
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return screenSize;
}
