var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './popupModal.css';
import closeSquare from "../../../assets/icons/close-square.svg";
import { useDispatch, useSelector } from 'react-redux';
import showPopupModal from '../../../redux/actions/popupModal';
/**
 * PopupModal Component
 *
 * This component is the general PopupModal that appears on top of everything. It is a user interaction blocking component.
 * The users will not be able to interact with anything else while this in on top.
 *
 * That being said, this can be bypassed by inspecting the HTML and deleting it from the DOM Tree :/ nothing we can do about that.
 *
 * USAGE:
 * WARNING! This is not something you will manually add it to your pages!
 * This component is always available near the root of the ReactJS Application.
 *
 * Please use useDispatch() from React Redux and call showPopupModal within dispatch.
 * If you want to show something, pass in a children aswell. For example:
 * dispatch(showPopupModal(true, <p>Test!</p>))
 *
 * That will then trigger a popup modal that simply shows "Test!" with a cross at the top right.
 * Of course, the children can be something much more complex. There is no limit.
 *
 * If you wish to close it, call dispatch(showPopupModal(false)).
 *
 * This Component is typically paired with the <Confirm> component that takes in an onConfirm and onCancel callback.
 * You can override those two buttons but you should typically call dispatch(showPopupModal(false)) or something else in those callbacks.
 *
 * Example Usage can be found within navBar.tsx for the logout confirmation modal.
 */
export default function PopupModal() {
    var dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var children = useSelector(function (state) { return state.popupReducer.children; });
    return (_jsx("div", __assign({ className: "".concat(children ? 'fade' : 'hidden') }, { children: _jsxs("div", __assign({ className: "popupModal my-auto " }, { children: [_jsx("div", __assign({ className: 'flex flex-row-reverse' }, { children: _jsx("button", __assign({ onClick: function () {
                            dispatch(showPopupModal(false));
                        } }, { children: _jsx("img", { src: closeSquare }) })) })), _jsx("div", __assign({ className: 'flex flex-col justify-center items-center' }, { children: children }))] })) })));
}
