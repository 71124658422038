var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./table.css";
import { useNavigate } from "react-router";
import Button from "../../atoms/button/button";
import Shimmer from "../../atoms/shimmer/shimmer";
import Input from "../../atoms/input/input";
var TableCard = function (props) {
    var _a = useState(1), currentPage = _a[0], setCurrentPage = _a[1];
    var _b = useState(""), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = useState([]), rows = _c[0], setRows = _c[1];
    var handleSelectedData = props.handleSelectedData;
    var _d = useState(props.selectData), selectedData = _d[0], setSelectedData = _d[1];
    var _e = useState(false), isSelectedAll = _e[0], SelectAll = _e[1];
    var navigate = useNavigate();
    var totalRows = props.data.length;
    var filteredRows = props.data.filter(function (row) {
        return row.some(function (cell) {
            return (typeof cell === "string" &&
                cell.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (typeof cell === "object" &&
                    cell.text.toLowerCase().includes(searchTerm.toLowerCase()));
        });
    });
    var totalFilteredRows = filteredRows.length;
    var totalPages = Math.ceil(totalFilteredRows / props.rowsPerPage);
    var startIndex = (currentPage - 1) * props.rowsPerPage;
    var endIndex = startIndex + props.rowsPerPage;
    var endEntry = Math.min(currentPage * props.rowsPerPage, totalFilteredRows);
    useEffect(function () {
        var currentRows = filteredRows.slice(startIndex, endIndex);
        setRows(currentRows);
        setSelectedData([]);
        SelectAll(false);
    }, [props.currentTab]);
    useEffect(function () {
        // Update the rows whenever filteredRows or startIndex or endIndex changes
        var currentRows = filteredRows.slice(startIndex, endIndex);
        props.selectData && props.selectData.length > 0 ? "" : setRows(currentRows);
    }, [
        totalFilteredRows,
        currentPage,
        searchTerm,
        props.data,
        props.selectData,
    ]);
    // useEffect(()=>{
    //   if (JSON.stringify(currentRows) !== JSON.stringify(rows)) {
    //     SelectRows(currentRows);
    //       //   }
    // }, [currentRows, rows])
    var emptyRows = Array.from({ length: Math.max(0, props.rowsPerPage - rows.length) }, function (_, index) { return index; });
    var handlePageChange = function (page) {
        setCurrentPage(page);
    };
    var handleRowClick = function (link) {
        if (link) {
            if (typeof link === "string") {
                navigate(link);
            }
            else {
                navigate(link.link, link.state && link.state);
            }
        }
    };
    var generatePageNumbers = function () {
        var maxPageButtons = 5;
        var pageNumbers = [];
        var startPage = Math.max(1, Math.min(currentPage - Math.floor(maxPageButtons / 2), totalPages - maxPageButtons + 1));
        var endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
        for (var i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };
    var formatNumber = function (num) { return (num < 10 ? "0".concat(num) : num.toString()); };
    function handleSearch(value) {
        setSearchTerm(value);
        setCurrentPage(1);
    }
    var selectAllRows = function () {
        SelectAll(!isSelectedAll);
        if (isSelectedAll) {
            toggleAllCheckboxes(true);
            setSelectedData([]);
            handleSelectedData === null || handleSelectedData === void 0 ? void 0 : handleSelectedData([]);
        }
        else {
            toggleAllCheckboxes(false);
            var updatedData = rows.map(function (row) { return row[1]; });
            setSelectedData(updatedData);
            handleSelectedData === null || handleSelectedData === void 0 ? void 0 : handleSelectedData(updatedData);
        }
    };
    var toggleAllCheckboxes = function (toggle) {
        setRows(function (prevCheckboxState) {
            // Set all boolean values to true
            var updatedCheckboxState = prevCheckboxState.map(function (row) {
                var toggledBoolean = !toggle;
                return __spreadArray([toggledBoolean], row.slice(1), true);
            });
            return updatedCheckboxState;
        });
    };
    var toggleCheckbox = function (index) {
        setRows(function (prevCheckboxState) {
            // Create a new array with the updated boolean value
            var updatedCheckboxState = __spreadArray([], prevCheckboxState, true);
            updatedCheckboxState[index][0] = !updatedCheckboxState[index][0];
            return updatedCheckboxState;
        });
    };
    //function to change the values in the input fields entered by the user
    var handleChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        SelectAll(false);
        toggleCheckbox(parseInt(name, 10));
        if (selectedData) {
            var isValuePresent = selectedData.length > 0 && selectedData.includes(value);
            var updatedVal = void 0;
            if (isValuePresent) {
                // If the value is present, remove it
                updatedVal = selectedData.filter(function (entity) { return entity !== value; });
            }
            else {
                // If the value is not present, add it
                updatedVal = __spreadArray(__spreadArray([], selectedData, true), [value], false);
            }
            setSelectedData(updatedVal);
            handleSelectedData === null || handleSelectedData === void 0 ? void 0 : handleSelectedData(updatedVal);
        }
    };
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: "card-background" }, { children: _jsxs("table", { children: [_jsx("tr", __assign({ className: "table-header" }, { children: props.headers.map(function (header, index) { return (_jsxs("th", __assign({ className: "align-".concat(index === props.headers.length - 1 ? "right" : "left") }, { children: [index === 0 && props.multiSelect ? (_jsx(Input, { type: "checkbox", className: "select-input", name: "list", onChange: selectAllRows, onInputChange: function () { }, isChecked: isSelectedAll })) : (""), index === props.headers.length - 1 && props.showSearchBar ? (_jsx("input", { type: "text", placeholder: "Search here", value: searchTerm, onChange: function (e) { return handleSearch(e.target.value); }, className: "!w-full bg-red-50" })) : (header)] }), index)); }) })), rows &&
                            rows.map(function (row, rowIndex) {
                                var hasLink = row.some(function (cell) { return typeof cell === "object"; });
                                var hasMultipleLinks = row.filter(function (cell) { return typeof cell === "object"; }).length > 1;
                                return (_jsxs("tr", __assign({ className: "".concat(hasMultipleLinks
                                        ? ""
                                        : props.multiSelect
                                            ? ""
                                            : hasLink
                                                ? "with-link"
                                                : "", " ").concat(props.multiSelect
                                        ? ""
                                        : props.multiSelect
                                            ? ""
                                            : hasLink
                                                ? "clickable-row"
                                                : ""), onClick: function () {
                                        if (!props.multiSelect && !hasMultipleLinks && hasLink) {
                                            handleRowClick(row.find(function (cell) { return typeof cell === "object"; }));
                                        }
                                    } }, { children: [
                                        /* {props.multiSelect ? (
                                      <td className="align-left">
                                        <Input
                                          type="checkbox"
                                          className="select-input"
                                          name={`${row}`}
                                          value={`${row[1]}`}
                                          onChange={handleChange}
                                          onInputChange={() => {}}
                                        />
                                      </td>
                                    ) : (
                                      ""
                                    )} */ "", row.map(function (cell, cellIndex) { return (_jsx("td", __assign({ className: "align-".concat(cellIndex === row.length - 1 ? "right" : "left") }, { children: cell == null ? (_jsx(Shimmer, { className: "w-full h-4 rounded-3xl my-4" })) : typeof cell === "string" ? (cell // Render regular string data
                                            ) : typeof cell === "boolean" ? (_jsx(Input, { type: "checkbox", className: "select-input", name: rowIndex, value: "".concat(row[1]), onChange: handleChange, onInputChange: function () { }, isChecked: cell })) : cell && cell.type === "button" ? (_jsx(Button, __assign({ onClick: function () {
                                                    return navigate(cell.link ? cell.link : "", cell.state && cell.state);
                                                }, className: "adviser-button ".concat(cell.classname ? cell.classname : "") }, { children: cell.text }))) : cell.type === "toggle" ? (_jsx(Button, __assign({ onClick: props.handleClick, className: "adviser-button ".concat(cell.classname ? cell.classname : "") }, { children: cell.text }))) : (_jsx("p", __assign({ onClick: function () {
                                                    return navigate(cell.link ? cell.link : "", cell.state && cell.state);
                                                }, style: { cursor: "pointer" } }, { children: cell.text }))) }), cellIndex)); })] }), rowIndex));
                            }), emptyRows.map(function (index) { return (_jsxs("tr", { children: [props.headers.map(function (_, cellIndex) { return (_jsx("td", {}, cellIndex) // Render an empty cell for each header
                                ); }), props.headers.length !== 0 &&
                                    props.data[0] &&
                                    props.headers.length < props.data[0].length &&
                                    // If headers exist and the number of headers is less than the number of cells in the row
                                    Array.from({ length: props.data[0].length - props.headers.length }, // Calculate the number of missing cells
                                    function (_, i) { return (_jsx("td", {}, "empty-cell-".concat(i + props.headers.length))); } // Render empty cells for missing columns
                                    )] }, "empty-".concat(index))); })] }) })), _jsxs("div", __assign({ className: "pagination-card" }, { children: [_jsx("div", __assign({ className: "pagination-info" }, { children: "Showing ".concat(startIndex + 1, " to ").concat(endEntry, " of ").concat(totalRows, " entries") })), _jsxs("div", __assign({ className: "pagination-controls" }, { children: [_jsx("button", __assign({ onClick: function () { return handlePageChange(currentPage - 1); }, disabled: currentPage === 1 }, { children: "<" })), _jsx("div", __assign({ className: "page-numbers" }, { children: generatePageNumbers().map(function (pageNumber) { return (_jsx("span", __assign({ className: pageNumber === currentPage ? "current-page" : "", onClick: function () { return handlePageChange(pageNumber); } }, { children: formatNumber(pageNumber) }), pageNumber)); }) })), _jsx("button", __assign({ onClick: function () { return handlePageChange(currentPage + 1); }, disabled: currentPage === totalPages }, { children: ">" }))] }))] }))] }));
};
TableCard.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};
export default TableCard;
