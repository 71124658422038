export var ButtonType;
(function (ButtonType) {
    ButtonType[ButtonType["Primary"] = 0] = "Primary";
    ButtonType[ButtonType["Secondary"] = 1] = "Secondary";
    ButtonType[ButtonType["Important"] = 2] = "Important";
    ButtonType[ButtonType["Outlined"] = 3] = "Outlined";
    ButtonType[ButtonType["OutlinedPrimary"] = 4] = "OutlinedPrimary";
    ButtonType[ButtonType["OutlinedSecondary"] = 5] = "OutlinedSecondary";
    ButtonType[ButtonType["Tertiary"] = 6] = "Tertiary";
    ButtonType[ButtonType["Filled"] = 7] = "Filled";
    ButtonType[ButtonType["FilledBlack"] = 8] = "FilledBlack";
})(ButtonType || (ButtonType = {}));
