import { Dashboard } from "../constants/dashboard";
export var getStatsTileData = function (username) {
    return {
        type: Dashboard.Stats_Tiles,
        username: username
    };
};
export var getQuesAttemptDetails = function (username) {
    return {
        type: Dashboard.Ques_Attempt_Details,
        username: username
    };
};
export var AdviserInfoAction = function () {
    return {
        type: Dashboard.Adviser_Info,
    };
};
export var updateNeedsQuestionnaire = function (needsQuestionnaire) {
    return {
        type: Dashboard.Update_Needs_Questionnaire,
        payload: needsQuestionnaire,
    };
};
export var makeQues2Ready = function (data, toggle) {
    return {
        type: Dashboard.Update_Ques_Attempt_Details,
        data: data,
        toggle: toggle,
    };
};
