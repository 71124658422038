export var Profile;
(function (Profile) {
    Profile["Profile"] = "Profile/Loading";
    Profile["Profile_Empty"] = "Profile/Empty";
    Profile["Profile_Successful"] = "Profile/Success";
    Profile["Profile_Error"] = "Profile/Error";
    Profile["Update_Profile"] = "UpdateProfile/Loading";
    Profile["Update_Profile_Successful"] = "UpdateProfile/Success";
    Profile["Update_Profile_Error"] = "UpdateProfile/Error";
})(Profile || (Profile = {}));
