// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-page-template-right {
  width: calc(100% - 22.9375rem);
  height: 100%;
  margin-left: 22.9375rem;
  background-color: var(--background-color-darker);
  padding: 1.5rem 2.62rem;
  min-height: 100vh;
}

.dashboard {
  height: 100%;
}

.graph-area-left {
  background-color: white;
  width: 57%;
  flex-shrink: 0;
  border-radius: 1.875rem;
  background: #FFF;
  margin-right: 2.5rem;
  padding: 2.5rem;
}

.graph-area-right {
  width: 40%;
  height: 32rem;
  flex-shrink: 0;
  border-radius: 1.875rem;
  background: #FFF;
  padding: 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/dashboard.css"],"names":[],"mappings":"AAGA;EACE,8BAA8B;EAC9B,YAAY;EACZ,uBAAuB;EACvB,gDAAgD;EAChD,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,UAAU;EACV,cAAc;EACd,uBAAuB;EACvB,gBAAgB;EAChB,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":["@import \"../../styles/global.css\";\n\n\n.dashboard-page-template-right {\n  width: calc(100% - 22.9375rem);\n  height: 100%;\n  margin-left: 22.9375rem;\n  background-color: var(--background-color-darker);\n  padding: 1.5rem 2.62rem;\n  min-height: 100vh;\n}\n\n.dashboard {\n  height: 100%;\n}\n\n.graph-area-left {\n  background-color: white;\n  width: 57%;\n  flex-shrink: 0;\n  border-radius: 1.875rem;\n  background: #FFF;\n  margin-right: 2.5rem;\n  padding: 2.5rem;\n}\n\n.graph-area-right {\n  width: 40%;\n  height: 32rem;\n  flex-shrink: 0;\n  border-radius: 1.875rem;\n  background: #FFF;\n  padding: 2.5rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
