var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../../styles/main.css";
import "../../styles/global.css";
import "./statsTile.css";
import { Status } from "../../../redux/constants/status";
import Shimmer from "../shimmer/shimmer";
import RightArrow from "../rightArrow/rightArrow";
import { useNavigate } from "react-router";
function constructStatsBody(score, points, icon) {
    return (_jsxs("div", __assign({ className: "flex flex-row h-full justify-between" }, { children: [!score || (score <= 3 && points != undefined) ? (_jsx("ul", __assign({ className: "h-full flex flex-col justify-evenly w-2/3" }, { children: points === null || points === void 0 ? void 0 : points.slice(0, 3).map(function (point, index) {
                    return (_jsx("li", __assign({ className: "stat-points list-disc list-inside stat-li" }, { children: _jsx("a", { children: point }) }), index));
                }) }))) : (_jsx("p", __assign({ className: "stat" }, { children: score.toFixed(2) }))), icon && _jsx("img", { src: icon, alt: "stats-tile-icon" })] })));
}
/**
 * The Stats Tile
 * This represents an individual block of Statistics typically seen in the dashboard.
 * It will contain the title, link, linkTitle and/or score/points
 * Score is the actual number whereas points are individual explanations of each points.
 * An example could be: Attended 3 Interviews in the last 3 months
 *
 * This Component will render the individual points if the score is less than or equal to 3.
 * Should the score be less than or equal to 3 but there are no points provided, it will render the Number instead.
 */
export default function StatsTile(props) {
    var navigate = useNavigate();
    var body;
    var title;
    var link;
    switch (props.status) {
        case Status.Successful:
            // This can be handled via Status.Empty
            // but that'll require us to change every switch case
            if (!props.title) {
                // There is no data.
                body = (_jsx("div", __assign({ className: "flex flex-row h-full justify-between" }, { children: "No Data Available" })));
                break;
            }
            title = _jsx("h4", { children: props.title });
            body = constructStatsBody(props.score, props.points, props.icon);
            link =
                props.title == "Average Caseload Employability Score" ? (props.userType == "jobseeker" ? (_jsxs("a", __assign({ onClick: function () {
                        navigate(props.link);
                    }, className: "cursor-pointer flex flex-row stat-text-bottom stat-tile-transition" }, { children: [_jsx("p", __assign({ className: "stat-link" }, { children: props.linkTitle })), _jsx(RightArrow, {})] }))) : ("")) : (_jsxs("a", __assign({ onClick: function () {
                        navigate(props.link);
                    }, className: "cursor-pointer flex flex-row stat-text-bottom stat-tile-transition" }, { children: [_jsx("p", __assign({ className: "stat-link" }, { children: props.linkTitle })), _jsx(RightArrow, {})] })));
            break;
        default:
            title = (_jsx("div", __assign({ className: "h-8 pb-2 w-full" }, { children: _jsx(Shimmer, { className: "w-2/3 rounded" }) })));
            body = _jsx(Shimmer, { className: "rounded-xl" });
            link = (_jsx("div", __assign({ className: "h-8 my-2 w-full" }, { children: _jsx(Shimmer, { className: "w-1/2 rounded" }) })));
            break;
    }
    return (_jsxs("div", __assign({ className: "stats-tile flex flex-col" }, { children: [title, body, link] })));
}
