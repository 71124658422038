import { Profile } from "../constants/profile";
export function getProfileData() {
    return {
        type: Profile.Profile
    };
}
export function updateProfileData(payload) {
    return {
        type: Profile.Update_Profile,
        payload: payload
    };
}
