// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-width {
  width: 50%;
}

.image-container {
  position: relative;
  
}

.image-container img {
  width: 100%;
  /* height: 100%; */
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(165.52deg, #a1b6ff00 -4.31%, #5148fa 90.88%);
  pointer-events: none; /* Ensures the overlay doesn't interfere with clicks or other events */
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/leftCoverImage/leftCoverImage.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;;AAEpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,oBAAiB;KAAjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,wEAAwE;EACxE,oBAAoB,EAAE,sEAAsE;AAC9F","sourcesContent":[".left-width {\n  width: 50%;\n}\n\n.image-container {\n  position: relative;\n  \n}\n\n.image-container img {\n  width: 100%;\n  /* height: 100%; */\n  object-fit: cover;\n  display: block;\n}\n\n.gradient-overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: linear-gradient(165.52deg, #a1b6ff00 -4.31%, #5148fa 90.88%);\n  pointer-events: none; /* Ensures the overlay doesn't interfere with clicks or other events */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
