var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router";
import LeftArrow from "../leftArrow/leftArrow";
import RightArrow from "../rightArrow/rightArrow";
import { ArrowType } from "./arrowType";
import "./card.css";
/**
 * This Card component creates a block of the background color (lighter) with rounded edges and padding.
 * All children are displayed on the center.
 *
 * You will typically use this Component to create such blocks that may just display some information or a
 * link that directs the users somewhere else.
 *
 * An example functionality of this would be to display the current date for information
 * And the obligations card that directs the users elswwhere.
 * Both can be found in the dashboard page.
 *
 * You may choose to include a right arrow at the end of the cildren (RHS) to further indicate if it's a link.
 */
export default function Card(props) {
    var navigate = useNavigate();
    var className = props.multiLine ? "card-large" : "card-text";
    return (_jsx("div", __assign({ onClick: function () {
            if (props.link) {
                navigate(props.link);
            }
        }, className: "card ".concat(props.transarent && "bg-transparent", " flex justify-center ").concat(className, " ").concat(props.className, " ").concat(props.link && "cursor-pointer") }, { children: _jsxs("div", __assign({ className: "align-middle m-auto w-full ".concat(props.multiLine ? "" : "flex", " ").concat(props.scrollable && "card-scroll") }, { children: [props.arrowType == ArrowType.Left && _jsx(LeftArrow, {}), props.children, props.arrowType == ArrowType.Right || props.arrow && _jsx(RightArrow, {})] })) })));
}
