var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { takeEvery, call, put, getContext } from "redux-saga/effects";
import Cookies from "js-cookie";
import { Authentication } from "../constants/authentication";
/**
 * The Login Generator function
 * This function is responsible for handling login service calls.
 * @param props
 */
function loginUserSaga(action) {
    var csrftoken, setCredentials, response, access, refresh, user_type, username, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                csrftoken = Cookies.get("csrftoken");
                return [4 /*yield*/, getContext("authService")];
            case 1:
                setCredentials = (_a.sent()).setCredentials;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return axios
                            .post("/api/token/", action.payload, {
                            headers: {
                                "X-CSRFToken": csrftoken,
                            },
                        })
                            .then(function (resp) { return resp.data; })
                            .catch(function (err) {
                            if (err.response.status === 403) {
                                return {
                                    error: "Maximum amount of failed login attempts reached. Please try again in an hour.*",
                                };
                            }
                            else {
                                return { error: "Invalid Credentials*" };
                            }
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!response.error) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Authentication.LOGIN_USER_FAILURE,
                        payload: response.error,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                access = response.access, refresh = response.refresh, user_type = response.user_type, username = response.username;
                // Example: Storing tokens in local storage
                localStorage.setItem("accessToken", access);
                localStorage.setItem("refreshToken", refresh);
                localStorage.setItem("userType", user_type);
                // Inject the service with the token, username and password.
                // This could handle the entire login flow but that seemed unnecessary.
                setCredentials(access, refresh);
                // Dispatch a success action
                return [4 /*yield*/, put({
                        type: Authentication.LOGIN_USER_SUCCESS,
                        payload: access,
                        email: action.payload.username,
                        role: user_type,
                        userType: user_type,
                        username: username,
                    })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_1 = _a.sent();
                return [4 /*yield*/, put({
                        type: Authentication.LOGIN_USER_FAILURE,
                        payload: "Error occurred during login",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Reset Password Generator function
 * This function is responsible for handling password reset service calls.
 * @param props
 */
function resetPasswordSaga(action) {
    var csrftoken, callAPI, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                csrftoken = Cookies.get("csrftoken");
                return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 9]);
                return [4 /*yield*/, call(function () {
                        return axios
                            .post("/api/api_password_reset/", action.payload, {
                            headers: {
                                "X-CSRFToken": csrftoken,
                            },
                        })
                            .then(function (resp) {
                            return resp.data.message;
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response == "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Authentication.RESET_PASSWORD_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Authentication.RESET_PASSWORD_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 9];
            case 8:
                error_2 = _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function resetPasswordConfirmSaga(action) {
    var response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(function () {
                        return axios.post("/api/api_password_reset/confirm/", action.payload, {
                            headers: { "X-CSRFToken": Cookies.get("csrftoken") },
                        });
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, put({
                        type: Authentication.RESET_PASSWORD_CONFIRM_SUCCESS,
                        payload: response.data.message,
                    })];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_3 = _a.sent();
                return [4 /*yield*/, put({
                        type: Authentication.RESET_PASSWORD_CONFIRM_FAILURE,
                        payload: "Failed to reset password. Please try again.",
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function registerUser(action) {
    var token, pass, user, response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                token = action.token;
                pass = action.password;
                user = action.username;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 6]);
                return [4 /*yield*/, call(function () {
                        return axios.post("/api/register_user/".concat(token, "/"), {
                            username: user,
                            password: pass,
                        });
                    })];
            case 2:
                response = _a.sent();
                if (!response) return [3 /*break*/, 4];
                return [4 /*yield*/, put({
                        type: Authentication.REGISTER_USER_SUCCESS,
                    })];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 6];
            case 5:
                err_1 = _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function UserSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(Authentication.LOGIN_USER, loginUserSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(Authentication.RESET_PASSWORD, resetPasswordSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(Authentication.REGISTER_USER, registerUser)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(Authentication.RESET_PASSWORD_CONFIRM, resetPasswordConfirmSaga)];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default UserSaga;
