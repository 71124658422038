import { ManageUser } from "../constants/manage_users";
export var getCaseloads = function (username) {
    return {
        type: ManageUser.ManageUser,
        username: username,
    };
};
export var getCaseloadsDashboard = function (username) {
    return {
        type: ManageUser.ManageUserDashboard,
        username: username,
    };
};
export var exportUsersData = function (usersList) {
    return {
        type: ManageUser.ManageExportUsers,
        payload: usersList,
    };
};
export var archiveUsersData = function (usersList, username) {
    return {
        type: ManageUser.ManageArchiveUsers,
        payload: usersList,
        username: username,
    };
};
export var createNewUser = function (hierarchy, email) {
    return {
        type: ManageUser.ManageCreateUser,
        hierarchy: hierarchy,
        email: email
    };
};
