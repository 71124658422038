var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import QuestionnaireOrg from "../../../organisms/jsQuestionnaire/jsQuestionnaire";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { KnowledgeAction, KnowledgeFormAction, } from "../../../../redux/actions/job_seeker_questionnaire";
/**
 * The Knowledge Questionnaire Page
 * This will need to call all relevant APIs to get the values.
 * @param props
 * @returns
 */
function KnowledgeQuestionnaire() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _a = useState(''), error = _a[0], setError = _a[1];
    useEffect(function () {
        dispatch(KnowledgeAction());
    }, [dispatch]);
    //using selector hook to fetch data from the reducer
    var _b = useSelector(function (state) { return state.rootReducer.JobSeekerQuestionnaireReducer; }), knowledeData = _b.knowledeData, error_knowledge = _b.error_knowledge, loading = _b.loading, knowledeFormData = _b.knowledeFormData, success_knowlede_form = _b.success_knowlede_form;
    var loginData = useSelector(function (state) { return state.rootReducer.userReducer; }).loginData;
    //function to submit the input user data
    var handleFormSubmit = function (formData) {
        var sanitizedData = Object.keys(formData).reduce(function (acc, key) {
            acc[key] = DOMPurify.sanitize(formData[key]);
            return acc;
        }, {});
        dispatch(KnowledgeFormAction(sanitizedData, loginData));
    };
    var handleBackBtn = function () {
        navigate("/questionnaire/antecedents");
    };
    if (success_knowlede_form) {
        navigate("/questionnaire/generalSkills");
    }
    return (_jsxs("div", __assign({ className: "" }, { children: [error && _jsx("div", __assign({ className: "error-message" }, { children: error })), _jsx(QuestionnaireOrg, { title: "Searching for Work", content: "The next few questions will ask about your current job search activity.", data: knowledeData, responses: knowledeFormData, handleSubmit: handleFormSubmit, handleBackBtn: handleBackBtn, classname: "knowledge-w", width: "14%" })] })));
}
export default KnowledgeQuestionnaire;
