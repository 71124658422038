import { Questionnaire } from "../constants/job_seeker_questionnaire";
/**
 * The Antecedents Action
 * This action is called when user successfully extracts the Antecedents questionnaires.
 * @param props
 */
export var AntecedentsAction = function () {
    return {
        type: Questionnaire.ANTECEDENTS,
    };
};
export var AntecedentsFormAction = function (antecedentsFormData, token) {
    return {
        type: Questionnaire.ANTECEDENTS_FORM,
        payload: antecedentsFormData,
        token: token,
    };
};
/**
 * The Knowledge Action
 * This action is called when user successfully extracts the Knowledge questionnaires.
 * @param props
 */
export var KnowledgeAction = function () {
    return {
        type: Questionnaire.KNOWLEDGE,
    };
};
export var KnowledgeFormAction = function (knowledgeFormData, token) {
    return {
        type: Questionnaire.KNOWLEDGE_FORM,
        payload: knowledgeFormData,
        token: token,
    };
};
/**
 * The General Skills Action
 * This action is called when user successfully extracts the General Skills questionnaires.
 * @param props
 */
export var GeneralSkillsAction = function () {
    return {
        type: Questionnaire.GENERAL_SKILLS,
    };
};
export var GeneralSkillsFormAction = function (generalSkillsFormData, token) {
    return {
        type: Questionnaire.GENERAL_SKILLS_FORM,
        payload: generalSkillsFormData,
        token: token,
    };
};
/**
 * The Confidence Action
 * This action is called when user successfully extracts the Confidence questionnaires.
 * @param props
 */
export var ConfidenceAction = function () {
    return {
        type: Questionnaire.CONFIDENCE,
    };
};
export var ConfidenceFormAction = function (confidenceFormData, token) {
    return {
        type: Questionnaire.CONFIDENCE_FORM,
        payload: confidenceFormData,
        token: token,
    };
};
/**
 * The Attributes Action
 * This action is called when user successfully extracts the Attributes questionnaires.
 * @param props
 */
export var AttributesAction = function () {
    return {
        type: Questionnaire.ATTRIBUTES,
    };
};
export var AttributesFormAction = function (attributesFormData, token) {
    return {
        type: Questionnaire.ATTRIBUTES_FORM,
        payload: attributesFormData,
        token: token,
    };
};
/**
 * The Qualifications Action
 * This action is called when user successfully extracts the Qualifications questionnaires.
 * @param props
 */
export var QualificationsAction = function () {
    return {
        type: Questionnaire.QUALIFICATIONS,
    };
};
export var QualificationsFormAction = function (qualificationsFormData, token) {
    return {
        type: Questionnaire.QUALIFICATIONS_FORM,
        payload: qualificationsFormData,
        token: token,
    };
};
/**
 * The Social Action
 * This action is called when user successfully extracts the Social questionnaires.
 * @param props
 */
export var SocialAction = function () {
    return {
        type: Questionnaire.SOCIAL,
    };
};
export var SocialFormAction = function (socialFormData, token) {
    return {
        type: Questionnaire.SOCIAL_FORM,
        payload: socialFormData,
        token: token,
    };
};
/**
 * The Progression Action
 * This action is called when user successfully extracts the Progression questionnaires.
 * @param props
 */
export var ProgressionAction = function () {
    return {
        type: Questionnaire.PROGRESSION,
    };
};
export var ProgressionFormAction = function (progressionFormData, token) {
    return {
        type: Questionnaire.PROGRESSION_FORM,
        payload: progressionFormData,
        token: token,
    };
};
/**
 * The Global Action
 * This action is called when user successfully extracts the Global questionnaires.
 * @param props
 */
export var GlobalAction = function () {
    return {
        type: Questionnaire.GLOBAL,
    };
};
export var GlobalFormAction = function (globalFormData, token) {
    return {
        type: Questionnaire.GLOBAL_FORM,
        payload: globalFormData,
        token: token,
    };
};
/**
 * The Client Obligations Action
 * This action is called when user successfully extracts the Client Obligations questionnaires.
 * @param props
 */
export var ClientObligationsAction = function () {
    return {
        type: Questionnaire.CLIENT_OBLIGATIONS,
    };
};
export var ClientObligationsFormAction = function (clientObligationsFormData, token) {
    return {
        type: Questionnaire.CLIENT_OBLIGATIONS_FORM,
        payload: clientObligationsFormData,
        token: token,
    };
};
/**
 * The Adviser Obligations Action
 * This action is called when user successfully extracts the Adviser Obligations questionnaires.
 * @param props
 */
export var AdviserObligationsAction = function () {
    return {
        type: Questionnaire.ADVISER_OBLIGATIONS,
    };
};
export var AdviserObligationsFormAction = function (adviserObligationsFormData, token) {
    return {
        type: Questionnaire.ADVISER_OBLIGATIONS_FORM,
        payload: adviserObligationsFormData,
        token: token,
    };
};
/**
 * The Adviser Feedback Action
 * This action is called when user successfully extracts the Adviser Feedback questionnaires.
 * @param props
 */
export var AdviserFeedbackAction = function () {
    return {
        type: Questionnaire.ADVISER_FEEDBACK,
    };
};
export var AdviserFeedbackFormAction = function (adviserFeedbackFormData, token) {
    return {
        type: Questionnaire.ADVISER_FEEDBACK_FORM,
        payload: adviserFeedbackFormData,
        token: token,
    };
};
/**
 * The Adviser Impact Action
 * This action is called when user successfully extracts the Adviser Impact questionnaires.
 * @param props
 */
export var AdviserImpactAction = function () {
    return {
        type: Questionnaire.ADVISER_IMPACT,
    };
};
export var AdviserImpactFormAction = function (adviserImpactFormData, token) {
    return {
        type: Questionnaire.ADVISER_IMPACT_FORM,
        payload: adviserImpactFormData,
        token: token,
    };
};
/**
 * The Overall Experience Action
 * This action is called when user successfully extracts the Overall Experience questionnaires.
 * @param props
 */
export var OverallExperienceAction = function () {
    return {
        type: Questionnaire.OVERALL_EXPERIENCE,
    };
};
export var OverallExperienceFormAction = function (overallExperienceFormData, token) {
    return {
        type: Questionnaire.OVERALL_EXPERIENCE_FORM,
        payload: overallExperienceFormData,
        token: token,
    };
};
