var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeEvery, call, put, getContext } from "redux-saga/effects";
import store from "../store";
import { QuestionnaireHistory } from "../constants/questionnaireHistoryConstants";
import { APIType } from "../services/authenticationService";
function getQuestionnaireHistory(actions) {
    var username, callAPI, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                username = actions.username;
                return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 6, , 7]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "api/router/submissions/by_username/?username=".concat(username));
                    })];
            case 3:
                response = _a.sent();
                if (!response) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: QuestionnaireHistory.HISTORY_SUCESSFUL,
                        payload: response.data,
                    })];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 7];
            case 6:
                error_1 = _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getQuestionnaireOneHistory(action) {
    var attempts, callAPI, response, error_2, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                attempts = store.getState().rootReducer.questionnaireHistoryReducer.numberOfAttempts;
                return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                if (!(attempts === 1)) return [3 /*break*/, 8];
                _a.label = 2;
            case 2:
                _a.trys.push([2, 6, , 7]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/get_fields_for_user/", { params: { user: action.username } });
                    })];
            case 3:
                response = _a.sent();
                if (!response) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: QuestionnaireHistory.RESPONSE_HISTORY_1_SUCESSFUL,
                        payload: response.data,
                    })];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 7];
            case 6:
                error_2 = _a.sent();
                return [3 /*break*/, 7];
            case 7: return [3 /*break*/, 14];
            case 8:
                if (!(attempts === 2)) return [3 /*break*/, 14];
                _a.label = 9;
            case 9:
                _a.trys.push([9, 13, , 14]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/get_old_fields_for_user/", { params: { user: action.username } });
                    })];
            case 10:
                response = _a.sent();
                if (!response) return [3 /*break*/, 12];
                return [4 /*yield*/, put({
                        type: QuestionnaireHistory.RESPONSE_HISTORY_1_SUCESSFUL,
                        payload: response.data,
                    })];
            case 11:
                _a.sent();
                _a.label = 12;
            case 12: return [3 /*break*/, 14];
            case 13:
                error_3 = _a.sent();
                return [3 /*break*/, 14];
            case 14: return [2 /*return*/];
        }
    });
}
function getQuestionnaireTwoHistory(action) {
    var callAPI, response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 6, , 7]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/get_fields_for_user/", { params: { user: action.username, } });
                    })];
            case 3:
                response = _a.sent();
                if (!response) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: QuestionnaireHistory.RESPONSE_HISTORY_1_SUCESSFUL,
                        payload: response.data,
                    })];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 7];
            case 6:
                error_4 = _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export default function QuestionnaireHistorySaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(QuestionnaireHistory.HISTORY, getQuestionnaireHistory)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(QuestionnaireHistory.RESPONSE_HISTORY_1, getQuestionnaireOneHistory)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(QuestionnaireHistory.RESPONSE_HISTORY_2, getQuestionnaireTwoHistory)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
