var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Questionnaire } from "../constants/job_seeker_questionnaire";
import { Status } from "../constants/status";
var initialState = {
    antecedentsData: [],
    error_antecedents: null,
    antecedentsFormData: [],
    success_antecedents_form: null,
    error_antecedents_form: null,
    knowledeData: [],
    error_knowledge: null,
    knowledeFormData: [],
    success_knowlede_form: null,
    error_knowlede_form: null,
    generalSkillsData: [],
    error_generalSkills: null,
    generalSkillsFormData: [],
    success_generalSkills_form: null,
    error_generalSkills_form: null,
    confidenceData: [],
    error_confidence: null,
    confidenceFormData: [],
    success_confidence_form: null,
    error_confidence_form: null,
    attributesData: [],
    error_attributes: null,
    attributesFormData: [],
    success_attributes_form: null,
    error_attributes_form: null,
    qualificationsData: [],
    error_qualifications: null,
    qualificationsFormData: [],
    success_qualifications_form: null,
    error_qualifications_form: null,
    socialData: [],
    error_social: null,
    socialFormData: [],
    success_social_form: null,
    error_social_form: null,
    progressionData: [],
    error_progression: null,
    progressionFormData: [],
    success_progression_form: null,
    error_progression_form: null,
    globalData: [],
    error_global: null,
    globalFormData: [],
    success_global_form: null,
    error_global_form: null,
    clientObligationsData: [],
    error_clientObligations: null,
    clientObligationsFormData: [],
    success_clientObligations_form: null,
    error_clientObligations_form: null,
    adviserObligationsData: [],
    error_adviserObligations: null,
    adviserObligationsFormData: [],
    success_adviserObligations_form: null,
    error_adviserObligations_form: null,
    adviserFeedbackData: [],
    error_adviserFeedback: null,
    adviserFeedbackFormData: [],
    success_adviserFeedback_form: null,
    error_adviserFeedback_form: null,
    adviserImpactData: [],
    error_adviserImpact: null,
    adviserImpactFormData: [],
    success_adviserImpact_form: null,
    error_adviserImpact_form: null,
    overallExperienceData: [],
    error_overallExperience: null,
    overallExperienceFormData: [],
    success_overallExperience_form: null,
    error_overallExperience_form: null,
    status: Status.Loading,
};
/**
 * The Job Seeker Questionnaire Reducer
 * This reducer is responsible for handling the state and action management of the job seeker questionnaire requests.
 * @param props
 */
var JobSeekerQuestionnaireReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case Questionnaire.ANTECEDENTS:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ANTECEDENTS_SUCCESS:
            return __assign(__assign({}, state), { antecedentsData: action.payload, status: Status.Successful });
        case Questionnaire.ANTECEDENTS_FAILURE:
            return __assign(__assign({}, state), { error_antecedents: action.payload, status: Status.Error });
        case Questionnaire.ANTECEDENTS_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ANTECEDENTS_FORM_SUCCESS:
            return __assign(__assign({}, state), { antecedentsFormData: action.payload, success_antecedents_form: action.status, status: Status.Successful });
        case Questionnaire.ANTECEDENTS_FORM_FAILURE:
            return __assign(__assign({}, state), { error_antecedents_form: action.status, status: Status.Error });
        case Questionnaire.KNOWLEDGE:
            return __assign(__assign({}, state), { success_antecedents_form: null, status: Status.Loading });
        case Questionnaire.KNOWLEDGE_SUCCESS:
            return __assign(__assign({}, state), { knowledeData: action.payload, status: Status.Successful });
        case Questionnaire.KNOWLEDGE_FAILURE:
            return __assign(__assign({}, state), { error_knowledge: action.payload, status: Status.Error });
        case Questionnaire.KNOWLEDGE_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.KNOWLEDGE_FORM_SUCCESS:
            return __assign(__assign({}, state), { knowledeFormData: action.payload, success_knowlede_form: action.status, status: Status.Successful });
        case Questionnaire.KNOWLEDGE_FORM_FAILURE:
            return __assign(__assign({}, state), { error_knowlede_form: action.status, status: Status.Error });
        case Questionnaire.GENERAL_SKILLS:
            return __assign(__assign({}, state), { success_knowlede_form: null, status: Status.Loading });
        case Questionnaire.GENERAL_SKILLS_SUCCESS:
            return __assign(__assign({}, state), { generalSkillsData: action.payload, status: Status.Successful });
        case Questionnaire.GENERAL_SKILLS_FAILURE:
            return __assign(__assign({}, state), { error_generalSkills: action.payload, status: Status.Error });
        case Questionnaire.GENERAL_SKILLS_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.GENERAL_SKILLS_FORM_SUCCESS:
            return __assign(__assign({}, state), { generalSkillsFormData: action.payload, success_generalSkills_form: action.status, status: Status.Successful });
        case Questionnaire.GENERAL_SKILLS_FORM_FAILURE:
            return __assign(__assign({}, state), { error_generalSkills_form: action.status, status: Status.Error });
        case Questionnaire.CONFIDENCE:
            return __assign(__assign({}, state), { success_generalSkills_form: null, status: Status.Loading });
        case Questionnaire.CONFIDENCE_SUCCESS:
            return __assign(__assign({}, state), { confidenceData: action.payload, status: Status.Successful });
        case Questionnaire.CONFIDENCE_FAILURE:
            return __assign(__assign({}, state), { error_confidence: action.payload, status: Status.Error });
        case Questionnaire.CONFIDENCE_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.CONFIDENCE_FORM_SUCCESS:
            return __assign(__assign({}, state), { confidenceFormData: action.payload, success_confidence_form: action.status, status: Status.Successful });
        case Questionnaire.CONFIDENCE_FORM_FAILURE:
            return __assign(__assign({}, state), { error_confidence_form: action.status, status: Status.Error });
        case Questionnaire.ATTRIBUTES:
            return __assign(__assign({}, state), { success_confidence_form: null, status: Status.Loading });
        case Questionnaire.ATTRIBUTES_SUCCESS:
            return __assign(__assign({}, state), { attributesData: action.payload, status: Status.Successful });
        case Questionnaire.ATTRIBUTES_FAILURE:
            return __assign(__assign({}, state), { error_attributes: action.payload, status: Status.Error });
        case Questionnaire.ATTRIBUTES_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ATTRIBUTES_FORM_SUCCESS:
            return __assign(__assign({}, state), { attributesFormData: action.payload, success_attributes_form: action.status, status: Status.Successful });
        case Questionnaire.ATTRIBUTES_FORM_FAILURE:
            return __assign(__assign({}, state), { error_attributes_form: action.status, status: Status.Error });
        case Questionnaire.QUALIFICATIONS:
            return __assign(__assign({}, state), { success_attributes_form: null, status: Status.Loading });
        case Questionnaire.QUALIFICATIONS_SUCCESS:
            return __assign(__assign({}, state), { qualificationsData: action.payload, status: Status.Successful });
        case Questionnaire.QUALIFICATIONS_FAILURE:
            return __assign(__assign({}, state), { error_qualifications: action.payload, status: Status.Error });
        case Questionnaire.QUALIFICATIONS_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.QUALIFICATIONS_FORM_SUCCESS:
            return __assign(__assign({}, state), { qualificationsFormData: action.payload, success_qualifications_form: action.status, status: Status.Successful });
        case Questionnaire.QUALIFICATIONS_FORM_FAILURE:
            return __assign(__assign({}, state), { error_qualifications_form: action.status, status: Status.Error });
        case Questionnaire.SOCIAL:
            return __assign(__assign({}, state), { success_qualifications_form: null, status: Status.Loading });
        case Questionnaire.SOCIAL_SUCCESS:
            return __assign(__assign({}, state), { socialData: action.payload, status: Status.Successful });
        case Questionnaire.SOCIAL_FAILURE:
            return __assign(__assign({}, state), { error_social: action.payload, status: Status.Error });
        case Questionnaire.SOCIAL_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.SOCIAL_FORM_SUCCESS:
            return __assign(__assign({}, state), { socialFormData: action.payload, success_social_form: action.status, status: Status.Successful });
        case Questionnaire.SOCIAL_FORM_FAILURE:
            return __assign(__assign({}, state), { error_social_form: action.status, status: Status.Error });
        case Questionnaire.PROGRESSION:
            return __assign(__assign({}, state), { success_social_form: null, status: Status.Loading });
        case Questionnaire.PROGRESSION_SUCCESS:
            return __assign(__assign({}, state), { progressionData: action.payload, status: Status.Successful });
        case Questionnaire.PROGRESSION_FAILURE:
            return __assign(__assign({}, state), { error_progression: action.payload, status: Status.Error });
        case Questionnaire.PROGRESSION_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.PROGRESSION_FORM_SUCCESS:
            return __assign(__assign({}, state), { progressionFormData: action.payload, success_progression_form: action.status, status: Status.Successful });
        case Questionnaire.PROGRESSION_FORM_FAILURE:
            return __assign(__assign({}, state), { error_progression_form: action.status, status: Status.Error });
        case Questionnaire.GLOBAL:
            return __assign(__assign({}, state), { success_progression_form: null, status: Status.Loading });
        case Questionnaire.GLOBAL_SUCCESS:
            return __assign(__assign({}, state), { globalData: action.payload, status: Status.Successful });
        case Questionnaire.GLOBAL_FAILURE:
            return __assign(__assign({}, state), { error_global: action.payload, status: Status.Error });
        case Questionnaire.GLOBAL_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.GLOBAL_FORM_SUCCESS:
            return __assign(__assign({}, state), { globalFormData: action.payload, success_global_form: action.status, status: Status.Successful });
        case Questionnaire.GLOBAL_FORM_FAILURE:
            return __assign(__assign({}, state), { error_global_form: action.status, status: Status.Error });
        case Questionnaire.CLIENT_OBLIGATIONS:
            return __assign(__assign({}, state), { success_global_form: null, status: Status.Loading });
        case Questionnaire.CLIENT_OBLIGATIONS_SUCCESS:
            return __assign(__assign({}, state), { clientObligationsData: action.payload, status: Status.Successful });
        case Questionnaire.CLIENT_OBLIGATIONS_FAILURE:
            return __assign(__assign({}, state), { error_clientObligations: action.payload, status: Status.Error });
        case Questionnaire.CLIENT_OBLIGATIONS_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.CLIENT_OBLIGATIONS_FORM_SUCCESS:
            return __assign(__assign({}, state), { clientObligationsFormData: action.payload, success_clientObligations_form: action.status, status: Status.Successful });
        case Questionnaire.CLIENT_OBLIGATIONS_FORM_FAILURE:
            return __assign(__assign({}, state), { error_clientObligations_form: action.status, status: Status.Error });
        case Questionnaire.ADVISER_OBLIGATIONS:
            return __assign(__assign({}, state), { success_clientObligations_form: null, status: Status.Loading });
        case Questionnaire.ADVISER_OBLIGATIONS_SUCCESS:
            return __assign(__assign({}, state), { adviserObligationsData: action.payload, status: Status.Successful });
        case Questionnaire.ADVISER_OBLIGATIONS_FAILURE:
            return __assign(__assign({}, state), { error_adviserObligations: action.payload, status: Status.Error });
        case Questionnaire.ADVISER_OBLIGATIONS_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_OBLIGATIONS_FORM_SUCCESS:
            return __assign(__assign({}, state), { adviserObligationsFormData: action.payload, success_adviserObligations_form: action.status, status: Status.Successful });
        case Questionnaire.ADVISER_OBLIGATIONS_FORM_FAILURE:
            return __assign(__assign({}, state), { error_adviserObligations_form: action.status, status: Status.Error });
        case Questionnaire.ADVISER_FEEDBACK:
            return __assign(__assign({}, state), { success_adviserObligations_form: null, status: Status.Loading });
        case Questionnaire.ADVISER_FEEDBACK_SUCCESS:
            return __assign(__assign({}, state), { adviserFeedbackData: action.payload, status: Status.Successful });
        case Questionnaire.ADVISER_FEEDBACK_FAILURE:
            return __assign(__assign({}, state), { error_adviserFeedback: action.payload, status: Status.Error });
        case Questionnaire.ADVISER_FEEDBACK_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_FEEDBACK_FORM_SUCCESS:
            return __assign(__assign({}, state), { adviserFeedbackFormData: action.payload, success_adviserFeedback_form: action.status, status: Status.Successful });
        case Questionnaire.ADVISER_FEEDBACK_FORM_FAILURE:
            return __assign(__assign({}, state), { error_adviserFeedback_form: action.status, status: Status.Error });
        case Questionnaire.ADVISER_IMPACT:
            return __assign(__assign({}, state), { success_adviserFeedback_form: null, status: Status.Loading });
        case Questionnaire.ADVISER_IMPACT_SUCCESS:
            return __assign(__assign({}, state), { adviserImpactData: action.payload, status: Status.Successful });
        case Questionnaire.ADVISER_IMPACT_FAILURE:
            return __assign(__assign({}, state), { error_adviserImpact: action.payload, status: Status.Error });
        case Questionnaire.ADVISER_IMPACT_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.ADVISER_IMPACT_FORM_SUCCESS:
            return __assign(__assign({}, state), { adviserImpactFormData: action.payload, success_adviserImpact_form: action.status, status: Status.Successful });
        case Questionnaire.ADVISER_IMPACT_FORM_FAILURE:
            return __assign(__assign({}, state), { error_adviserImpact_form: action.status, status: Status.Error });
        case Questionnaire.OVERALL_EXPERIENCE:
            return __assign(__assign({}, state), { success_adviserImpact_form: null, status: Status.Loading });
        case Questionnaire.OVERALL_EXPERIENCE_SUCCESS:
            return __assign(__assign({}, state), { overallExperienceData: action.payload, status: Status.Successful });
        case Questionnaire.OVERALL_EXPERIENCE_FAILURE:
            return __assign(__assign({}, state), { error_overallExperience: action.payload, status: Status.Error });
        case Questionnaire.OVERALL_EXPERIENCE_FORM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Questionnaire.OVERALL_EXPERIENCE_FORM_SUCCESS:
            return __assign(__assign({}, state), { overallExperienceFormData: action.payload, success_overallExperience_form: action.status, status: Status.Successful });
        case Questionnaire.OVERALL_EXPERIENCE_FORM_FAILURE:
            return __assign(__assign({}, state), { error_overallExperience_form: action.status, status: Status.Error });
        default:
            return state;
    }
};
export default JobSeekerQuestionnaireReducer;
