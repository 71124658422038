import { Authentication } from "../constants/authentication";
/**
 * The Login Action
 * This action is called when user successfully enter payload while logging in into the system.
 * @param props
 */
export var LoginAction = function (data) {
    return {
        type: Authentication.LOGIN_USER,
        payload: data,
    };
};
/**
 * The Reset Action
 * This action is called when user successfully enter payload to reset password while logging in into the system.
 * @param props
 */
export var ResetAction = function (data) {
    return {
        type: Authentication.RESET_PASSWORD,
        payload: data,
    };
};
export var ResetActionConfirm = function (data) {
    return {
        type: Authentication.RESET_PASSWORD_CONFIRM,
        payload: data,
    };
};
export var LogoutAction = function () {
    return {
        type: Authentication.LOGOUT_USER,
    };
};
export var RegisterUser = function (username, password, token) {
    return {
        type: Authentication.REGISTER_USER,
        username: username,
        password: password,
        token: token,
    };
};
