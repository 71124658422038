// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.popupModal {
  width: 35%;
  background: var(--background-color);
  padding: 4.06rem 4.12rem;
  border-radius: 2.3125rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/popupModal/popupModal.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,WAAW;EACX,YAAY;EACZ,WAAW;EACX,MAAM;EACN,OAAO;EACP,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,mCAAmC;EACnC,wBAAwB;EACxB,wBAAwB;AAC1B","sourcesContent":["@import \"../../styles/global.css\";\n\n.fade {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.5);\n  width: 100%;\n  height: 100%;\n  z-index: 10;\n  top: 0;\n  left: 0;\n  position: fixed;\n}\n\n.popupModal {\n  width: 35%;\n  background: var(--background-color);\n  padding: 4.06rem 4.12rem;\n  border-radius: 2.3125rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
