// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-right {
  display: flex;
  /* width: 0.9375rem; */
  /* height: 0.9375rem; */
  margin: 0.25rem 0 0.19rem 0.38rem;
  /* padding: 0.19531rem 0rem; */
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
  /* flex-shrink: 0; */
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/rightArrow/rightArrow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iCAAiC;EACjC,8BAA8B;EAC9B,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".arrow-right {\n  display: flex;\n  /* width: 0.9375rem; */\n  /* height: 0.9375rem; */\n  margin: 0.25rem 0 0.19rem 0.38rem;\n  /* padding: 0.19531rem 0rem; */\n  justify-content: center;\n  align-items: center;\n  padding: 0.2rem 0;\n  /* flex-shrink: 0; */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
