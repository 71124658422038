/**
 * Constant declaration for the redux store.
 */
export var Authentication;
(function (Authentication) {
    Authentication["LOGIN_USER"] = "LOGIN_USER";
    Authentication["LOGIN_USER_SUCCESS"] = "LOGIN_USER_SUCCESS";
    Authentication["LOGIN_USER_FAILURE"] = "LOGIN_USER_FAILURE";
    Authentication["RESET_PASSWORD"] = "RESET_PASSWORD";
    Authentication["RESET_PASSWORD_SUCCESS"] = "RESET_PASSWORD_SUCCESS";
    Authentication["RESET_PASSWORD_FAILURE"] = "RESET_PASSWORD_FAILURE";
    Authentication["RESET_PASSWORD_CONFIRM"] = "RESET_PASSWORD_CONFIRM";
    Authentication["RESET_PASSWORD_CONFIRM_SUCCESS"] = "RESET_PASSWORD_CONFIRM_SUCCESS";
    Authentication["RESET_PASSWORD_CONFIRM_FAILURE"] = "RESET_PASSWORD_CONFIRM_FAILURE";
    Authentication["LOGOUT_USER"] = "LOGOUT_USER";
    Authentication["REGISTER_USER"] = "REGISTER_USER";
    Authentication["REGISTER_USER_SUCCESS"] = "REGISTER_USER_SUCCESS";
})(Authentication || (Authentication = {}));
