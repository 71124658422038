var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../styles/main.css";
import "../styles/login.css";
/**
* The Login Template
* This template is entitled to all the authentication for providing its structure.
* The props accepted are image for the left side and form element for the right side of the page.
* @param props
* @returns
*/
function LoginTemplate(props) {
    return (_jsxs("div", __assign({ className: "h-full flex w-full" }, { children: [props.leftImage, _jsx("div", __assign({ className: "right-width form-content" }, { children: props.form }))] })));
}
export default LoginTemplate;
