var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import QuestionnaireOrg from "../../../organisms/jsQuestionnaire/jsQuestionnaire";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AntecedentsAction, AntecedentsFormAction, } from "../../../../redux/actions/job_seeker_questionnaire";
/**
 * The Antecedents Questionnaire Page
 * This will need to call all relevant APIs to get the values.
 * @param props
 * @returns
 */
function AntecedentsQuestionnaire() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _a = useState(''), error = _a[0], setError = _a[1];
    useEffect(function () {
        dispatch(AntecedentsAction());
    }, [dispatch]);
    //using selector hook to fetch data from the reducer
    var _b = useSelector(function (state) { return state.rootReducer.JobSeekerQuestionnaireReducer; }), antecedentsData = _b.antecedentsData, error_antecedents = _b.error_antecedents, loading = _b.loading, antecedentsFormData = _b.antecedentsFormData, success_antecedents_form = _b.success_antecedents_form;
    var loginData = useSelector(function (state) { return state.rootReducer.userReducer; }).loginData;
    //function to submit the input user data
    var handleFormSubmit = function (formData) {
        var sanitizedData = Object.keys(formData).reduce(function (acc, key) {
            acc[key] = DOMPurify.sanitize(formData[key]);
            return acc;
        }, {});
        dispatch(AntecedentsFormAction(sanitizedData, loginData));
    };
    var handleBackBtn = function () {
        navigate("/");
    };
    if (success_antecedents_form) {
        navigate("/questionnaire/knowledge");
    }
    return (_jsxs("div", __assign({ className: "" }, { children: [error && _jsx("div", __assign({ className: "error-message" }, { children: error })), _jsx(QuestionnaireOrg, { title: "Demographics", content: "For each of the following sections please tick the box that best represents your situation.", data: antecedentsData, responses: antecedentsFormData, handleSubmit: handleFormSubmit, handleBackBtn: handleBackBtn, classname: "antecedents-w", width: "7%" })] })));
}
export default AntecedentsQuestionnaire;
