var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getStatsTileData } from "../../../../redux/actions/dashboard";
import NavBar from "../../../organisms/navBar/navBar";
import "../strengths/strenghts.css";
import "../../../templates/general_page_template.css";
import Card from "../../../atoms/card/card";
import Shimmer from "../../../atoms/shimmer/shimmer";
import Button from "../../../atoms/button/button";
import { ButtonType } from "../../../atoms/button/buttonType";
import { ArrowType } from "../../../atoms/card/arrowType";
import PageHeader from "../../../organisms/pageHeader/pageHeader";
export default function Development() {
    var dispatch = useDispatch();
    var navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var userToken = useSelector(function (state) { return state.rootReducer.userReducer.loginData; });
    var username = useSelector(function (state) { return state.rootReducer.userReducer.username; });
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var viewing_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_user_type; });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var statsData = useSelector(function (state) { return state.rootReducer.dashboardReducer.stats; });
    var state = useLocation().state;
    if (!userToken) {
        // User has not logged in, no auth token
        // Redirect to Login
        useEffect(function () {
            navigate("/login");
        }, []);
    }
    else {
        // Now we know that the user is logged in. Perform the API calls
        useEffect(function () {
            if (statsData === undefined) {
                if (state && state.viewUser) {
                    dispatch(getStatsTileData(state.viewUser));
                }
                else {
                    dispatch(getStatsTileData(username));
                }
            }
        }, [state]);
    }
    var points = [];
    // TODO: MAKE IT GENERIC WITH STRENGHTS
    if (statsData !== undefined) {
        // DEVELOPMENT IS AT INDEX 2
        statsData[2].points.forEach(function (data, index) {
            points.push(_jsx("div", __assign({ className: "rounded-xl w-full px-6 py-5 font-medium ".concat(index % 2 == 0 && "grey-background") }, { children: data }), index));
        });
    }
    var viewing_username = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_username; });
    return (_jsxs("div", __assign({ className: "h-full" }, { children: [_jsx(NavBar, { viewing_user: viewing_user_type, logged_in_user: logged_in_user_type, viewing_username: viewing_username }), _jsxs("div", __assign({ className: "page-template-right" }, { children: [_jsx(PageHeader, __assign({ title: "Your Development Areas" }, { children: _jsx(Button, __assign({ className: "px-4 py-4 w-min mx-6", buttonType: ButtonType.Filled, onClick: function () {
                                navigate("/dashboard");
                            } }, { children: _jsx(Card, __assign({ arrowType: ArrowType.Left, transarent: true, className: "p-0 m-0" }, { children: _jsx("p", __assign({ className: "text-lg" }, { children: "Back to Dashboard" })) })) })) })), _jsx(Card, __assign({ multiLine: true }, { children: _jsx("div", __assign({ className: "flex flex-col h-full w-full" }, { children: statsData != undefined ? points : _jsx(Shimmer, {}) })) }))] }))] })));
}
