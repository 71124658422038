var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NavBar from "../../organisms/navBar/navBar";
import "../../templates/general_page_template.css";
import Card from "../../atoms/card/card";
import "../../styles/text.css";
import tas from "./jobSeekerTermsAndConditions.json";
import PageHeader from "../../organisms/pageHeader/pageHeader";
import { useSelector } from "react-redux";
export default function JobSeekerTermsAndConditions() {
    var data = [];
    for (var i = 0; i < tas.length; i += 2) {
        data.push(_jsxs("div", __assign({ className: "flex flex-col my-5" }, { children: [_jsx("p", __assign({ className: "text-text" }, { children: tas[i] })), _jsx("p", __assign({ className: "text-text" }, { children: tas[i + 1] }))] }), i));
    }
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var viewing_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_user_type; });
    var viewing_username = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_username; });
    return (_jsxs("div", __assign({ className: "h-full" }, { children: [_jsx(NavBar, { viewing_user: viewing_user_type, logged_in_user: logged_in_user_type, viewing_username: viewing_username }), _jsxs("div", __assign({ className: "page-template-right" }, { children: [_jsx(PageHeader, { title: "Terms & Conditions" }), _jsxs(Card, __assign({ scrollable: true, multiLine: true }, { children: [_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("p", __assign({ className: "text-title" }, { children: "MyEmploy Terms and Conditions" })), _jsx("p", __assign({ className: "text-subtitle" }, { children: "Effective Date 20 Jan 2024" }))] })), data] }))] }))] })));
}
