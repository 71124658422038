var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NavBar from "../../organisms/navBar/navBar";
import "../../templates/general_page_template.css";
import Card from "../../atoms/card/card";
import "../../styles/text.css";
import "./faq.css";
import faq from "./jobSeekerHelpAndSupport.json";
import PageHeader from "../../organisms/pageHeader/pageHeader";
import { useSelector } from "react-redux";
export default function JobSeekerHelpAndSupport() {
    var data = [];
    for (var i = 0, j = 0; i < faq.length; i += 2, j++) {
        data.push(_jsxs("li", __assign({ className: "list-item list-inside my-4" }, { children: [_jsx("p", __assign({ className: "text-text inline underline" }, { children: faq[i] })), _jsx("ul", __assign({ className: "list-item ml-4" }, { children: _jsx("li", __assign({ className: "list-item list-inside hollow" }, { children: _jsx("p", __assign({ className: "text-text inline" }, { children: faq[i + 1] })) })) }))] }), j));
    }
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var viewing_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_user_type; });
    var viewing_username = useSelector(function (state) { return state.rootReducer.dashboardReducer.viewing_username; });
    return (_jsxs("div", __assign({ className: "h-full" }, { children: [_jsx(NavBar, { viewing_user: viewing_user_type, logged_in_user: logged_in_user_type, viewing_username: viewing_username }), _jsxs("div", __assign({ className: "page-template-right" }, { children: [_jsx(PageHeader, { title: "Help & Support" }), _jsxs(Card, __assign({ scrollable: true, multiLine: true }, { children: [_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("p", __assign({ className: "text-title" }, { children: "FAQ" })), _jsx("p", __assign({ className: "text-subtitle" }, { children: "Effective Date 20 Jan 2024" }))] })), _jsxs("ol", __assign({ type: "1", className: "list-decimal list-inside" }, { children: __spreadArray([], data, true) }))] }))] }))] })));
}
