// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr {
  background: var(--grey);
  width: 100%;
  height: 0.0625rem;
  margin: 2.12rem 0rem;
}

.dropdown-btn {
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

/* Style for the dropdown container (hidden by default) */
.dropdown-content {
  cursor: pointer;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 125px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin: 4.5rem 0rem 0 0;
  border-radius: 1rem;
}

/* Style for the dropdown items */
.dropdown-content a {
  color: var(--primary);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
}

/* Change color on hover
.dropdown-content a:hover {
  background-color: #f1f1f1;
} */

/* Show the dropdown menu when the button is clicked */
.dropdown-btn:focus + .dropdown-content {
  display: block;
}

.profile-img {
  height: 4rem;
  width: 4rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/pageHeader/pageHeader.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,WAAW;EACX,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,eAAe;AACjB;;AAEA,yDAAyD;AACzD;EACE,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,yCAAyC;EACzC,UAAU;EACV,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA,iCAAiC;AACjC;EACE,qBAAqB;EACrB,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB;;AAEA;;;GAGG;;AAEH,sDAAsD;AACtD;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".hr {\n  background: var(--grey);\n  width: 100%;\n  height: 0.0625rem;\n  margin: 2.12rem 0rem;\n}\n\n.dropdown-btn {\n  color: white;\n  padding: 10px;\n  border: none;\n  cursor: pointer;\n}\n\n/* Style for the dropdown container (hidden by default) */\n.dropdown-content {\n  cursor: pointer;\n  position: absolute;\n  background-color: #f9f9f9;\n  min-width: 125px;\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n  z-index: 1;\n  margin: 4.5rem 0rem 0 0;\n  border-radius: 1rem;\n}\n\n/* Style for the dropdown items */\n.dropdown-content a {\n  color: var(--primary);\n  padding: 12px 16px;\n  text-decoration: none;\n  display: block;\n  text-align: center;\n  cursor: pointer;\n}\n\n/* Change color on hover\n.dropdown-content a:hover {\n  background-color: #f1f1f1;\n} */\n\n/* Show the dropdown menu when the button is clicked */\n.dropdown-btn:focus + .dropdown-content {\n  display: block;\n}\n\n.profile-img {\n  height: 4rem;\n  width: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
