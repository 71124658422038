var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Authentication } from "../constants/authentication";
import { Status } from "../constants/status";
var initialState = {
    status: Status.Loading,
};
/**
 * The User Reducer
 * This reducer is responsible for handling the state and action management of the authentication requests.
 * @param props
 */
var userReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        //user login
        case Authentication.LOGIN_USER:
            return __assign(__assign({}, state), { error_login: "", error_reset_confirm: "", status: Status.Loading });
        case Authentication.LOGIN_USER_SUCCESS:
            return __assign(__assign({}, state), { loginData: action.payload, email: action.email, role: action.role, username: action.username, userType: action.userType, error_login: "", status: Status.Successful });
        case Authentication.LOGIN_USER_FAILURE:
            return __assign(__assign({}, state), { error_login: action.payload, status: Status.Error });
        //user password reset
        case Authentication.RESET_PASSWORD:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Authentication.RESET_PASSWORD_SUCCESS:
            return __assign(__assign({}, state), { resetData: action.payload, status: Status.Successful });
        case Authentication.RESET_PASSWORD_FAILURE:
            return __assign(__assign({}, state), { error_reset: action.payload, status: Status.Error });
        case Authentication.RESET_PASSWORD_CONFIRM:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Authentication.RESET_PASSWORD_CONFIRM_SUCCESS:
            return __assign(__assign({}, state), { resetData_confirm: action.payload, status: Status.Successful });
        case Authentication.RESET_PASSWORD_CONFIRM_FAILURE:
            return __assign(__assign({}, state), { error_reset_confirm: action.payload, status: Status.Error });
        case Authentication.REGISTER_USER_SUCCESS:
            return __assign(__assign({}, state), { registerSuccess: true });
        default:
            return state;
    }
};
export default userReducer;
