var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import "./CreateUser.css";
import { Status } from '../../../redux/constants/status';
import Button from '../../atoms/button/button';
import showPopupModal from '../../../redux/actions/popupModal';
import Shimmer from '../../atoms/shimmer/shimmer';
export default function CreateUserConfirmation(props) {
    var dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var status = useSelector(function (state) { return state.rootReducer.ManageUserReducer.createUserStatus; });
    var body;
    switch (status.status) {
        case Status.Successful:
            body = (_jsxs(_Fragment, { children: [_jsx("h1", { children: "User Created" }), _jsxs("p", { children: ["The account setup instructions for a ", _jsx("a", __assign({ className: 'primarytext' }, { children: props.accountType })), " account have been sent to their email."] })] }));
            break;
        case Status.Error:
            body = (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Could not create user" }), _jsx("p", { children: "The user could not be created. Please try again later." }), _jsxs("p", { children: ["Error: ", status.message] })] }));
            break;
        default:
            body = (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Creating..." }), _jsx("div", __assign({ className: 'h-[30vh] w-full' }, { children: _jsx(Shimmer, { className: 'rounded-2xl' }) }))] }));
    }
    return (_jsxs(_Fragment, { children: [body, status != undefined && _jsx(Button, __assign({ className: 'mt-8', onClick: function () {
                    dispatch(showPopupModal(false));
                } }, { children: "Close" }))] }));
}
