var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import Button from "../../atoms/button/button";
import ErrorMessage from "../../atoms/errorMessage/errorMessage";
import InputField from "../../molecules/inputField/inputField";
import { useDispatch, useSelector } from "react-redux";
import showPopupModal from "../../../redux/actions/popupModal";
import { createNewUser } from "../../../redux/actions/manage_users";
import CreateUserConfirmation from "./CreateUserConfirmation";
var Selection;
(function (Selection) {
    Selection[Selection["Adviser"] = 0] = "Adviser";
    Selection[Selection["Manager"] = 1] = "Manager";
    Selection[Selection["Organisation"] = 2] = "Organisation";
})(Selection || (Selection = {}));
function generateOptions(caseload, username, selection) {
    var _a, _b;
    var names = [];
    switch (selection) {
        case Selection.Adviser:
            (_a = caseload.advisers) === null || _a === void 0 ? void 0 : _a.forEach(function (ad) { return names.push(ad.name); });
            break;
        case Selection.Manager:
            (_b = caseload.managers) === null || _b === void 0 ? void 0 : _b.forEach(function (ma) { return names.push(ma.name); });
            break;
        case Selection.Organisation:
            names.push(username);
            break;
    }
    return (_jsx(_Fragment, { children: names.map(function (e, i) {
            return (_jsx("option", __assign({ value: e }, { children: e }), i));
        }) }));
}
var CreateUserForm = function (props) {
    var dispatch = useDispatch();
    var _a = useState(""), hierarchy = _a[0], setHierarchy = _a[1];
    var _b = useState(""), email = _b[0], setEmail = _b[1];
    var logged_in_user_type = useSelector(function (state) { return state.rootReducer.dashboardReducer.logged_in_user_type; });
    var username = useSelector(function (state) { return state.rootReducer.userReducer.username; });
    var caseload = useSelector(function (state) { return state.rootReducer.ManageUserReducer.caseload; });
    function handleSubmit() {
        var logged = 0;
        switch (logged_in_user_type) {
            case "organisation":
                logged = 3;
                break;
            case "manager":
                logged = 2;
                break;
            case "adviser":
                logged = 1;
                break;
        }
        if ((hierarchy !== "" && email !== "") || props.userType === logged) {
            dispatch(createNewUser(hierarchy === "" ? username : hierarchy, email));
            dispatch(showPopupModal(true, _jsx(CreateUserConfirmation, { accountType: props.creatingUserType })));
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "mb-7" }, { children: "Create user" })), _jsxs("p", { children: ["Please fill in the following essential details to create a new", " ", _jsxs("span", __assign({ className: "text-[#5148FA]" }, { children: [" ", props.userType === 1
                                ? "Jobseeker"
                                : props.userType === 2
                                    ? "Adviser"
                                    : "Manager", " "] })), "account."] }), _jsxs("div", { children: [_jsxs("div", __assign({ className: "mt-6" }, { children: [_jsx("label", __assign({ className: "login" }, { children: props.userType === 1
                                    ? "Please select Adviser for this user:"
                                    : props.userType === 2
                                        ? "Please select Manager for this user:"
                                        : "Please select Organisation for this user:" })), _jsxs("select", __assign({ value: hierarchy, onChange: function (e) {
                                    setHierarchy(e.target.value);
                                }, className: "login mt-2" }, { children: [_jsx("option", __assign({ value: "" }, { children: " " })), generateOptions(caseload, username, props.userType === 1
                                        ? Selection.Adviser
                                        : props.userType === 2
                                            ? Selection.Manager
                                            : Selection.Organisation)] }))] })), _jsx(InputField, { label: "Email", type: "text", id: "id_username", name: "email", placeholder: "", value: email, onChange: function (e) {
                            return setEmail(e.target.value);
                        }, className: "login mt-2" }), !email ? (_jsx(ErrorMessage, { message: "This field is required*" }, "username")) : (""), _jsx("div", __assign({ className: "pt-6" }, { children: _jsx(Button, { border: "none", onClick: function () {
                                handleSubmit();
                            }, text: "Create", className: "mt-6" }) }))] })] }));
};
export default CreateUserForm;
