// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-text {
  color: rgba(30, 30, 30, 0.90);
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 0.56rem;
  margin-bottom: 0;
}

.alt-text {
  color: rgba(30, 30, 30, 0.50);
  font-family: Poppins;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.125rem;
  text-transform: capitalize;
  margin-top: 0;
}

`, "",{"version":3,"sources":["webpack://./src/components/molecules/brandHeader/brandHeader.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,0BAA0B;EAC1B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;EACxB,0BAA0B;EAC1B,aAAa;AACf","sourcesContent":[".top-text {\n  color: rgba(30, 30, 30, 0.90);\n  font-family: Poppins;\n  font-size: 1.375rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  text-transform: capitalize;\n  margin-top: 0.56rem;\n  margin-bottom: 0;\n}\n\n.alt-text {\n  color: rgba(30, 30, 30, 0.50);\n  font-family: Poppins;\n  font-size: 0.625rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  letter-spacing: 0.125rem;\n  text-transform: capitalize;\n  margin-top: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
