var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { takeEvery, call, put, getContext } from "redux-saga/effects";
import Cookies from "js-cookie";
import { Questionnaire } from "../constants/job_seeker_questionnaire";
import { APIType } from "../services/authenticationService";
/**
 * The Antecedents Generator function
 * This function is responsible for handling Antecedents service calls.
 * @param props
 */
function AntecedentsSaga() {
    var callAPI, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/antecedents/")
                            .then(function (resp) {
                            return resp.data;
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({ type: Questionnaire.ANTECEDENTS_FAILURE, payload: response })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({ type: Questionnaire.ANTECEDENTS_SUCCESS, payload: response })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_1 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ANTECEDENTS_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AntecedentsFormSaga(action) {
    var csrftoken, callAPI, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                csrftoken = Cookies.get("csrftoken");
                return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/antecedents/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: Questionnaire.ANTECEDENTS_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ANTECEDENTS_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_2 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ANTECEDENTS_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Knowledge Generator function
 * This function is responsible for handling Knowledge service calls.
 * @param props
 */
function KnowledgeSaga() {
    var callAPI, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/knowledge/")
                            .then(function (resp) {
                            return resp.data;
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({ type: Questionnaire.KNOWLEDGE_FAILURE, payload: response })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({ type: Questionnaire.KNOWLEDGE_SUCCESS, payload: response })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_3 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.KNOWLEDGE_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function KnowledgeFormSaga(action) {
    var callAPI, response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/knowledge/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.KNOWLEDGE_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.KNOWLEDGE_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_4 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.KNOWLEDGE_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The General Skills Generator function
 * This function is responsible for handling General Skills service calls.
 * @param props
 */
function GeneralSkillsSaga() {
    var callAPI, response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/general_skills/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, "Consider how confident you are in different aspects of applying for work and answer the following questions as accurately as possible.");
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.GENERAL_SKILLS_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.GENERAL_SKILLS_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_5 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.GENERAL_SKILLS_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function GeneralSkillsFormSaga(action) {
    var callAPI, response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/general_skills/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.GENERAL_SKILLS_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.GENERAL_SKILLS_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_6 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.GENERAL_SKILLS_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Confidence Generator function
 * This function is responsible for handling Confidence service calls.
 * @param props
 */
function ConfidenceSaga() {
    var callAPI, response, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/confidence/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, "Consider how confident you are in different aspects of applying for work and answer the following questions as accurately as possible.");
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({ type: Questionnaire.CONFIDENCE_FAILURE, payload: response })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({ type: Questionnaire.CONFIDENCE_SUCCESS, payload: response })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_7 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.CONFIDENCE_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function ConfidenceFormSaga(action) {
    var callAPI, response, error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/confidence/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.CONFIDENCE_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.CONFIDENCE_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_8 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.CONFIDENCE_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Attributes Generator function
 * This function is responsible for handling Attributes service calls.
 * @param props
 */
function AttributesSaga() {
    var callAPI, response, error_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/attributes/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, "Consider how confident you are in different aspects of applying for work and answer the following questions as accurately as possible.");
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({ type: Questionnaire.ATTRIBUTES_FAILURE, payload: response })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({ type: Questionnaire.ATTRIBUTES_SUCCESS, payload: response })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_9 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ATTRIBUTES_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AttributesFormSaga(action) {
    var callAPI, response, error_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/attributes/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ATTRIBUTES_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ATTRIBUTES_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_10 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ATTRIBUTES_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Qualifications Generator function
 * This function is responsible for handling Qualifications service calls.
 * @param props
 */
function QualificationsSaga() {
    var callAPI, response, error_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/qualifications/")
                            .then(function (resp) {
                            return resp.data;
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.QUALIFICATIONS_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.QUALIFICATIONS_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_11 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.QUALIFICATIONS_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function QualificationsFormSaga(action) {
    var callAPI, response, error_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/qualifications/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.QUALIFICATIONS_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.QUALIFICATIONS_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_12 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.QUALIFICATIONS_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Social Generator function
 * This function is responsible for handling Social service calls.
 * @param props
 */
function SocialSaga() {
    var callAPI, response, error_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/social/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, "What support do you have in regard to looking for and keeping work?");
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({ type: Questionnaire.SOCIAL_FAILURE, payload: response })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({ type: Questionnaire.SOCIAL_SUCCESS, payload: response })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_13 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.SOCIAL_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function SocialFormSaga(action) {
    var callAPI, response, error_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/social/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.SOCIAL_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.SOCIAL_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_14 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.SOCIAL_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Progression Generator function
 * This function is responsible for handling Progression service calls.
 * @param props
 */
function ProgressionSaga() {
    var callAPI, response, error_15;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/progression/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp);
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({ type: Questionnaire.PROGRESSION_FAILURE, payload: response })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({ type: Questionnaire.PROGRESSION_SUCCESS, payload: response })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_15 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.PROGRESSION_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function ProgressionFormSaga(action) {
    var callAPI, response, error_16;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/progression/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.PROGRESSION_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.PROGRESSION_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_16 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.PROGRESSION_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Global Generator function
 * This function is responsible for handling Global service calls.
 * @param props
 */
function GlobalSaga() {
    var callAPI, response, error_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/global/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp);
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({ type: Questionnaire.GLOBAL_FAILURE, payload: response })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({ type: Questionnaire.GLOBAL_SUCCESS, payload: response })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_17 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.GLOBAL_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function GlobalFormSaga(action) {
    var callAPI, response, error_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/global/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.GLOBAL_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.GLOBAL_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_18 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.GLOBAL_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Client Obligations Generator function
 * This function is responsible for handling Client Obligations service calls.
 * @param props
 */
function ClientObligationsSaga() {
    var callAPI, response, error_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/client_obligations/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, "To what extent have you made the following commitments or obligations to your adviser?");
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.CLIENT_OBLIGATIONS_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.CLIENT_OBLIGATIONS_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_19 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.CLIENT_OBLIGATIONS_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function ClientObligationsFormSaga(action) {
    var callAPI, response, error_20;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/client_obligations/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.CLIENT_OBLIGATIONS_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.CLIENT_OBLIGATIONS_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_20 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.CLIENT_OBLIGATIONS_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Adviser Obligations Generator function
 * This function is responsible for handling Adviser Obligations service calls.
 * @param props
 */
function AdviserObligationsSaga() {
    var callAPI, response, error_21;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/adviser_obligations/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, "To what extent has your adviser made the following commitments or obligations to you?");
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_OBLIGATIONS_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_OBLIGATIONS_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_21 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_OBLIGATIONS_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AdviserObligationsFormSaga(action) {
    var callAPI, response, error_22;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/adviser_obligations/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_OBLIGATIONS_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_OBLIGATIONS_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_22 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_OBLIGATIONS_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Adviser Feedback Generator function
 * This function is responsible for handling Adviser Feedback service calls.
 * @param props
 */
function AdviserFeedbackSaga() {
    var callAPI, response, error_23;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/adviser_feedback_2/")
                            .then(function (resp) {
                            return getMatrixRadioSelectArray(resp, "Thinking of the support you have been offered, please answer the following questions as honestly as possible.");
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_FEEDBACK_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_FEEDBACK_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_23 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_FEEDBACK_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AdviserFeedbackFormSaga(action) {
    var callAPI, response, error_24;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/adviser_feedback_2/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_FEEDBACK_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_FEEDBACK_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_24 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_FEEDBACK_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Adviser Impact Generator function
 * This function is responsible for handling Adviser Impact service calls.
 * @param props
 */
function AdviserImpactSaga() {
    var callAPI, response, error_25;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/adviser_impact_2/")
                            .then(function (resp) {
                            return resp.data;
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_IMPACT_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_IMPACT_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_25 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_IMPACT_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function AdviserImpactFormSaga(action) {
    var callAPI, response, error_26;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/adviser_impact_2/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_IMPACT_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.ADVISER_IMPACT_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_26 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.ADVISER_IMPACT_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * The Overall Experience Generator function
 * This function is responsible for handling Overall Experience service calls.
 * @param props
 */
function OverallExperienceSaga() {
    var callAPI, response, error_27;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.GET, "/api/model_metadata/overall_experience_2/")
                            .then(function (resp) {
                            return resp.data;
                        })
                            .catch(function () {
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.OVERALL_EXPERIENCE_FAILURE,
                        payload: response,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.OVERALL_EXPERIENCE_SUCCESS,
                    payload: response,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_27 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.OVERALL_EXPERIENCE_FAILURE,
                        payload: "Error occurred",
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function OverallExperienceFormSaga(action) {
    var callAPI, response, error_28;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext("authService")];
            case 1:
                callAPI = (_a.sent()).callAPI;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(function () {
                        return callAPI(APIType.POST, "/api/submit/overall_experience_2/", { body: action.payload })
                            .then(function (resp) { return resp.data.success; })
                            .catch(function (err) {
                            if (err.response && err.response.status === 401) {
                                return "Invalid or Expired Token";
                            }
                            return "Invalid Credentials";
                        });
                    })];
            case 3:
                response = _a.sent();
                if (!(response === "Invalid Credentials" ||
                    response === "Invalid or Expired Token")) return [3 /*break*/, 5];
                // Dispatch an error action
                return [4 /*yield*/, put({
                        type: Questionnaire.OVERALL_EXPERIENCE_FORM_FAILURE,
                        status: response,
                        payload: action.payload,
                    })];
            case 4:
                // Dispatch an error action
                _a.sent();
                return [3 /*break*/, 7];
            case 5: 
            // Dispatch a success action
            return [4 /*yield*/, put({
                    type: Questionnaire.OVERALL_EXPERIENCE_FORM_SUCCESS,
                    status: response,
                    payload: action.payload,
                })];
            case 6:
                // Dispatch a success action
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_28 = _a.sent();
                return [4 /*yield*/, put({
                        type: Questionnaire.OVERALL_EXPERIENCE_FORM_FAILURE,
                        status: "Error occurred",
                        payload: action.payload,
                    })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function getMatrixRadioSelectArray(resp, blurb) {
    // Filter out objects having "field_type" equal to "MatrixRadioSelect"
    var choices = [];
    var matrixRadioSelectObjects = resp.data.filter(function (obj) {
        var fieldType = obj[Object.keys(obj)[0]].field_type;
        choices = obj[Object.keys(obj)[0]].choices;
        return fieldType === "MatrixRadioSelect";
    });
    // Create a new array with the filtered objects
    var newArray = __spreadArray([], matrixRadioSelectObjects, true);
    // Append the new array to the original one
    var modifiedData = __spreadArray(__spreadArray([], resp.data.filter(function (obj) { return obj[Object.keys(obj)[0]].field_type !== "MatrixRadioSelect"; }), true), [
        {
            matrix_Radio_Select_Array: {
                label: blurb ? blurb : "",
                field_type: "MatrixRadioSelect",
                choices: choices,
                data: newArray,
            },
        },
    ], false);
    return modifiedData;
}
function JobSeekerQuestionnaireSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(Questionnaire.ANTECEDENTS, AntecedentsSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ANTECEDENTS_FORM, AntecedentsFormSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.KNOWLEDGE, KnowledgeSaga)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.KNOWLEDGE_FORM, KnowledgeFormSaga)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.GENERAL_SKILLS, GeneralSkillsSaga)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.GENERAL_SKILLS_FORM, GeneralSkillsFormSaga)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.CONFIDENCE, ConfidenceSaga)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.CONFIDENCE_FORM, ConfidenceFormSaga)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ATTRIBUTES, AttributesSaga)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ATTRIBUTES_FORM, AttributesFormSaga)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.QUALIFICATIONS, QualificationsSaga)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.QUALIFICATIONS_FORM, QualificationsFormSaga)];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.SOCIAL, SocialSaga)];
            case 13:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.SOCIAL_FORM, SocialFormSaga)];
            case 14:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.PROGRESSION, ProgressionSaga)];
            case 15:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.PROGRESSION_FORM, ProgressionFormSaga)];
            case 16:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.GLOBAL, GlobalSaga)];
            case 17:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.GLOBAL_FORM, GlobalFormSaga)];
            case 18:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.CLIENT_OBLIGATIONS, ClientObligationsSaga)];
            case 19:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.CLIENT_OBLIGATIONS_FORM, ClientObligationsFormSaga)];
            case 20:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_OBLIGATIONS, AdviserObligationsSaga)];
            case 21:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_OBLIGATIONS_FORM, AdviserObligationsFormSaga)];
            case 22:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_FEEDBACK, AdviserFeedbackSaga)];
            case 23:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_FEEDBACK_FORM, AdviserFeedbackFormSaga)];
            case 24:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_IMPACT, AdviserImpactSaga)];
            case 25:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.ADVISER_IMPACT_FORM, AdviserImpactFormSaga)];
            case 26:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.OVERALL_EXPERIENCE, OverallExperienceSaga)];
            case 27:
                _a.sent();
                return [4 /*yield*/, takeEvery(Questionnaire.OVERALL_EXPERIENCE_FORM, OverallExperienceFormSaga)];
            case 28:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default JobSeekerQuestionnaireSaga;
